import React from 'react';

import Dashboard from 'components/Dashboard';
import { ChildrenProps } from 'models/props';
import { isLessThan } from 'utils/useWindowWidth';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import { FXSTwoCols } from './styles';

config.autoAddCss = false;

export default function TwoColsMainLayout({ children }: ChildrenProps) {
	const isLessThan833 = isLessThan(833);
	const isLessThan1280 = isLessThan(1280);

	return (
		<Dashboard>
			<FXSTwoCols
				isLessThan833={isLessThan833}
				isLessThan1280={isLessThan1280}
			>
				{children}
			</FXSTwoCols>
		</Dashboard>
	);
}
