import { css } from 'styled-components';

const txt_large = css`
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 1.2rem;
		line-height: 1.6187rem;
	}
`;
const txt_large_bold = css`
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 1.2rem;
		line-height: 1.6187rem;
	}
`;
const txt_small = css`
	font-weight: 400;
	font-size: 0.8rem;
	line-height: 1.0625rem;
	@media screen and (min-width: 680px) {
		font-size: 0.9187rem;
		line-height: 1.25rem;
	}
`;
const txt_small_bold = css`
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 1.0625rem;
	@media screen and (min-width: 680px) {
		font-size: 0.9187rem;
		line-height: 1.25rem;
	}
`;
const txt_xsmall = css`
	font-weight: 400;
	font-size: 0.6875rem;
	line-height: 0.875rem;
	@media screen and (min-width: 680px) {
		font-size: 0.8rem;
		line-height: 1.0625rem;
	}
`;
const txt_xsmall_bold = css`
	font-weight: 700;
	font-size: 0.6875rem;
	line-height: 0.875rem;
	@media screen and (min-width: 680px) {
		font-size: 0.8rem;
		line-height: 1.0625rem;
	}
`;
const txt_nano = css`
	font-weight: 400;
	font-size: 0.6875rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 0.6875rem;
		line-height: 1.25rem;
	}
`;
const txt_nano_bold = css`
	font-weight: 700;
	font-size: 0.6875rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 0.6875rem;
		line-height: 1.25rem;
	}
`;

export {
	txt_large,
	txt_large_bold,
	txt_nano,
	txt_nano_bold,
	txt_small,
	txt_small_bold,
	txt_xsmall,
	txt_xsmall_bold
};
