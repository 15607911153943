import React from 'react';

interface Props {
	components: Array<
		React.JSXElementConstructor<React.PropsWithChildren<any>>
	>;
	children: React.ReactNode;
}

export default function Compose(props: Props) {
	const { components = [], children } = props;

	return (
		<div>
			{components.reduceRight((acc, Comp) => {
				return <Comp>{acc}</Comp>;
			}, children)}
		</div>
	);
}
