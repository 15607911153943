import styled from 'styled-components';
import { COLORS } from 'styles/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/************************
  MOBILE
************************/
export const UserIcon = styled(FontAwesomeIcon)`
	height: 24px;
	width: 24px !important;
	color: ${COLORS.white};
	@media (min-width: 833px) {
		height: 20px;
		color: ${COLORS.grey500};
	}
`;
export const FXSUserInfoMobile = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
	width: 41px;
	height: 41px;
	background: ${COLORS.corp};
	border-radius: 4px;
	&:hover {
		background: ${COLORS.blue};
	}
	svg {
		fill: ${COLORS.white};
	}
`;

/************************
  DESKTOP
************************/
export const FXSUserWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 40px;
	@media (min-width: 833px) {
		margin-left: unset;
		align-items: flex-end;
	}
`;
export const FXSUserButtonText = styled.span`
	max-width: 100%;
	max-height: 250%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FXSUserButton = styled.button`
	display: inline-flex;
	align-items: center;
	height: 40px;
	padding: 8px 12px;
	border-radius: 4px;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	background-color: #fff;
	box-sizing: border-box;
	border: 0 solid transparent;
	svg {
		fill: ${COLORS.dark};
	}
	&:hover,
	&:active {
		background: ${COLORS.interaction_blue_light_2};
		border: 0 solid transparent;
		color: ${COLORS.interaction_blue};

		svg {
			fill: ${COLORS.interaction_blue};
		}
	}
`;

export const FXSLogoutBtn = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 10px 20px;
	gap: 8px;
	height: 44px;
	background: ${COLORS.white};
	font-weight: 400;
	font-size: 14.72px;
	line-height: 20px;
	color: ${COLORS.grey};
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	position: absolute;
	top: 100%;
	right: 0;
	width: max-content;
	svg {
		fill: ${COLORS.grey};
	}

	&:hover {
		background: ${COLORS.grey200};
	}
`;
