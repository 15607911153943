import { TimezoneApiResponse } from 'constants/timezones';
import { initialState } from 'contexts/timeZoneContext';

const fetcher = async (url: string) => {
	return await fetch(
		`${url}/api/timezoneapi/get?cultureName=en&cacheRefresh=${new Date().getTime()}`,
		{
			method: 'GET'
		}
	).then(async (res) => {
		const data = await res.json();
		return timeZonesMapper(data);
	});
};

const timeZonesMapper = (data: any): TimezoneApiResponse => {
	const result = initialState;

	result.TimeZones = data.TimeZones;
	result.SetAutomaticallyTimeZone = data.SetAutomaticallyTimeZone;
	result.CurrentTimeZone = data.CurrentTimeZone;
	result.Translations = data.Translations;
	result.IsTwelveTimeFormat = data.IsTwelveTimeFormat;

	return result;
};

export async function getTimeZones(url: string): Promise<TimezoneApiResponse> {
	return await fetcher(url);
}
