import React from 'react';

import { isLessThan } from 'fxs-utils';

const useNotificationTutorial = (
	isNotificationsModalOpened: boolean,
	setIsNotificationsModalOpened: (state: boolean) => void
) => {
	const [currentImgIndex, setCurrentImgIndex] = React.useState(0);
	const isLessThan833 = isLessThan(833);

	const imgs = [
		'notificationsTutorialMobileOne.png',
		'notificationsTutorialMobileTwo.png',
		'notificationsTutorialMobileThree.png',
		'notificationsTutorialMobileFour.png'
	];

	React.useEffect(() => {
		if (isLessThan833) setCurrentImgIndex(0);
	}, [isNotificationsModalOpened]);

	const handleCloseModal = (
		e: React.MouseEvent<SVGSVGElement | HTMLButtonElement, MouseEvent>
	) => {
		e.stopPropagation();
		setIsNotificationsModalOpened(false);
	};

	const handleNextImg = () => {
		if (currentImgIndex === imgs.length - 1) {
			setCurrentImgIndex(0);
		} else {
			setCurrentImgIndex(currentImgIndex + 1);
		}
	};

	const handlePrevImg = () => {
		if (currentImgIndex === 0) {
			setCurrentImgIndex(imgs.length - 1);
		} else {
			setCurrentImgIndex(currentImgIndex - 1);
		}
	};
	return {
		currentImgIndex,
		imgs,
		handleCloseModal,
		handleNextImg,
		handlePrevImg
	};
};

export default useNotificationTutorial;
