import { useEffect, useState } from 'react';

import jwt_decode from 'jwt-decode';

import Popup from './Popup';

type User = {
	IsPremium: string;
	PremiumExp: string;
};

const apiUrl: string = `${process.env.REACT_APP_USERPAY_URL}`;

export default () => {
	// Universal constants
	const oneDay = 86400000;
	const campaingDays = oneDay * 5;

	// User data
	const userToken = window.userInfo.token;
	const userId = window.userInfo.id;
	const userTokenDecoded: User = jwt_decode(userToken);
	const { IsPremium, PremiumExp } = userTokenDecoded;

	// Dates objects
	const currentDate = new Date();
	const expirationDate = new Date(parseInt(PremiumExp) * 1000);
	expirationDate.setHours(23);
	expirationDate.setMinutes(59);
	expirationDate.setSeconds(59);
	const timeUntilExpiration =
		expirationDate.getTime() - currentDate.getTime();
	const isLastDay: boolean = timeUntilExpiration <= oneDay ? true : false;
	const cookieBeforeExpiration = expirationDate.getTime() - oneDay;

	// Misc
	const [isReady, setIsReady] = useState(false);
	const [payment, setPayment] = useState(null);

	useEffect(() => {
		if (IsPremium && timeUntilExpiration <= campaingDays) {
			const paymentInfo = fetch(`${apiUrl}?userId=${userId}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `Bearer ${userToken}`
				}
			});

			paymentInfo
				.then((response) => response.json())
				.then((data) => {
					setPayment(data);
					setIsReady(true);
				});
		}
	}, []);

	return (
		<>
			{isReady && payment.paymentPlatform === 2 && (
				<div className='offer-banner'>
					<Popup
						Payment={payment}
						isLastDay={isLastDay}
						cookieBeforeExpiration={cookieBeforeExpiration}
					/>
				</div>
			)}
		</>
	);
};
