export const EnvelopeIcon = () => {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0.5 18 13.5'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M16.313.5A1.71 1.71 0 0 1 18 2.188v10.125c0 .949-.773 1.687-1.687 1.687H1.688C.738 14 0 13.262 0 12.313V2.188C0 1.273.738.5 1.688.5zm0 1.688H1.688v1.44c.773.669 2.039 1.653 4.71 3.763.598.457 1.758 1.582 2.602 1.547.809.035 1.969-1.09 2.566-1.547 2.672-2.11 3.938-3.094 4.746-3.762zM1.688 12.313h14.625V5.809c-.81.632-1.97 1.546-3.692 2.918-.809.597-2.144 1.933-3.621 1.898-1.512.035-2.883-1.3-3.656-1.898C3.62 7.355 2.46 6.44 1.687 5.809z'
			/>
		</svg>
	);
};
