import React, { useState } from 'react';

import { ConnectedHubs, PushContext } from '../contexts/pushContext';

export const PushContextProvider = ({ children }) => {
	const [hubs, setHubs] = useState([]);

	const addHub = (hub: string) => {
		const newValue = hubs;
		newValue.push(hub);
		setHubs(newValue);
		console.log(`added:${hub}`);
	};
	const removeHub = (hub: string) => {
		const newValue = hubs.filter((item) => item !== hub);
		setHubs(newValue);
		console.log(`removed:${hub}`);
	};
	const hasHub = (hub: string): boolean => {
		return hubs.indexOf(hub) !== -1;
	};

	const initValue: ConnectedHubs = { hubs: [], addHub, removeHub, hasHub };

	return (
		<PushContext.Provider value={initValue}>
			{children}
		</PushContext.Provider>
	);
};
