import React from 'react';

import {
	NewsletterContextProps,
	useNewsletterContext
} from 'context-providers/newsletterContextProvider';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { PremiumNewsletter } from 'models/newsletter';
import { NewslettersProps } from 'models/props';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Envelope } from './envelope';
import { NewsletterRow } from './newsletterRow';
import {
	CloseIcon,
	NewslettersBackdrop,
	NewslettersCloseText,
	NewslettersDescription,
	NewslettersGreybox,
	NewslettersIcon,
	NewslettersModalAllOnP,
	NewslettersModalAllOnWrapper,
	NewslettersModalBody,
	NewslettersModalBtnReset,
	NewslettersModalBtnSave,
	NewslettersModalBtnWrapper,
	NewslettersModalCheckbox,
	NewslettersModalSwitchCircle,
	NewslettersModalSwitchWrapper,
	NewslettersSidebar,
	NewslettersSidebarHeader,
	NewslettersSidebarTitle,
	NewslettersSnackbar,
	NotificatiionSnackbarP
} from './styles';

const NewslettersModal: React.FC<NewslettersProps> = ({
	elementRef,
	isNewslettersModalOpened,
	setIsNewslettersModalOpened
}) => {
	const { t } = useTranslationsContext();
	const isLessThan833 = isLessThan(833);
	const dismissedNewslettersDescription = localStorage.getItem(
		'dismissedNewslettersDescription'
	);

	const handleCloseModal = (
		e: React.MouseEvent<
			HTMLDivElement | SVGSVGElement | HTMLButtonElement,
			MouseEvent
		>
	) => {
		e.stopPropagation();
		setIsNewslettersModalOpened(false);
	};

	const {
		error,
		subscribedToAllNewsletters,
		newsletters,
		handleSubscribeAll,
		isLoading,
		setNewsletters,
		setChanged,
		saveNewsletters,
		resetNewsletters,
		changed,
		saved
	} = useNewsletterContext() as NewsletterContextProps;

	return (
		<NewslettersBackdrop
			isNewslettersModalOpened={isNewslettersModalOpened}
		>
			<NewslettersSidebar ref={elementRef}>
				<div>
					<NewslettersSidebarHeader>
						<NewslettersSidebarTitle>
							{t('PremiumNewsletters')}
						</NewslettersSidebarTitle>
						<CloseIcon
							icon={faTimes as IconProp}
							onClick={handleCloseModal}
						/>
					</NewslettersSidebarHeader>
					{!dismissedNewslettersDescription && (
						<NewslettersGreybox show={true}>
							<div>
								<NewslettersDescription>
									{t('NewslettersDescription')}
								</NewslettersDescription>
								<NewslettersCloseText
									onClick={(e) => {
										handleCloseModal(e);
										localStorage.setItem(
											'dismissedNewslettersDescription',
											'true'
										);
									}}
								>
									{t('NewslettersCloseText')}
								</NewslettersCloseText>
							</div>
							<Envelope />
						</NewslettersGreybox>
					)}
					<NewslettersModalBody>
						<NewslettersModalAllOnWrapper>
							<NewslettersModalAllOnP>
								{t('AllNewsletters')}
							</NewslettersModalAllOnP>
							<NewslettersModalCheckbox>
								<NewslettersModalSwitchWrapper
									isActive={subscribedToAllNewsletters(
										newsletters
									)}
									onClick={() => {
										handleSubscribeAll(newsletters);
									}}
								>
									<NewslettersModalSwitchCircle
										isActive={subscribedToAllNewsletters(
											newsletters
										)}
										onClick={() => {
											handleSubscribeAll(newsletters);
										}}
									/>
								</NewslettersModalSwitchWrapper>
							</NewslettersModalCheckbox>
						</NewslettersModalAllOnWrapper>
						{isLoading ? (
							<p className='loading'>Loading...</p>
						) : error ? (
							<p className='error'>{error}</p>
						) : (
							newsletters.map((newsletter: PremiumNewsletter) => (
								<NewsletterRow
									newsletter={newsletter}
									setNewsletters={setNewsletters}
									newsletters={newsletters}
									setChanged={setChanged}
									key={newsletter.Id}
								/>
							))
						)}
					</NewslettersModalBody>
				</div>
				<NewslettersModalBtnWrapper>
					{saved && (
						<NewslettersSnackbar>
							<NewslettersIcon icon={faCheck as IconProp} />
							<NotificatiionSnackbarP>
								{t('changesSaved')}
							</NotificatiionSnackbarP>
						</NewslettersSnackbar>
					)}

					<NewslettersModalBtnSave
						onClick={() => {
							saveNewsletters(setIsNewslettersModalOpened);
						}}
						disabled={!changed}
					>
						{t('save')} {isLessThan833 ? '' : t('preferences')}
					</NewslettersModalBtnSave>
					<NewslettersModalBtnReset
						onClick={resetNewsletters}
						disabled={!changed}
					>
						{t('reset')} {isLessThan833 ? '' : t('preferences')}
					</NewslettersModalBtnReset>
				</NewslettersModalBtnWrapper>
			</NewslettersSidebar>
		</NewslettersBackdrop>
	);
};
export default NewslettersModal;
