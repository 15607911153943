import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const MarketBiasSheet = styled.div`
	width: 100%;
	height: 550px;
	display: flex;
	flex-direction: column;
	margin-bottom: 32px;
	@media screen and (max-width: 833px) {
		margin: 0 0 16px 20px;
	}
`;

export const Iframe = styled.iframe`
	width: 100%;
	height: 100%;
	border-style: none;
	background: ${COLORS.white};
	border-radius: 4px;
`;

export const MarketBiasTitle = styled.span`
	color: ${COLORS.grey};
	font-weight: 700;
	min-width: 110px;
`;

export const MarketBiasDescription = styled.span`
	color: ${COLORS.grey600};
`;

export const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 14px;
	gap: 16px;
	@media screen and (min-width: 833px) {
		flex-direction: row;
	}
`;
