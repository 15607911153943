import {
	Config,
	Endpoints,
	MarketBias,
	MasterClass,
	Variables
} from '../models/config';

export const configMapper = (source: any): Config => ({
	Endpoints: endpointsMapper(JSON.parse(source.Endpoints)),
	MarketBias: marketBiasMapper(JSON.parse(source.MarketBias)),
	MasterClass: masterClassMapper(JSON.parse(source.MasterClass)),
	Variables: variablesMapper(JSON.parse(source.Variables)),
	ExpertsSchedule: JSON.parse(source.ExpertsSchedule)
});

export const endpointsMapper = (source: any): Endpoints => ({
	Lead: source.Lead,
	SignalMessage: source.SignalMessage,
	SignalMessages: source.SignalMessages,
	Signals: source.Signals,
	SignalStats: source.SignalStats,
	SignalHistorical: source.SignalHistorical,
	SignalBookmark: source.SignalBookmark,
	SignalLike: source.SignalLike,
	SignalMostLiked: source.SignalMostLiked,
	SignalStrategies: source.SignalStrategies,
	SignalStatsStrategyId: source.SignalStatsStrategyId,
	SignalStrategyStrategyId: source.SignalStrategyStrategyId,
	SignalUserSettings: source.SignalUserSettings,
	UserEventFollows: source.UserEventFollows,
	SignalUserSettingsEmail: source.SignalUserSettingsEmail,
	SignalUserSettingsId: source.SignalUserSettingsId,
	SignalTradePlan: source.SignalTradePlan,
	UserEventFollowsByPostId: source.UserEventFollowsByPostId,
	MasterData: source.MasterData,
	Push: source.Push,
	UserTypeformSettings: source.UserTypeformSettings,
	Logout: source.Logout,
	Newsletters: source.Newsletters,
	UserNewsletters: source.UserNewsletters,
	Localization: source.Localization
});

export const masterClassMapper = (source: any): MasterClass => ({
	IsEnabled: source.isEnabled,
	EmbedCode: source.embedCode
});

export const marketBiasMapper = (source: any): MarketBias => ({
	GoogleExcelURL: source.GoogleExcelURL
});

export const variablesMapper = (source: any): Variables => ({
	AlgoliaApiKey: source.AlgoliaApiKey,
	AlgoliaAppId: source.AlgoliaAppId,
	AlgoliaIndex: source.AlgoliaIndex,
	AlgoliaTake: source.AlgoliaTake,
	Culture: source.Culture,
	FxsSite: source.FxsSite,
	GtmId: source.GtmId,
	TimeToRefresh: source.TimeToRefresh,
	SignalsLandingUrl: source.SignalsLandingUrl,
	SignalsPageUrl: source.SignalsPageUrl,
	NotificationSoundUrl: source.NotificationSoundUrl,
	StaticContentBaseUrl: source.StaticContentBaseUrl,
	TypeformId: source.TypeformId,
	TradingViewUrl: source.TradingViewUrl,
	NonFXAssets: source.NonFXAssets,
	DiscordUrl: source.DiscordUrl,
	TradingStudioUrl: source.TradingStudioUrl,
	NotificationEnabled: source.NotificationEnabled,
	HowEnableNotificationsUrl: source.HowEnableNotificationsUrl,
	OneSignalAppId: source.OneSignalAppId,
	CookieDomain: source.CookieDomain,
	AuthenticationCookie: source.AuthenticationCookie,
	LocalizationApplicationId: source.LocalizationApplicationId,
	FXSWidgetUrl: source.FxsWidgetUrl,
	DashboardUrl: source.DashboardUrl
});
