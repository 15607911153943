import React from 'react';

import { User } from '../models/user';

export const user: Partial<User> = {
	id: null,
	token: null,
	name: null,
	phone: null,
	country: null,
	isPremium: false,
	isLogged: false,
	email: null,
	isEmployee: false,
	emailEncrypted: null
};

export const UserContext = React.createContext(user);
