import React, { useContext, useEffect, useState } from 'react';
import PopupMobile from 'components/DiscordBtnOnboarding/PopupMobile';
import { MemHeaderMobile } from 'components/Header';
import NewslettersModal from 'components/NewslettersModal';
import NotificationsModal from 'components/NotificationsModal';
import NotificationsTutorial from 'components/NotificationsTutorial';
import { MemSidebarDesktop } from 'components/Sidebar';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { FXSUtilitiesFloats } from 'fxs-styles';
import { NotificationsPermissionType } from 'models/enums';
import { isNotificationSupported, useOneSignal } from 'utils/oneSignalManager';
import useClickOutside from 'utils/useClickOutside';
import { isLessThan } from 'utils/useWindowWidth';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import CustomHead from '../customHead';
import { FXSGridWrapper } from './styles';

config.autoAddCss = false;

type PageWrapperProps = React.PropsWithChildren<any>;

export default function PageWrapper({ children }: PageWrapperProps) {
	const { user } = useContext(UserContext);
	const [isLateralMobileMenuOpened, setLateralMobileMenuStatus] =
		useState(false);
	const localNotifications = JSON.parse(
		window.localStorage.getItem('notifications')
	);
	const { t } = useTranslationsContext();
	const openModal = useOneSignal(localNotifications);
	const [isNotificationsModalOpened, setIsNotificationsModalOpened] =
		useState(false);
	const [isNewslettersModalOpened, setIsNewslettersModalOpened] =
		useState(false);
	const notificationRef = useClickOutside(() => {
		setIsNotificationsModalOpened(false);
	});

	const newslettersRef = useClickOutside(() => {
		setIsNewslettersModalOpened(false);
	});

	const isLessThan833 = isLessThan(833);

	// Discord

	useEffect(() => {
		setIsNotificationsModalOpened(openModal);
	}, [openModal]);

	useEffect(() => {
		setIsNotificationsModalOpened(openModal);
	}, [openModal]);

	const notificationType = () => {
		if (Notification.permission === NotificationsPermissionType.denied) {
			return (
				<NotificationsTutorial
					elementRef={notificationRef}
					isNotificationsModalOpened={isNotificationsModalOpened}
					translate={t}
					setIsNotificationsModalOpened={
						setIsNotificationsModalOpened
					}
				/>
			);
		} else {
			return (
				<NotificationsModal
					elementRef={notificationRef}
					isNotificationsModalOpened={isNotificationsModalOpened}
					translate={t}
					setIsNotificationsModalOpened={
						setIsNotificationsModalOpened
					}
				/>
			);
		}
	};

	return (
		<FXSGridWrapper isLessThan833={isLessThan833}>
			<CustomHead />
			<FXSUtilitiesFloats />
			{children}
			<MemHeaderMobile
				isPremium={user?.isPremium}
				setLateralMobileMenuStatus={setLateralMobileMenuStatus}
				setIsNotificationsModalOpened={setIsNotificationsModalOpened}
				setIsNewslettersModalOpened={setIsNewslettersModalOpened}
			/>
			<MemSidebarDesktop
				isLateralMobileMenuOpened={isLateralMobileMenuOpened}
				setLateralMobileMenuStatus={setLateralMobileMenuStatus}
				isPremium={user?.isPremium}
				setIsNotificationsModalOpened={setIsNotificationsModalOpened}
				setIsNewslettersModalOpened={setIsNewslettersModalOpened}
			/>
			{isNotificationSupported() && notificationType()}
			<NewslettersModal
				isNewslettersModalOpened={isNewslettersModalOpened}
				setIsNewslettersModalOpened={setIsNewslettersModalOpened}
				translate={t}
				elementRef={newslettersRef}
			/>
			{isLessThan833 && user?.isPremium === false && <PopupMobile />}
		</FXSGridWrapper>
	);
}
