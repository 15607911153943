import { getUser } from '../utils/getUser';

const fetcher = async (eventTitle: string): Promise<void> => {
	return getUser().then(async (user) => {
		const res = await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.Lead}`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					CreationDate: new Date().toISOString(),
					Email: user.email,
					Name: user.name,
					Phone: user.phone,
					Broker: null,
					BrokerEmail: null,
					Type: 4,
					Country: user.country,
					Description: `Event Logged -> ${eventTitle}`,
					Culture: window.config.Variables.Culture
				})
			}
		);
		return res.json();
	});
};

export async function setTerms(eventTitle: string): Promise<void> {
	return fetcher(eventTitle);
}
