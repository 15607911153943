import styled from 'styled-components';

export const CalendarWrapper = styled.div`
	background-color: white;
	display: block;
	float: left;
	width: 100%;
	padding: 7px 0;
	@media (min-width: 833px) {
		padding: 24px;
	}
`;
