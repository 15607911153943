import { useContext, useEffect, useRef } from 'react';

import { FullWidthMainLayout } from 'components/Layouts';
import UserNoPremium from 'components/UserNoPremium';
import { timeZoneHelper } from 'constants/timezones';
import { ConfigContext } from 'contexts/configContext';
import { TimeZoneContext } from 'contexts/timeZoneContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { getSpeechScore } from 'data/getSpeechScore';
import { getIsLocked } from 'data/getIsLocked';
import { PremiumProps } from 'models/props';
import { FXSPageTitleResponsive } from 'styles/mixins';
import { FXS_hd_med } from 'styles/typography';

import { CalendarWrapper } from './styles';

declare module 'react' {
	export interface HTMLAttributes<T> {
		name?: string;
		culture?: string;
		tracking?: string;
		t?: T;
	}
}

export default function Calendar({ isPremium }: PremiumProps) {
	const { t } = useTranslationsContext();
	const divRef = useRef(null);
	function AddLibrary(urlOfTheLibrary) {
		const script = document.createElement('script');
		script.src = urlOfTheLibrary;
		script.async = true;
		document.body.appendChild(script);
	}
	const { timeZones } = useContext(TimeZoneContext);

	const { config } = useContext(ConfigContext);

	const OneSignalAppId = config.Variables.OneSignalAppId;
	const FxsWidgetUrl = config.Variables.FXSWidgetUrl;
	const DashboardUrl = config.Variables.DashboardUrl;

	timeZoneHelper(timeZones);

	useEffect(() => {
		if (divRef.current) {
			divRef.current.setAttribute('fxs-widget', 'true');
			divRef.current.setAttribute('calendar-api-version', 'v2');
			divRef.current.setAttribute('one-signal-app-id', OneSignalAppId);
			divRef.current.setAttribute('name', 'calendar');
			divRef.current.setAttribute('culture', 'en');
			divRef.current.setAttribute('tracking', 'true');
			divRef.current.setAttribute('timezone-offset', '0');
			divRef.current.setAttribute('dashboard-url', DashboardUrl);
			divRef.current.setAttribute(
				'country-code',
				'DE,AU,CA,CN,EMU,DE,ES,US,FR,IT,JP,NZ,UK,CH'
			);
			divRef.current.setAttribute('has-posts', 'true');
			divRef.current.setAttribute('timezone-helper', 'getTimezoneHelper');
			divRef.current.setAttribute('init-one-signal', 'https');
			divRef.current.setAttribute('is-Locked', getIsLocked);
			divRef.current.setAttribute('get-speech-score-fnc', getSpeechScore);
		}
	}, []);

	return (
		<FullWidthMainLayout>
			<FXSPageTitleResponsive>
				<FXS_hd_med>{t('calendarPageTitle')}</FXS_hd_med>
			</FXSPageTitleResponsive>
			{!isPremium && <UserNoPremium />}
			{AddLibrary(FxsWidgetUrl)}
			<CalendarWrapper>
				<div ref={divRef}></div>
			</CalendarWrapper>
		</FullWidthMainLayout>
	);
}
