import { mapSignalsMessages } from '../mappers/signalMessages';
import {
	EditorialMessagePayload,
	Message,
	MessageResponse
} from '../models/signalMessages';
import { getUser } from '../utils/getUser';

const fetcher = async (): Promise<EditorialMessagePayload> => {
	return getUser().then(async (user) => {
		try {
			const res = await fetch(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalMostLiked}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `bearer ${user?.token}`
					}
				}
			);
			if (res.ok) {
				const data = await res.json();
				const mappedMessage = data.map((item: MessageResponse) =>
					mapSignalsMessages(item)
				) as Message[];
				return {
					data: mappedMessage,
					error: false
				} as EditorialMessagePayload;
			}
			return { data: null, error: false } as EditorialMessagePayload;
		} catch (error) {
			return { data: null, error: true };
		}
	});
};

export async function getPopularSignals(): Promise<EditorialMessagePayload> {
	return fetcher();
}
