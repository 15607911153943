import React, { useContext, useEffect, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import { FXSToast } from 'components/shared';
import { INSIGHTS } from 'constants/locations';
import { SCROLL_CONTAINER_ID } from 'constants/signalMessages';
import { FilterContext, FilterState } from 'contexts/filtersContext';
import { PushContext } from 'contexts/pushContext';
import { SignalsInsightsContext } from 'contexts/signalsInsightsContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { getSignalsMessages } from 'data/getSignalsMessages';
import { getSignalsSettings } from 'data/getSignalsSettings';
import { getEventFollow } from 'data/getUserEventFollow';
import { loadNewerMessages } from 'data/loadNewerMessages';
import { loadOlderMessages } from 'data/loadOlderMessages';
import { setBookmarkedSignals } from 'data/setBookmarkedSignals';
import { setLikedSignals } from 'data/setLikedSignals';
import { setTerms } from 'data/setTerms';
import { setEventFollow } from 'data/setUserEventFollow';
import { updateSignalsSettings } from 'data/updateSignalsSettings';
import { useSignalMessagesPush } from 'data/useSignalMessagesPush';
import { Loading } from 'fxs-loading';
import * as fxsSignalsInsights from 'fxs-signals-insights';
import {
	mapEditorialMessageFromPush,
	mapTradeMessageFromPush
} from 'mappers/signalMessages';
import { EditorialCategoryType } from 'models/enums';
import {
	EditorialMessagePush,
	TradeMessagePush
} from 'models/signalPushMessages';
import { UserSettingsResponse } from 'models/userSettings';
import { isMessageMatch } from 'utils/filtersCheck';
import { getUser } from 'utils/getUser';
import {
	addNewerMessages,
	addOlderMessages,
	addOrUpdateMessage,
	getLatestReadMessageDate,
	updateOffset
} from 'utils/messages';
import { scrollMove } from 'utils/scroll';

import { FXSInsightsWrapper } from './styles';

export default function SignalsInsights() {
	const { user } = useContext(UserContext);
	const connected = useContext(PushContext);
	const { filters } = useContext(FilterContext);
	const { state, saveState } = useContext(SignalsInsightsContext);

	const [isFetching, setIsFetching] = useState(false);
	const [scrollElement, setScrollElement] = useState(null as HTMLElement);
	const [signalsSettings, setSignalsSettings] = useState(
		null as UserSettingsResponse
	);
	const [upToDate, setUpToDate] = useState(false);
	const [lastMessageDate, setLastMessageDate] = useState(null);

	const messageFromPush = useSignalMessagesPush(connected);
	const { t } = useTranslationsContext();

	const culture = window.config.Variables.Culture;

	useEffect(() => {
		const element = document.getElementById(SCROLL_CONTAINER_ID);
		setScrollElement(element);
	}, []);

	useEffect(() => {
		setUpToDate(false);
		getUser().then((currentUser) => {
			getMessages(currentUser?.email, filters);
		});
	}, [filters, window.config.Endpoints.SignalMessages]);

	useEffect(() => {
		const editorialPush = messageFromPush?.message as EditorialMessagePush;
		if (editorialPush?.Title !== undefined) {
			const mapped = mapEditorialMessageFromPush(
				editorialPush,
				messageFromPush.method
			);

			if (!isMessageMatch(filters, mapped)) return;

			if (state?.newer.noMore) {
				saveState({
					...state,
					messages: addOrUpdateMessage(
						mapped,
						messageFromPush.method,
						state?.messages
					),
					older: {
						...state?.older,
						offset: updateOffset(
							state?.older.offset,
							messageFromPush.method
						)
					},
					newer: {
						...state?.newer,
						receiving: false
					}
				});
			}
		}

		const tradeMessagePush = messageFromPush?.message as TradeMessagePush;
		if (tradeMessagePush?.SideType !== undefined) {
			const mapped = mapTradeMessageFromPush(tradeMessagePush);
			if (
				filters.categories.length > 0 &&
				!filters.categories.includes(EditorialCategoryType.Trade)
			)
				return;
			if (state?.newer.noMore) {
				saveState({
					...state,
					messages: state?.messages
						? [mapped, ...state.messages]
						: [mapped],
					older: {
						...state?.older,
						offset: updateOffset(
							state?.older.offset,
							messageFromPush.method
						)
					},
					newer: {
						...state?.newer,
						receiving: false
					}
				});
			}
		}
	}, [messageFromPush]);

	useEffect(() => {
		// Dispose
		return () => {
			if (!signalsSettings) return;
			const { id, email } = signalsSettings;
			const latestReadMessageDate = getLatestReadMessageDate(
				state?.messages
			);
			updateSignalsSettings(id, email, latestReadMessageDate);
		};
	});

	function getMessages(
		email: string,
		filters: FilterState,
		date?: string
	): void {
		setIsFetching(true);

		saveState({
			...state,
			messages: []
		});
		getSignalsSettings(email).then((settings) => {
			setSignalsSettings(settings);
			getSignalsMessages(
				date ?? settings?.latestReadMessageDate,
				filters
			).then((payload) => {
				setIsFetching(false);
				if (!payload?.data) {
					saveState({
						...state,
						messages: undefined,
						older: {
							...state?.older,
							isFetching: false,
							error: true
						}
					});
					return;
				}

				if (payload.data.length === 0) {
					saveState({
						...state,
						messages: [],
						older: {
							...state?.older,
							isFetching: false,
							error: false
						}
					});
					setUpToDate(false);
					return;
				}
				if (!payload.noMoreNewer) {
					scrollMove(scrollElement, 50);
				}

				const [currentLastMessageDate] = payload.data.slice(-1);
				setLastMessageDate(currentLastMessageDate.dateUtc);

				saveState({
					...state,
					messages: payload.data,
					newer: {
						...state?.newer,
						noMore: payload.noMoreNewer
					},
					older: {
						...state?.older,
						isFetching: false,
						error: false,
						offset: 0
					}
				});
			});
		});
	}

	function getNewerMessages(): void {
		saveState({
			...state,
			newer: {
				...state?.newer,
				isFetching: true,
				error: false
			}
		});

		loadNewerMessages(
			signalsSettings?.latestReadMessageDate,
			state?.newer.offset,
			filters
		).then((payload) => {
			if (!payload?.data) {
				saveState({
					...state,
					newer: {
						...state?.newer,
						isFetching: false,
						error: true,
						noMore: payload.noMore
					}
				});
				return;
			}

			saveState({
				...state,
				messages: addNewerMessages(payload.data, state?.messages),
				newer: {
					...state?.newer,
					offset: payload.nextOffset,
					isFetching: false,
					error: false,
					noMore: payload.noMore
				}
			});
		});
	}

	function getOlderMessages() {
		saveState({
			...state,
			older: {
				...state?.older,
				isFetching: true,
				error: false
			}
		});

		loadOlderMessages(lastMessageDate, state?.older.offset, filters).then(
			(payload) => {
				if (payload?.data.length === 0) {
					saveState({
						...state,
						older: {
							...state?.older,
							isFetching: false,
							error: false
						}
					});
					setUpToDate(true);
					return;
				}
				if (!payload?.data) {
					saveState({
						...state,
						older: {
							...state?.older,
							isFetching: false,
							error: true
						}
					});
					return;
				}

				saveState({
					...state,
					messages: addOlderMessages(payload.data, state?.messages),
					older: {
						offset: payload.nextOffset,
						isFetching: false,
						error: false
					}
				});
			}
		);
	}

	function onJumpToStart(): void {
		const date = new Date();
		date.setSeconds(0, 0);

		getSignalsSettings(user?.email).then((settings) => {
			setSignalsSettings(settings);

			return getMessages(
				user?.email,
				filters,
				date.toISOString() ?? settings?.latestReadMessageDate
			);
		});
	}

	function onScrollReact(event: React.UIEvent<React.ReactNode>): void {
		const target = event.target as HTMLDivElement;
		if (target.scrollTop === 0) {
			if (!state?.newer.noMore) {
				getNewerMessages();
			}
		}
	}

	return (
		<FXSInsightsWrapper onScroll={onScrollReact}>
			<fxsSignalsInsights.SignalsInsights
				isPremium={user?.isPremium}
				data={state?.messages}
				isFetching={isFetching}
				t={t}
				culture={culture}
				onJumpToStart={onJumpToStart}
				latestReadMessageDate={signalsSettings?.latestReadMessageDate}
				newer={state?.newer}
				older={state?.older}
				signalsPageUrl={window.config.Variables.SignalsPageUrl}
				setLike={(id, status) => setLikedSignals(id, status)}
				setBookmark={(id, status) => setBookmarkedSignals(id, status)}
				getEventFollow={(eventId) => getEventFollow(eventId)}
				setEventFollow={(eventId, eventTitle) =>
					setEventFollow(eventId, eventTitle)
				}
				setTerms={(eventTitle) => setTerms(eventTitle)}
				messagePageUrl={`${window.config.Variables.TradingStudioUrl}${INSIGHTS}/`}
				isEmployee={user?.isEmployee}
			/>
			{state?.messages?.length > 0 && !upToDate && (
				<VisibilitySensor
					onChange={(isVisible) => isVisible && getOlderMessages()}
					delayedCall
					partialVisibility
				>
					<Loading size='2x' />
				</VisibilitySensor>
			)}
			{!isFetching && state?.messages?.length > 0 && upToDate && (
				<FXSToast text={t('updated')} />
			)}
			{!isFetching && state?.messages?.length === 0 && (
				<FXSToast text={t('noFilterResult')} />
			)}
		</FXSInsightsWrapper>
	);
}
