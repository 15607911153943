import React from 'react';

export const EnvelopeSmall = () => {
	return (
		<svg
			fill='none'
			version='1.1'
			viewBox='0 0 14 11'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='m12.688 0.5h-11.375c-0.73828 0-1.3125 0.60156-1.3125 1.3125v7.875c0 0.7383 0.57422 1.3125 1.3125 1.3125h11.375c0.7109 0 1.3125-0.5742 1.3125-1.3125v-7.875c0-0.71094-0.6016-1.3125-1.3125-1.3125zm0 1.3125v1.1211c-0.6289 0.51953-1.6133 1.2852-3.6914 2.9258-0.46484 0.35546-1.3672 1.2305-1.9961 1.2031-0.65625 0.02734-1.5586-0.84766-2.0234-1.2031-2.0781-1.6406-3.0625-2.4063-3.6641-2.9258v-1.1211h11.375zm-11.375 7.875v-5.0586c0.60156 0.49218 1.5039 1.2031 2.8438 2.2695 0.60156 0.46484 1.668 1.5039 2.8438 1.4766 1.1484 0.02734 2.1875-1.0117 2.8164-1.4766 1.3398-1.0664 2.2422-1.7774 2.8711-2.2695v5.0586h-11.375z'
				fill='#BDBDC2'
			/>
		</svg>
	);
};
