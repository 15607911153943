import { useLayoutEffect, useState } from 'react';

export function useWindowWidth(): number {
	const [width, setWidth] = useState(null);

	useLayoutEffect(() => {
		function handleResize() {
			setWidth(window.innerWidth);
		}
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return width;
}

export const isLessThan = (maxWindowWidth: number): boolean => {
	const windowWidth = useWindowWidth();
	return windowWidth < maxWindowWidth;
};
