import { useContext, useEffect, useState } from 'react';

import { INSIGHTS } from 'constants/locations';
import { PRE_SIGNAL_QUERY, TAGS_QUERY } from 'constants/queryParams';
import { FilterState } from 'contexts/filtersContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { getSignalsMessages } from 'data/getSignalsMessages';
import { getTags } from 'data/getTags';
import { getEventFollow } from 'data/getUserEventFollow';
import { setBookmarkedSignals } from 'data/setBookmarkedSignals';
import { setLikedSignals } from 'data/setLikedSignals';
import { setTerms } from 'data/setTerms';
import { setEventFollow } from 'data/setUserEventFollow';
import * as fxsSignalsInsights from 'fxs-signals-insights';
import { EditorialMessage, SignalsMessageState } from 'models/signalMessages';
import { getQueryParam } from 'utils/getQueryParam';
import { mapNonFXTags } from 'utils/mapTags';

import { FXSInsightsWrapper } from './styles';

const initialState: SignalsMessageState = {
	messages: undefined,
	isFetching: true
};

const PreSignalLatest = () => {
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();
	const culture = window.config.Variables.Culture;
	const [preSignalData, setPreSignalData] = useState(initialState);

	const preOrderTitle = getQueryParam(PRE_SIGNAL_QUERY);
	const tags = mapNonFXTags(getQueryParam(TAGS_QUERY));

	const filter: FilterState = {
		categories: [],
		markets: [],
		tags: []
	};

	useEffect(() => {
		getTags().then((allTags) => {
			allTags['Tags'].forEach((x) => {
				if (tags.includes(x.Name.toLowerCase())) {
					filter.tags.push(x.Id);
				}
			});
			getSignalsMessages(null, filter, 50).then((res) => {
				const messages = res.data.map(
					(message) => message as EditorialMessage
				);
				setPreSignalData({
					messages: messages.filter((data) =>
						data.title
							?.toLocaleLowerCase()
							.includes(preOrderTitle.toLocaleLowerCase())
					),
					isFetching: false
				});
			});
		});
	}, []);

	return (
		<FXSInsightsWrapper>
			<fxsSignalsInsights.SignalsInsights
				isPremium={user?.isPremium}
				data={preSignalData.messages}
				isFetching={preSignalData.isFetching}
				t={t}
				culture={culture}
				signalsPageUrl={window.config.Variables.SignalsLandingUrl}
				setLike={(id, status) => setLikedSignals(id, status)}
				setBookmark={(id, status) => setBookmarkedSignals(id, status)}
				getEventFollow={(eventId) => getEventFollow(eventId)}
				setEventFollow={(eventId, eventTitle) =>
					setEventFollow(eventId, eventTitle)
				}
				setTerms={(eventTitle) => setTerms(eventTitle)}
				messagePageUrl={`/${INSIGHTS}/`}
				isEmployee={user?.isEmployee}
			/>
		</FXSInsightsWrapper>
	);
};

export default PreSignalLatest;
