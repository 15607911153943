import {
	StrategyHistorical,
	StrategyHistoricalResponse
} from '../models/historicalData';

export const mapHistoricalChart = (
	source: StrategyHistoricalResponse
): StrategyHistorical => ({
	asset: source.asset,
	closingTimeUtc: new Date(source.closingTimeUtc),
	entryPrice: source.entryPrice,
	growthPercentage: source.growthPercentage,
	id: source.id,
	normalizedProfit: source.normalizedProfit,
	openingTimeUtc: new Date(source.openingTimeUtc),
	orderType: source.orderType,
	pips: source.pips,
	positionMaintenanceId: source.positionMaintenanceId,
	sideType: source.sideType,
	stopLoss: source.stopLoss,
	strategyFancyName: source.strategyFancyName,
	strategyId: source.strategyId
});
