import styled from 'styled-components';

export const YoutubeWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	height: calc(100% - 90px);
	iframe {
		flex: 1 1 100%;
		width: 100%;
		height: 100%;
		@media (min-width: 1280px) {
			&:first-child {
				width: 750%;
				flex: 1 1 75%;
			}
			&:last-child {
				width: 25%;
				flex: 1 1 25%;
			}
		}
	}
`;
