import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faAnalytics,
	faExclamationCircle,
	faFileSearch,
	faUserChart
} from '@fortawesome/pro-solid-svg-icons';

import { EditorialCategoryType } from '../models/enums';

export type CategoryProps = {
	id: number;
	icon: IconProp;
	label: string;
	value: EditorialCategoryType;
};

export const Categories: CategoryProps[] = [
	{
		id: 11,
		icon: faUserChart as IconProp,
		label: 'educationType',
		value: EditorialCategoryType.Education
	},
	{
		id: 12,
		icon: faFileSearch as IconProp,
		label: 'analysisType',
		value: EditorialCategoryType.Analysis
	},
	{
		id: 13,
		icon: faExclamationCircle as IconProp,
		label: 'warningType',
		value: EditorialCategoryType.Warning
	},
	{
		id: 14,
		icon: faAnalytics as IconProp,
		label: 'reviewType',
		value: EditorialCategoryType.Review
	}
];
