import styled from 'styled-components';
import { COLORS } from 'styles/colors';


export const HeaderButton = styled.button`
	position: relative;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	box-sizing: border-box;
	height: 40px;
	width: calc(100% - 24px);
	padding: 8px;
	border: 1px solid transparent;
	color: ${COLORS.white};
	font-weight: 700;
	font-size: 1rem;
	background-color: transparent;
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	svg {
		fill: ${COLORS.white};
	}

	@media (min-width: 833px) {
		justify-content: center;
		height: 40px;
		width: unset;
		padding: 12px 8px;
		color: ${COLORS.dark};
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
		background-color: ${COLORS.white};
		svg {
			fill: ${COLORS.grey};
		}
		&:hover,
		&:active {
			background-color: ${COLORS.interaction_blue_light_2};
		}
		&:hover {
			svg {
				fill: ${COLORS.interaction_blue};
			}
		}
	}
`;

/************************
  TOOLTIP
************************/
export const HeaderTooltip = styled.div`
	position: absolute;
	bottom: -40px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	height: 28px;
	width: max-content;
	padding: 4px 8px;
	background: ${COLORS.dark};
	border-radius: 4px;
	color: ${COLORS.white};
	&::before {
		content: '';
		position: absolute;
		top: -6px;
		left: 50%;
		transform: translateX(-50%);
		width: 0;
		height: 0;
		border-left: 12px solid transparent;
		border-right: 12px solid transparent;
		border-bottom: 12px solid ${COLORS.dark};
	}
	& span {
		color: inherit;
		font-weight: 700;
		font-size: 12px;
		line-height: 20px;
	}
`;
