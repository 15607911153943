import { CrudMethod } from '../models/enums';
import { TradePlan } from '../models/tradePlan';

const addOrUpdateTradePlanInList = (
	list: TradePlan[],
	newTradePlan: TradePlan
): TradePlan[] => {
	const currentIndex = list.findIndex((tp) => tp.id === newTradePlan.id);
	let result = [...list];

	if (currentIndex < 0) result = [newTradePlan, ...list];
	else {
		result = [...list];
		result[currentIndex] = newTradePlan;
	}

	return result;
};

const deleteTradePlanInList = (
	list: TradePlan[],
	newTradePlan: TradePlan
): TradePlan[] => {
	const currentIndex = list.findIndex((tp) => tp.id === newTradePlan.id);
	const result = [...list];
	if (currentIndex >= 0) result.splice(currentIndex, 1);

	return result;
};

export const addOrUpdateTradePlan = (
	tradePlan: TradePlan,
	method: CrudMethod,
	data?: TradePlan[]
) => {
	if (!data) return undefined;

	let result = data;
	switch (method) {
		case CrudMethod.Create:
		case CrudMethod.Update:
			result = addOrUpdateTradePlanInList(data, tradePlan);
			break;
		case CrudMethod.Delete:
			result = deleteTradePlanInList(data, tradePlan);
			break;
		default:
			break;
	}

	result.sort((a, b) => {
		return new Date(b.dateUtc).getTime() - new Date(a.dateUtc).getTime();
	});

	return result;
};
