import React from 'react';

import { Config } from '../models/config';

type ContextProps = {
	config: Config;
	saveConfig(newConfig: Config): void;
};

export const initialConfiguration: Config = {
	Endpoints: {
		SignalMessage: '',
		SignalMessages: '',
		MasterData: '',
		SignalMostLiked: '',
		SignalStatsStrategyId: '',
		SignalStrategyStrategyId: '',
		SignalUserSettings: '',
		SignalUserSettingsEmail: '',
		SignalUserSettingsId: '',
		SignalHistorical: '',
		SignalStats: '',
		Signals: '',
		SignalTradePlan: '',
		Lead: '',
		Push: '',
		SignalBookmark: '',
		SignalLike: '',
		SignalStrategies: '',
		UserEventFollows: '',
		UserEventFollowsByPostId: '',
		UserTypeformSettings: '',
		Logout: '',
		Newsletters: '',
		UserNewsletters: '',
		Localization: ''
	},
	MasterClass: {
		IsEnabled: '',
		EmbedCode: ''
	},
	MarketBias: {
		GoogleExcelURL: ''
	},
	Variables: {
		AlgoliaApiKey: '',
		AlgoliaAppId: '',
		AlgoliaIndex: '',
		AlgoliaTake: 0,
		Culture: '',
		TimeToRefresh: 180000,
		SignalsLandingUrl: '',
		SignalsPageUrl: '',
		GtmId: '',
		NotificationSoundUrl: '',
		StaticContentBaseUrl: '',
		FxsSite: '',
		TypeformId: '',
		TradingViewUrl: '',
		NonFXAssets: '',
		DiscordUrl: '',
		TradingStudioUrl: '',
		NotificationEnabled: false,
		HowEnableNotificationsUrl: '',
		OneSignalAppId: '',
		CookieDomain: '',
		AuthenticationCookie: '',
		LocalizationApplicationId: '',
		FXSWidgetUrl: '',
		DashboardUrl: ''
	},
	ExpertsSchedule: []
};

export const ConfigContext = React.createContext<ContextProps>(null);
