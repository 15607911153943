import { TradePlanPush, TradePlanPushPayload } from 'models/tradePlanPush';

import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	LogLevel
} from '@microsoft/signalr';

import { CrudMethod } from '../models/enums';
import {
	EditorialMessagePush,
	MessagePushPayload,
	TradeMessagePush
} from '../models/signalPushMessages';

export class SignalPushManager {
	connection: HubConnection = null;
	connectionPromise: Promise<HubConnection> = null;
	tradeCallbacks: { (signal: TradeMessagePush): void }[] = [];
	editorialCallbacks: { (editorial: MessagePushPayload): void }[] = [];
	tradePlanCallbacks: { (tradePlan: TradePlanPushPayload): void }[] = [];
	tradeUpdatedStarted = false;
	editorialStarted = false;
	tradePlanStarted = false;
	serverUrl: string;

	constructor(serverUrl: string) {
		this.serverUrl = serverUrl;
	}

	getConnection() {
		if (this.connection) return Promise.resolve(this.connection);
		if (this.connectionPromise) return this.connectionPromise;
		const self = this;
		this.connectionPromise = new Promise((resolve) => {
			let retry = 5;
			let timeoutId = null;
			let stoppedForTime = false;
			const connection = new HubConnectionBuilder()
				.withUrl(`${this.serverUrl}/signalHub`)
				.configureLogging(LogLevel.Information)
				.build();
			connection.on('trade', (msg) => {
				self.tradeCallbacks.forEach((cb) => {
					cb(msg);
				});
			});

			function start() {
				if (connection.state === HubConnectionState.Disconnected) {
					connection
						.start()
						.then(() => {
							if (self.tradeUpdatedStarted)
								connection.invoke('tradeUpdated');
							if (self.editorialStarted)
								connection.invoke('editorial');
							if (self.tradePlanStarted)
								connection.invoke('tradePlan');

							self.connection = connection;
							self.connectionPromise = null;

							resolve(connection);

							if (timeoutId !== null) {
								window.clearTimeout(timeoutId);
							}

							retry = 5;
							stoppedForTime = false;
							timeoutId = window.setTimeout(
								() => {
									retry = 5;
									timeoutId = null;
									stoppedForTime = true;
									connection.stop();
								},
								480 * 60 * 1000
							);
						})
						.catch((err) => {
							console.log(err);

							retry--;

							if (retry > 0) {
								setTimeout(() => {
									start();
								}, Math.random() * 120000);
							}
						});
				} else {
					resolve(connection);
					self.connection = connection;
					self.connectionPromise = null;
				}
			}
			connection.onclose(() => {
				if (!stoppedForTime) start();
			});
			start();
		});
		return this.connectionPromise;
	}

	signalSubscribe(callback: (signal: TradeMessagePush) => void) {
		this.tradeCallbacks.push(callback);
		const self = this;
		this.getConnection().then((connection) => {
			if (self.tradeUpdatedStarted) return;

			connection.invoke('tradeUpdated');
			connection.on('tradeUpdated', (msg) => {
				self.tradeCallbacks.forEach((cb) => {
					cb(msg);
				});
			});
			self.tradeUpdatedStarted = true;
		});
	}

	signalEditorialSubscribe(callback: (signal: MessagePushPayload) => void) {
		this.editorialCallbacks.push(callback);

		const self = this;
		this.getConnection().then((connection) => {
			if (self.editorialStarted) return;

			connection.invoke('editorial');
			connection.on('editorialCreated', (msg) => {
				self.editorialCallbacks.forEach((cb) => {
					cb({
						message: msg as EditorialMessagePush,
						method: CrudMethod.Create
					});
				});
			});
			connection.on('editorialUpdated', (msg) => {
				self.editorialCallbacks.forEach((cb) => {
					cb({ message: msg, method: CrudMethod.Update });
				});
			});
			connection.on('editorialDeleted', (msg) => {
				self.editorialCallbacks.forEach((cb) => {
					cb({ message: msg, method: CrudMethod.Delete });
				});
			});

			self.editorialStarted = true;
		});
	}

	signalTradePlanSubscribe(
		callback: (tradePlan: TradePlanPushPayload) => void
	) {
		this.tradePlanCallbacks.push(callback);

		const self = this;
		this.getConnection().then((connection) => {
			if (self.tradePlanStarted) return;

			connection.invoke('tradePlan');
			connection.on('tradePlanCreated', (trade) => {
				self.tradePlanCallbacks.forEach((cb) => {
					cb({
						tradePlan: trade as TradePlanPush,
						method: CrudMethod.Create
					});
				});
			});
			connection.on('tradePlanUpdated', (trade) => {
				self.tradePlanCallbacks.forEach((cb) => {
					cb({ tradePlan: trade, method: CrudMethod.Update });
				});
			});
			connection.on('tradePlanDeleted', (trade) => {
				self.tradePlanCallbacks.forEach((cb) => {
					cb({ tradePlan: trade, method: CrudMethod.Delete });
				});
			});

			self.tradePlanStarted = true;
		});
	}

	signalUnSubscribe() {
		if (this.connection) {
			this.connection.stop();
		}
	}
}
