import styled, { css } from 'styled-components';
import { COLORS } from 'styles/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SidebarStyledProps {
	isLateralMobileMenuOpened: boolean;
}
const Sidebar = css`
	display: flex;
	flex-direction: column;
	width: 255px;
	background: ${COLORS.corp};
`;
/************************
  MOBILE
************************/
export const FXSBackdrop = styled.div<SidebarStyledProps>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: ${(props) => (props.isLateralMobileMenuOpened ? '0' : '-200%')};
	right: ${(props) => (props.isLateralMobileMenuOpened ? '0' : '100%')};
	display: flex;
	justify-content: flex-start;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	max-height: 100vh;
	overflow-y: auto;
	transition: all 0.3s ease-in-out;
`;
export const FXSSidebarMobile = styled.aside`
	${Sidebar}
`;
export const FXSSidebarTop = styled.div`
	position: relative;
	margin-bottom: 1.5rem;
	padding: 4rem 0 2rem;
	border-bottom: 1px solid ${COLORS.blue};
`;
export const CloseIcon = styled(FontAwesomeIcon)`
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 24px;
	width: 24px !important;
	color: ${COLORS.white};
`;
/************************
  DESKTOP
************************/
export const FXSSidebarDesktop = styled.aside`
	${Sidebar}
	grid-area: sidebar;
`;
export const FXSPremiumLogoDesktop = styled.img`
	height: 66px;
	width: 148px;
	margin: 3rem 3.75rem 5rem 2.5rem;
`;
