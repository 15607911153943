import { SideType, Signal, StatusType } from 'fxs-signals-chip';
import { toDateUtc } from 'fxs-utils';

import { SidePushType, TradeActionType } from '../models/enums';
import { TradeMessagePush } from '../models/signalPushMessages';
import { Trade } from '../models/trades';

const getStatus = (
	closingTimeUtc: Date | undefined,
	pips: number
): StatusType => {
	if (!closingTimeUtc) return StatusType.Running;
	if (pips > 0) return StatusType.Win;
	return StatusType.Loss;
};

const getSideType = (sideType: SidePushType): SideType => {
	if (sideType === SidePushType.Buy) return SideType.Buy;
	return SideType.Sell;
};

export const mapFromApi = (source: Trade): Signal => ({
	positionMaintenanceId: source.positionMaintenanceId,
	asset: source.asset,
	strategyFancyName: source.strategyFancyName,
	strategyId: source.strategyId,
	entryPrice: source.entryPrice,
	profitPercent: source.profitPercent,
	stopLoss: source.stopLoss,
	takeProfit: source.takeProfit,
	openingTimeUtc: toDateUtc(source.openingTimeUtc),
	closingTimeUtc: source.closingTimeUtc
		? toDateUtc(source.closingTimeUtc)
		: undefined,
	sideType: getSideType(source.sideType),
	status: getStatus(source.closingTimeUtc, source.pips || 0),
	isHighlighted: source.isHighlighted,
	limitType: source.limitType,
	limitsHaveChanged: false,
	exitPrice: source.exitPrice,
	pips: source.pips
});

export const mapFromPush = (source: TradeMessagePush): Trade => ({
	positionMaintenanceId: source.PositionMaintenanceId,
	asset: source.Asset,
	strategyFancyName: source.StrategyFancyName,
	strategyId: source.StrategyId,
	entryPrice: source.EntryPrice,
	profitPercent: source.ProfitPercent,
	stopLoss: source.StopLoss,
	takeProfit: source.TakeProfit,
	openingTimeUtc: toDateUtc(source.OpeningTimeUtc),
	closingTimeUtc: source.ClosingTimeUtc
		? toDateUtc(source.ClosingTimeUtc)
		: undefined,
	sideType: source.SideType,
	status: getStatus(source.ClosingTimeUtc, source.Pips || 0),
	isHighlighted: true,
	limitType: source.LimitType,
	limitsHaveChanged: source.ActionType === TradeActionType.Updated,
	exitPrice: source.ExitPrice,
	pips: source.Pips
});
