import { SignalsMessageRequest } from '../models/signalMessages';
import { getUser } from '../utils/getUser';

const fetcher = async (messageId, status): Promise<any> => {
	return getUser().then(async (user) => {
		await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalBookmark}`,
			{
				method: status ? 'DELETE' : 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ messageId } as SignalsMessageRequest)
			}
		);
		return messageId;
	});
};

export async function setBookmarkedSignals(
	messageId: string,
	status: boolean
): Promise<any> {
	return fetcher(messageId, status);
}
