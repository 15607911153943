import React from 'react';

import { MARKETS } from 'constants/filter';
import { Markets } from 'constants/markets';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'utils/useWindowWidth';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RowTypes } from './RowType';
import {
	FXSFilterMarketBtn,
	FXSFilterMarketWrapper,
	FXSFilterModalRow,
	FXSFilterTitle
} from './styles';

const MarketRow = ({ activeTrades, state, handleClick }: RowTypes) => {
	const isLessThan833 = isLessThan(833);
	const { t } = useTranslationsContext();

	return (
		<FXSFilterModalRow activeTrade={activeTrades}>
			<FXSFilterTitle isLessThan833={isLessThan833}>
				{t('markets')}
			</FXSFilterTitle>
			<FXSFilterMarketWrapper>
				{Markets.map((m) => (
					<FXSFilterMarketBtn
						key={m.id}
						checked={state?.markets?.includes(m.value)}
						disabled={activeTrades}
						onClick={(e) =>
							handleClick(e, m.value, MARKETS, null, false)
						}
					>
						{state?.markets?.includes(m.value) && (
							<FontAwesomeIcon icon={faCheck as IconProp} />
						)}
						{m.value}
					</FXSFilterMarketBtn>
				))}
			</FXSFilterMarketWrapper>
		</FXSFilterModalRow>
	);
};

export default MarketRow;
