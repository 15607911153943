import React from 'react';

import { Categories } from 'constants/categories';
import { CATEGORIES } from 'constants/filter';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'utils/useWindowWidth';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/pro-regular-svg-icons';

import { RowTypes } from './RowType';
import {
	FXSFilterCategoryIcon,
	FXSFilterCategoryLabel,
	FXSFilterCategoryLeft,
	FXSFilterCategoryRow,
	FXSFilterCheckbox,
	FXSFilterModalRow,
	FXSFilterTitle
} from './styles';

const CategoryRow = ({ activeTrades, state, handleClick }: RowTypes) => {
	const isLessThan833 = isLessThan(833);
	const { t } = useTranslationsContext();

	return (
		<FXSFilterModalRow activeTrade={activeTrades}>
			<FXSFilterTitle isLessThan833={isLessThan833}>
				{t('categories')}
			</FXSFilterTitle>
			{Categories.map((c) => (
				<FXSFilterCategoryRow
					key={c.id}
					disabled={activeTrades}
					onClick={(e) =>
						handleClick(e, c.value, CATEGORIES, null, false)
					}
				>
					<FXSFilterCategoryLeft>
						<FXSFilterCategoryIcon icon={c.icon as IconProp} />
						<FXSFilterCategoryLabel>
							{t(c.label)}
						</FXSFilterCategoryLabel>
					</FXSFilterCategoryLeft>
					<FXSFilterCheckbox
						icon={
							state?.categories?.includes(c.value)
								? (faCheckSquare as IconProp)
								: (faSquare as IconProp)
						}
						checked={state?.categories?.includes(c.value)}
						disabled={activeTrades}
						onClick={(e) =>
							handleClick(e, c.value, CATEGORIES, null, false)
						}
					/>
				</FXSFilterCategoryRow>
			))}
		</FXSFilterModalRow>
	);
};

export default CategoryRow;
