import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const FXSDividerWide = styled.hr`
	display: block;
	height: 7px;
	margin: -15px -15px 15px;
	background-color: ${COLORS.grey300};
	border: none;
	box-sizing: border-box;
	@media (min-width: 833px) {
		margin: -25px -25px 25px;
	}
`;
