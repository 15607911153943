import Routes from 'Routes';

import { BOOKMARKS, LATEST, POPULAR } from 'constants/locations';
import { InsightsType } from 'models/enums';

export interface TabLinksTypes {
	id: number;
	label: string;
	route: Routes;
	type: InsightsType;
}

export const TabLinks: TabLinksTypes[] = [
	{ id: 11, label: LATEST, route: Routes.HOME, type: InsightsType.latest },
	{
		id: 12,
		label: POPULAR,
		route: Routes.POPULAR,
		type: InsightsType.popular
	},
	{
		id: 13,
		label: BOOKMARKS,
		route: Routes.BOOKMARKS,
		type: InsightsType.bookmarks
	}
];
