import {
	SignalStrategyFullStatsResponse,
	SignalStrategyStatsResponse,
	StatCalculationResponse
} from '../models/strategy';
import { getUser } from '../utils/getUser';

const fetcher = async (): Promise<SignalStrategyStatsResponse> => {
	return getUser().then(async (user) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.SignalStats}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `bearer ${user?.token}`
					}
				}
			);

			const data = await response.json();
			const statsReponse = { data } as SignalStrategyStatsResponse;
			statsReponse.data = sortList(statsReponse.data);
			return statsReponse;
		} catch (error) {
			console.log(error);
			return { error: true } as SignalStrategyStatsResponse;
		}
	});
};

export async function getStrategyFullStats(): Promise<SignalStrategyStatsResponse> {
	return await fetcher();
}

const sortList = (
	statsData: SignalStrategyFullStatsResponse[]
): SignalStrategyFullStatsResponse[] => {
	let sortedStrategies: SignalStrategyFullStatsResponse[] = [];

	if (statsData !== null) {
		sortedStrategies = statsData.sort((a, b) =>
			compare(a.statCalculation, b.statCalculation)
		);
	}
	return sortedStrategies;
};

const compare = (
	statCalculationA: StatCalculationResponse,
	statCalculationB: StatCalculationResponse
) => {
	if (statCalculationA !== null && statCalculationB !== null) {
		if (statCalculationA.winRate > statCalculationB.winRate) {
			return -1;
		}
		if (statCalculationA.winRate < statCalculationB.winRate) {
			return 1;
		}
	}
	return 0;
};
