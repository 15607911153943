import { TradePlan } from '../models/tradePlan';
import { getUser } from '../utils/getUser';

const fetcher = async () => {
	return getUser().then((user) => {
		return fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalTradePlan}`,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			}
		).then(async (res) => {
			const result = await res.json();
			return result.map((t) => {
				t.queryLink = t.insightsQuery;
				return t;
			});
		});
	});
};

export async function getTradePlan(): Promise<TradePlan[]> {
	return fetcher();
}
