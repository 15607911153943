import React from 'react';

import {
	ANALYSIS,
	COMMUNITY,
	ECONOMIC,
	SIGNAL,
	SPECIAL
} from 'constants/notifications';

type ContextProps = {
	notifications: NotificationsState;
	saveNotifications(newState: NotificationsState): void;
	clearNotifications(): void;
};

export type NotificationsState = {
	[SIGNAL]: boolean;
	[ECONOMIC]: boolean;
	[ANALYSIS]: boolean;
	[COMMUNITY]: boolean;
	[SPECIAL]: boolean;
};

export const initialNotificationsState: NotificationsState = {
	[SIGNAL]: true,
	[ECONOMIC]: true,
	[ANALYSIS]: true,
	[COMMUNITY]: true,
	[SPECIAL]: true
};

export const noneNotificationsState: NotificationsState = {
	[SIGNAL]: false,
	[ECONOMIC]: false,
	[ANALYSIS]: false,
	[COMMUNITY]: false,
	[SPECIAL]: false
};

export const NotificationsContext = React.createContext<ContextProps>(null);
