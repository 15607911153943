import { useContext, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { ConfigContext } from 'contexts/configContext';
import runOneSignal from 'utils/oneSignalManager';

import { UserContext } from '../contexts/userContext';
import { getUser } from '../utils/getUser';

export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const { config } = useContext(ConfigContext);
	const [isReady, setIsReady] = useState(false);

	async function getUserData() {
		const user = await getUser();
		if (!user) {
			window.location.href = `${config.Variables.FxsSite}/account/login?uriToRedirect=${window.location.href}`;
			return;
		}
		return user;
	}

	useEffect(() => {
		const userData = getUserData();
		userData.then((user) => {
			if (user) {
				setUser(user);
				TagManager.dataLayer({
					dataLayer: {
						userId: user.id,
						isPremium: user.isPremium,
						event: 'userFetch'
					}
				});
				runOneSignal(config.Variables.OneSignalAppId);
				setIsReady(true);
			}
		});
	}, []);

	return (
		isReady && (
			<UserContext.Provider value={{ user, setUser }}>
				{children}
			</UserContext.Provider>
		)
	);
};
