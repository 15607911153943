import React from 'react';

import { NotificationList } from 'constants/notifications';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { NotificationProps } from 'models/props';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';

import { Bell } from './bell';
import {
	CloseIcon,
	NotificatiionSnackbarP,
	NotificationCloseText,
	NotificationDescription,
	NotificationGreybox,
	NotificationIcon,
	NotificationModalAllOnP,
	NotificationModalAllOnWrapper,
	NotificationModalDescription,
	NotificationModalIcon,
	NotificationModalIconWrapper,
	NotificationModalSwitchCircle,
	NotificationModalSwitchWrapper,
	NotificationsBackdrop,
	NotificationsModalBody,
	NotificationsModalBtnReset,
	NotificationsModalBtnSave,
	NotificationsModalBtnWrapper,
	NotificationsModalCheckbox,
	NotificationsModalRow,
	NotificationsModalSuggestions,
	NotificationsModalSuggestionsP,
	NotificationsModalTypeformPopUpBtn,
	NotificationsSidebar,
	NotificationsSidebarHeader,
	NotificationsSidebarTitle,
	NotificationsSnackbar
} from './styles';
import { useNotification } from './useNotifications';

const NotificationsModal: React.FC<NotificationProps> = ({
	elementRef,
	isNotificationsModalOpened,
	setIsNotificationsModalOpened
}) => {
	const {
		handleCloseModal,
		handleAllNotificationsOn,
		handleNotifications,
		save,
		resetNotifications,
		allNotificationsOn,
		changesSaved,
		state,
		areChanges
	} = useNotification(
		isNotificationsModalOpened,
		setIsNotificationsModalOpened
	);
	const { t } = useTranslationsContext();

	const isLessThan833 = isLessThan(833);
	const dismissedNotificationDescription = localStorage.getItem(
		'dismissedNotificationDescription'
	);

	return (
		<NotificationsBackdrop
			isNotificationsModalOpened={isNotificationsModalOpened}
		>
			<NotificationsSidebar ref={elementRef}>
				<NotificationsSidebarHeader>
					<NotificationsSidebarTitle>
						{t('notificationPreferences')}
					</NotificationsSidebarTitle>
					<CloseIcon
						icon={faTimes as IconProp}
						onClick={handleCloseModal}
					/>
				</NotificationsSidebarHeader>
				{!dismissedNotificationDescription && (
					<NotificationGreybox show={true}>
						<div>
							<NotificationDescription>
								{t('notificationDescription')}
							</NotificationDescription>
							<NotificationCloseText
								onClick={(e) => {
									handleCloseModal(e);
									localStorage.setItem(
										'dismissedNotificationDescription',
										'true'
									);
								}}
							>
								{t('notificationCloseText')}
							</NotificationCloseText>
						</div>
						<Bell />
					</NotificationGreybox>
				)}
				<NotificationsModalBody>
					<NotificationModalAllOnWrapper>
						<NotificationModalAllOnP>
							{t('allCategoryOn')}
						</NotificationModalAllOnP>
						<NotificationsModalCheckbox>
							<NotificationModalSwitchWrapper
								isActive={allNotificationsOn}
								onClick={handleAllNotificationsOn}
							>
								<NotificationModalSwitchCircle
									isActive={allNotificationsOn}
									onClick={handleAllNotificationsOn}
								/>
							</NotificationModalSwitchWrapper>
						</NotificationsModalCheckbox>
					</NotificationModalAllOnWrapper>
					{NotificationList.map((notification) => (
						<NotificationsModalRow
							key={notification.id}
							onClick={() => handleNotifications(notification.id)}
						>
							<NotificationModalIconWrapper>
								<NotificationModalIcon
									icon={notification.icon as IconProp}
								/>
							</NotificationModalIconWrapper>
							<NotificationModalDescription>
								<h3>{t(notification.title)}</h3>
								<p>{t(notification.description)}</p>
							</NotificationModalDescription>
							<NotificationsModalCheckbox>
								<NotificationModalSwitchWrapper
									isActive={state[notification.id]}
									onClick={() =>
										handleNotifications(notification.id)
									}
								>
									<NotificationModalSwitchCircle
										isActive={state[notification.id]}
										onClick={() =>
											handleNotifications(notification.id)
										}
									/>
								</NotificationModalSwitchWrapper>
							</NotificationsModalCheckbox>
						</NotificationsModalRow>
					))}
				</NotificationsModalBody>

				<NotificationsModalSuggestions>
					<NotificationsModalSuggestionsP>
						{t('notificationSuggestions')} {isLessThan833 && <br />}
						<strong>{t('letUsKnow')}</strong>
					</NotificationsModalSuggestionsP>
					<NotificationsModalTypeformPopUpBtn id='Bb59aAVk'>
						{t('sendSuggestions')}
					</NotificationsModalTypeformPopUpBtn>
				</NotificationsModalSuggestions>

				<NotificationsModalBtnWrapper>
					{changesSaved && (
						<NotificationsSnackbar>
							<NotificationIcon icon={faCheck as IconProp} />
							<NotificatiionSnackbarP>
								{t('changesSaved')}
							</NotificatiionSnackbarP>
						</NotificationsSnackbar>
					)}

					<NotificationsModalBtnSave
						onClick={save}
						disabled={!areChanges}
					>
						{t('save')} {isLessThan833 ? '' : t('preferences')}
					</NotificationsModalBtnSave>
					<NotificationsModalBtnReset
						onClick={resetNotifications}
						disabled={!areChanges}
					>
						{t('reset')} {isLessThan833 ? '' : t('preferences')}
					</NotificationsModalBtnReset>
				</NotificationsModalBtnWrapper>
			</NotificationsSidebar>
		</NotificationsBackdrop>
	);
};

export default NotificationsModal;
