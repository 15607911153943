import { useTranslationsContext } from 'contexts/translationsContext';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FXSGoTo } from './styles';

const GoToFXS = () => {
	const { t } = useTranslationsContext();

	return (
		<FXSGoTo
			href={window.config.Variables.FxsSite}
			data-ga4event-clk-name='header_click'
			data-ga4event-element='back_to_fxstreet'
		>
			<FontAwesomeIcon icon={faArrowLeft as IconProp} />
			<span>{t('goto')}</span>
		</FXSGoTo>
	);
};

export default GoToFXS;
