import styled from 'styled-components';
import { COLORS } from 'styles/colors';

/************************
  MOBILE
************************/
export const FXSDiscordBtnLinkMobile = styled.a`
	display: flex;
	gap: 8px;
	align-items: center;
	background: transparent;
	border: none;
	text-decoration: none;
	white-space: nowrap;
	cursor: pointer;
	height: 40px;
	width: calc(100% - 24px);
	padding: 8px;
	justify-content: flex-start;
	color: ${COLORS.white};
	font-weight: 700;
	font-size: 16px;
	line-height: 20px;
	&:hover {
		text-decoration: none;
	}
	svg {
		fill: ${COLORS.white};
	}
`;
/************************
  DESKTOP
************************/

export const DiscordBtnLinkDesktop = styled.a`
	background: ${COLORS.highlight};
	color: ${COLORS.white};
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	
	padding: 8px 12px;
	gap: 4px;
	
	position: relative;
	height: 40px;

	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	text-align: center;
	text-decoration: none;

	white-space: nowrap;

	&:hover {
		background: ${COLORS.orange_highlight_light_1};
		text-decoration: none;
	}

	svg {
		path {
			fill: ${COLORS.white};
		}
		circle {
			display: none;
		}
	}
`;
