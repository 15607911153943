export type TimeZone = {
	TimeZoneId: string;
	TimeSpan: string;
	Location: string;
	CurrentTimeZone: boolean;
	HoursUtcOffset: number;
};

export type TimezoneApiResponse = {
	TimeZones: TimeZone[];
	SetAutomaticallyTimeZone: TimeZone;
	CurrentTimeZone: TimeZone;
	Translations: any;
	IsTwelveTimeFormat: boolean;
};

declare global {
	interface Window {
		getTimezoneHelper: () => void;
	}
}

export const timeZoneHelper = (timeZoneResponse) => {
	(window as any).getTimezoneHelper = () => {
		return new Promise((resolve) => {
			resolve({
				getTimezonesModel: () => {
					return new Promise((res) => {
						const previousTimeZone = JSON.parse(
							localStorage.getItem('timezone')
						);
						timeZoneResponse['IsTwelveTimeFormat'] =
							previousTimeZone
								? previousTimeZone['IsTwelveTimeFormat']
								: timeZoneResponse['IsTwelveTimeFormat'];

						if (previousTimeZone) {
							const timeZone = timeZoneResponse.TimeZones.find(
								(x) =>
									x.TimeZoneId === previousTimeZone.TimeZoneId
							);
							timeZoneResponse.CurrentTimeZone =
								timeZone ?? timeZoneResponse.CurrentTimeZone;
						} else {
							const timeZoneId = getUserTimeZoneId();
							timeZoneResponse.CurrentTimeZone =
								timeZoneResponse.TimeZones.find(
									(x) => x.TimeZoneId === timeZoneId
								);
						}

						timeZoneResponse.CurrentTimeZone.CurrentTimeZone = true;
						timeZoneResponse.TimeZoneInfoId =
							timeZoneResponse.CurrentTimeZone.TimeZoneId;

						const clockEvent = new CustomEvent('updateClockEvent', {
							detail: {
								timezoneIdSelected:
									timeZoneResponse.TimeZoneInfoId,
								isTwelveTimeFormat:
									timeZoneResponse.IsTwelveTimeFormat
							}
						});
						document.dispatchEvent(clockEvent);

						res(timeZoneResponse);
					});
				},
				setTimezone: (newTimeZone) => {
					return new Promise((res) => {
						const previousTimeZone = JSON.parse(
							localStorage.getItem('timezone')
						);
						newTimeZone['IsTwelveTimeFormat'] = previousTimeZone
							? previousTimeZone['IsTwelveTimeFormat']
							: false;
						localStorage.setItem(
							'timezone',
							JSON.stringify(newTimeZone)
						);
						res(newTimeZone);
					});
				},
				setTimeFormat: (isTwelveHours) => {
					return new Promise(() => {
						const timeZone = JSON.parse(
							localStorage.getItem('timezone')
						);
						timeZone['IsTwelveTimeFormat'] = isTwelveHours;
						localStorage.setItem(
							'timezone',
							JSON.stringify(timeZone)
						);
					});
				}
			});
		});
	};
};

const getUserTimeZoneId = function () {
	const olsonTimeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const olsonWindowsTimes = {
		'Australia/Darwin': 'AUS Central Standard Time',
		'Australia/Sydney': 'AUS Eastern Standard Time',
		'Australia/Melbourne': 'AUS Eastern Standard Time',
		'Asia/Kabul': 'Afghanistan Standard Time',
		'America/Anchorage': 'Alaskan Standard Time',
		'America/Juneau': 'Alaskan Standard Time',
		'America/Nome': 'Alaskan Standard Time',
		'America/Sitka': 'Alaskan Standard Time',
		'America/Yakutat': 'Alaskan Standard Time',
		'Asia/Bahrain': 'Arab Standard Time',
		'Asia/Kuwait': 'Arab Standard Time',
		'Asia/Qatar': 'Arab Standard Time',
		'Asia/Riyadh': 'Arab Standard Time',
		'Asia/Aden': 'Arab Standard Time',
		'Asia/Dubai': 'Arabian Standard Time',
		'Asia/Muscat': 'Arabian Standard Time',
		'Etc/GMT-4': 'Arabian Standard Time',
		'Asia/Baghdad': 'Arabic Standard Time',
		'America/Buenos_Aires': 'Argentina Standard Time',
		'America/Argentina/La_Rioja': 'Argentina Standard Time',
		'America/Argentina/Rio_Gallegos': 'Argentina Standard Time',
		'America/Argentina/Salta': 'Argentina Standard Time',
		'America/Argentina/San_Juan': 'Argentina Standard Time',
		'America/Argentina/San_Luis': 'Argentina Standard Time',
		'America/Argentina/Tucuman': 'Argentina Standard Time',
		'America/Argentina/Ushuaia': 'Argentina Standard Time',
		'America/Catamarca': 'Argentina Standard Time',
		'America/Cordoba': 'Argentina Standard Time',
		'America/Jujuy': 'Argentina Standard Time',
		'America/Mendoza': 'Argentina Standard Time',
		'America/Halifax': 'Atlantic Standard Time',
		'Atlantic/Bermuda': 'Atlantic Standard Time',
		'America/Glace_Bay': 'Atlantic Standard Time',
		'America/Goose_Bay': 'Atlantic Standard Time',
		'America/Moncton': 'Atlantic Standard Time',
		'America/Thule': 'Atlantic Standard Time',
		'Asia/Baku': 'Azerbaijan Standard Time',
		'Atlantic/Azores': 'Azores Standard Time',
		'America/Scoresbysund': 'Azores Standard Time',
		'America/Bahia': 'Bahia Standard Time',
		'Asia/Dhaka': 'Bangladesh Standard Time',
		'Asia/Thimphu': 'Bangladesh Standard Time',
		'America/Regina': 'Canada Central Standard Time',
		'America/Swift_Current': 'Canada Central Standard Time',
		'Atlantic/Cape_Verde': 'Cape Verde Standard Time',
		'Etc/GMT+1': 'Cape Verde Standard Time',
		'Asia/Yerevan': 'Caucasus Standard Time',
		'Australia/Adelaide': 'Cen. Australia Standard Time',
		'Australia/Broken_Hill': 'Cen. Australia Standard Time',
		'America/Guatemala': 'Central America Standard Time',
		'America/Belize': 'Central America Standard Time',
		'America/Costa_Rica': 'Central America Standard Time',
		'Pacific/Galapagos': 'Central America Standard Time',
		'America/Tegucigalpa': 'Central America Standard Time',
		'America/Managua': 'Central America Standard Time',
		'America/El_Salvador': 'Central America Standard Time',
		'Etc/GMT+6': 'Central America Standard Time',
		'Asia/Almaty': 'Central Asia Standard Time',
		'Antarctica/Vostok': 'Central Asia Standard Time',
		'Indian/Chagos': 'Central Asia Standard Time',
		'Asia/Bishkek': 'Central Asia Standard Time',
		'Asia/Qyzylorda': 'Central Asia Standard Time',
		'Etc/GMT-6': 'Central Asia Standard Time',
		'America/Cuiaba': 'Central Brazilian Standard Time',
		'America/Campo_Grande': 'Central Brazilian Standard Time',
		'Europe/Tirane': 'Central Europe Standard Time',
		'Europe/Prague': 'Central Europe Standard Time',
		'Europe/Budapest': 'Central Europe Standard Time',
		'Europe/Podgorica': 'Central Europe Standard Time',
		'Europe/Belgrade': 'Central Europe Standard Time',
		'Europe/Ljubljana': 'Central Europe Standard Time',
		'Europe/Bratislava': 'Central Europe Standard Time',
		'Europe/Sarajevo': 'Central European Standard Time',
		'Europe/Zagreb': 'Central European Standard Time',
		'Europe/Skopje': 'Central European Standard Time',
		'Europe/Warsaw': 'Central European Standard Time',
		'Antarctica/Macquarie': 'Central Pacific Standard Time',
		'Pacific/Ponape': 'Central Pacific Standard Time',
		'Pacific/Kosrae': 'Central Pacific Standard Time',
		'Pacific/Noumea': 'Central Pacific Standard Time',
		'Pacific/Guadalcanal': 'Central Pacific Standard Time',
		'Pacific/Efate': 'Central Pacific Standard Time',
		'Etc/GMT-11': 'Central Pacific Standard Time',
		'America/Chicago': 'Central Standard Time',
		'America/Winnipeg': 'Central Standard Time',
		'America/Rainy': 'Central Standard Time',
		'America/Rankin_Inlet': 'Central Standard Time',
		'America/Resolute': 'Central Standard Time',
		'America/Matamoros': 'Central Standard Time',
		'America/Indiana/Knox': 'Central Standard Time',
		'America/Indiana/Tell_City': 'Central Standard Time',
		'America/Menominee': 'Central Standard Time',
		'America/North_Dakota/Beulah': 'Central Standard Time',
		'America/North_Dakota/Center': 'Central Standard Time',
		'America/North_Dakota/New_Salem': 'Central Standard Time',
		CST6CDT: 'Central Standard Time',
		'America/Mexico_City': 'Central Standard Time (Mexico)',
		'America/Bahia_Banderas': 'Central Standard Time (Mexico)',
		'America/Cancun': 'Central Standard Time (Mexico)',
		'America/Merida': 'Central Standard Time (Mexico)',
		'America/Monterrey': 'Central Standard Time (Mexico)',
		'Asia/Shanghai': 'China Standard Time',
		'Asia/Chongqing': 'China Standard Time',
		'Asia/Harbin': 'China Standard Time',
		'Asia/Kashgar': 'China Standard Time',
		'Asia/Urumqi': 'China Standard Time',
		'Asia/Hong_Kong': 'China Standard Time',
		'Asia/Macau': 'China Standard Time',
		'Etc/GMT+12': 'Dateline Standard Time',
		'Antarctica/Syowa': 'E. Africa Standard Time',
		'Africa/Djibouti': 'E. Africa Standard Time',
		'Africa/Asmera': 'E. Africa Standard Time',
		'Africa/Addis_Ababa': 'E. Africa Standard Time',
		'Africa/Nairobi': 'E. Africa Standard Time',
		'Indian/Comoro': 'E. Africa Standard Time',
		'Indian/Antananarivo': 'E. Africa Standard Time',
		'Africa/Khartoum': 'E. Africa Standard Time',
		'Africa/Mogadishu': 'E. Africa Standard Time',
		'Africa/Juba': 'E. Africa Standard Time',
		'Africa/Dar_es_Salaam': 'E. Africa Standard Time',
		'Africa/Kampala': 'E. Africa Standard Time',
		'Indian/Mayotte': 'E. Africa Standard Time',
		'Etc/GMT-3': 'E. Africa Standard Time',
		'Australia/Brisbane': 'E. Australia Standard Time',
		'Australia/Brisbane Australia/Lindeman': 'E. Australia Standard Time',
		'Asia/Nicosia': 'E. Europe Standard Time',
		'America/Sao_Paulo': 'E. South America Standard Time',
		'America/New_York': 'Eastern Standard Time',
		'America/Nassau': 'Eastern Standard Time',
		'America/Toronto': 'Eastern Standard Time',
		'America/Iqaluit': 'Eastern Standard Time',
		'America/Montreal': 'Eastern Standard Time',
		'America/Nipigon': 'Eastern Standard Time',
		'America/Pangnirtung': 'Eastern Standard Time',
		'America/Thunder_Bay': 'Eastern Standard Time',
		'America/Grand_Turk': 'Eastern Standard Time',
		'America/Detroit': 'Eastern Standard Time',
		'America/Indiana/Petersburg': 'Eastern Standard Time',
		'America/Indiana/Vincennes': 'Eastern Standard Time',
		'America/Indiana/Winamac': 'Eastern Standard Time',
		'America/Kentucky/Monticello': 'Eastern Standard Time',
		'America/Louisville': 'Eastern Standard Time',
		EST5EDT: 'Eastern Standard Time',
		'Africa/Cairo': 'Egypt Standard Time',
		'Asia/Gaza': 'Egypt Standard Time',
		'Asia/Hebron': 'Egypt Standard Time',
		'Asia/Yekaterinburg': 'Ekaterinburg Standard Time',
		'Europe/Mariehamn': 'FLE Standard Time',
		'Europe/Sofia': 'FLE Standard Time',
		'Europe/Tallinn': 'FLE Standard Time',
		'Europe/Helsinki': 'FLE Standard Time',
		'Europe/Vilnius': 'FLE Standard Time',
		'Europe/Riga': 'FLE Standard Time',
		'Europe/Kiev': 'FLE Standard Time',
		'Europe/Simferopol': 'FLE Standard Time',
		'Europe/Uzhgorod': 'FLE Standard Time',
		'Europe/Zaporozhye': 'FLE Standard Time',
		'Pacific/Fiji': 'Fiji Standard Time',
		'Atlantic/Canary': 'GMT Standard Time',
		'Atlantic/Faeroe': 'GMT Standard Time',
		'Europe/London': 'GMT Standard Time',
		'Europe/Guernsey': 'GMT Standard Time',
		'Europe/Dublin': 'GMT Standard Time',
		'Europe/Isle_of_Man': 'GMT Standard Time',
		'Europe/Jersey': 'GMT Standard Time',
		'Europe/Lisbon': 'GMT Standard Time',
		'Atlantic/Madeira': 'GMT Standard Time',
		'Europe/Bucharest': 'GTB Standard Time',
		'Europe/Athens': 'GTB Standard Time',
		'Europe/Chisinau': 'GTB Standard Time',
		'Asia/Tbilisi': 'Georgian Standard Time',
		'America/Godthab': 'Greenland Standard Time',
		'Africa/Ouagadougou': 'Greenwich Standard Time',
		'Africa/Abidjan': 'Greenwich Standard Time',
		'Africa/El_Aaiun': 'Greenwich Standard Time',
		'Africa/Accra': 'Greenwich Standard Time',
		'Africa/Banjul': 'Greenwich Standard Time',
		'Africa/Conakry': 'Greenwich Standard Time',
		'Africa/Bissau': 'Greenwich Standard Time',
		'Atlantic/Reykjavik': 'Greenwich Standard Time',
		'Africa/Monrovia': 'Greenwich Standard Time',
		'Africa/Bamako': 'Greenwich Standard Time',
		'Africa/Nouakchott': 'Greenwich Standard Time',
		'Atlantic/St_Helena': 'Greenwich Standard Time',
		'Africa/Freetown': 'Greenwich Standard Time',
		'Africa/Dakar': 'Greenwich Standard Time',
		'Africa/Sao_Tome': 'Greenwich Standard Time',
		'Africa/Lome': 'Greenwich Standard Time',
		'Pacific/Honolulu': 'Hawaiian Standard Time',
		'Pacific/Rarotonga': 'Hawaiian Standard Time',
		'Pacific/Tahiti': 'Hawaiian Standard Time',
		'Pacific/Fakaofo': 'Hawaiian Standard Time',
		'Pacific/Johnston': 'Hawaiian Standard Time',
		'Etc/GMT+10': 'Hawaiian Standard Time',
		'Asia/Calcutta': 'India Standard Time',
		'Asia/Tehran': 'Iran Standard Time',
		'Asia/Jerusalem': 'Israel Standard Time',
		'Asia/Amman': 'Jordan Standard Time',
		'Europe/Kaliningrad': 'Kaliningrad Standard Time',
		'Europe/Minsk': 'Kaliningrad Standard Time',
		'Asia/Pyongyang': 'Korea Standard Time',
		'Asia/Seoul': 'Korea Standard Time',
		'Asia/Magadan': 'Magadan Standard Time',
		'Asia/Anadyr': 'Magadan Standard Time',
		'Asia/Kamchatka': 'Magadan Standard Time',
		'Indian/Mauritius': 'Mauritius Standard Time',
		'Indian/Reunion': 'Mauritius Standard Time',
		'Indian/Mahe': 'Mauritius Standard Time',
		'Asia/Beirut': 'Middle East Standard Time',
		'America/Montevideo': 'Montevideo Standard Time',
		'Africa/Casablanca': 'Morocco Standard Time',
		'America/Denver': 'Mountain Standard Time',
		'America/Edmonton': 'Mountain Standard Time',
		'America/Cambridge_Bay': 'Mountain Standard Time',
		'America/Inuvik': 'Mountain Standard Time',
		'America/Yellowknife': 'Mountain Standard Time',
		'America/Ojinaga': 'Mountain Standard Time',
		'America/Boise': 'Mountain Standard Time',
		'America/Shiprock': 'Mountain Standard Time',
		MST7MDT: 'Mountain Standard Time',
		'America/Chihuahua': 'Mountain Standard Time (Mexico)',
		'America/Chihuahua America/Mazatlan': 'Mountain Standard Time (Mexico)',
		'Asia/Rangoon': 'Myanmar Standard Time',
		'Indian/Cocos': 'Myanmar Standard Time',
		'Asia/Novosibirsk': 'N. Central Asia Standard Time',
		'Asia/Novokuznetsk': 'N. Central Asia Standard Time',
		'Asia/Omsk': 'N. Central Asia Standard Time',
		'Africa/Windhoek': 'Namibia Standard Time',
		'Asia/Katmandu': 'Nepal Standard Time',
		'Antarctica/South_Pole': 'New Zealand Standard Time',
		'Antarctica/McMurdo': 'New Zealand Standard Time',
		'Pacific/Auckland': 'New Zealand Standard Time',
		'America/St_Johns': 'Newfoundland Standard Time',
		'Asia/Irkutsk': 'North Asia East Standard Time',
		'Asia/Krasnoyarsk': 'North Asia Standard Time',
		'Antarctica/Palmer': 'Pacific SA Standard Time',
		'America/Santiago': 'Pacific SA Standard Time',
		'America/Vancouver': 'Pacific Standard Time',
		'America/Dawson': 'Pacific Standard Time',
		'America/Whitehorse': 'Pacific Standard Time',
		'America/Tijuana': 'Pacific Standard Time',
		'America/Los_Angeles': 'Pacific Standard Time',
		PST8PDT: 'Pacific Standard Time',
		'America/Santa_Isabel': 'Pacific Standard Time (Mexico)',
		'Asia/Karachi': 'Pakistan Standard Time',
		'America/Asuncion': 'Paraguay Standard Time',
		'Europe/Brussels': 'Romance Standard Time',
		'Europe/Copenhagen': 'Romance Standard Time',
		'Europe/Madrid': 'Romance Standard Time',
		'Africa/Ceuta': 'Romance Standard Time',
		'Europe/Paris': 'Romance Standard Time',
		'Europe/Moscow': 'Russian Standard Time',
		'Europe/Moscow Europe/Samara Europe/Volgograd': 'Russian Standard Time',
		'Antarctica/Rothera': 'SA Eastern Standard Time',
		'America/Fortaleza': 'SA Eastern Standard Time',
		'America/Araguaina': 'SA Eastern Standard Time',
		'America/Belem': 'SA Eastern Standard Time',
		'America/Maceio': 'SA Eastern Standard Time',
		'America/Recife': 'SA Eastern Standard Time',
		'America/Santarem': 'SA Eastern Standard Time',
		'America/Cayenne': 'SA Eastern Standard Time',
		'America/Paramaribo': 'SA Eastern Standard Time',
		'Etc/GMT+3': 'SA Eastern Standard Time',
		'America/Bogota': 'SA Pacific Standard Time',
		'America/Coral_Harbour': 'SA Pacific Standard Time',
		'America/Guayaquil': 'SA Pacific Standard Time',
		'America/Port-au-Prince': 'SA Pacific Standard Time',
		'America/Jamaica': 'SA Pacific Standard Time',
		'America/Cayman': 'SA Pacific Standard Time',
		'America/Panama': 'SA Pacific Standard Time',
		'America/Lima': 'SA Pacific Standard Time',
		'Etc/GMT+5': 'SA Pacific Standard Time',
		'America/Antigua': 'SA Western Standard Time',
		'America/Anguilla': 'SA Western Standard Time',
		'America/Aruba': 'SA Western Standard Time',
		'America/Barbados': 'SA Western Standard Time',
		'America/St_Barthelemy': 'SA Western Standard Time',
		'America/La_Paz': 'SA Western Standard Time',
		'America/Manaus': 'SA Western Standard Time',
		'America/Boa_Vista': 'SA Western Standard Time',
		'America/Eirunepe': 'SA Western Standard Time',
		'America/Porto_Velho': 'SA Western Standard Time',
		'America/Rio_Branco': 'SA Western Standard Time',
		'America/Blanc-Sablon': 'SA Western Standard Time',
		'America/Curacao': 'SA Western Standard Time',
		'America/Dominica': 'SA Western Standard Time',
		'America/Santo_Domingo': 'SA Western Standard Time',
		'America/Grenada': 'SA Western Standard Time',
		'America/Guadeloupe': 'SA Western Standard Time',
		'America/Guyana': 'SA Western Standard Time',
		'America/St_Kitts': 'SA Western Standard Time',
		'America/St_Lucia': 'SA Western Standard Time',
		'America/Marigot': 'SA Western Standard Time',
		'America/Martinique': 'SA Western Standard Time',
		'America/Montserrat': 'SA Western Standard Time',
		'America/Puerto_Rico': 'SA Western Standard Time',
		'America/Port_of_Spain': 'SA Western Standard Time',
		'America/St_Vincent': 'SA Western Standard Time',
		'America/Tortola': 'SA Western Standard Time',
		'America/St_Thomas': 'SA Western Standard Time',
		'Etc/GMT+4': 'SA Western Standard Time',
		'Antarctica/Davis': 'SE Asia Standard Time',
		'Indian/Christmas': 'SE Asia Standard Time',
		'Asia/Jakarta Asia/Pontianak': 'SE Asia Standard Time',
		'Asia/Phnom_Penh': 'SE Asia Standard Time',
		'Asia/Vientiane': 'SE Asia Standard Time',
		'Asia/Hovd': 'SE Asia Standard Time',
		'Asia/Bangkok': 'SE Asia Standard Time',
		'Asia/Saigon': 'SE Asia Standard Time',
		'Etc/GMT-7': 'SE Asia Standard Time',
		'Pacific/Apia': 'Samoa Standard Time',
		'Asia/Brunei': 'Singapore Standard Time',
		'Asia/Makassar': 'Singapore Standard Time',
		'Asia/Kuala_Lumpur': 'Singapore Standard Time',
		'Asia/Kuching': 'Singapore Standard Time',
		'Asia/Manila': 'Singapore Standard Time',
		'Asia/Singapore': 'Singapore Standard Time',
		'Etc/GMT-8': 'Singapore Standard Time',
		'Africa/Bujumbura': 'South Africa Standard Time',
		'Africa/Gaborone': 'South Africa Standard Time',
		'Africa/Lubumbashi': 'South Africa Standard Time',
		'Africa/Maseru': 'South Africa Standard Time',
		'Africa/Tripoli': 'South Africa Standard Time',
		'Africa/Blantyre': 'South Africa Standard Time',
		'Africa/Maputo': 'South Africa Standard Time',
		'Africa/Kigali': 'South Africa Standard Time',
		'Africa/Mbabane': 'South Africa Standard Time',
		'Africa/Johannesburg': 'South Africa Standard Time',
		'Africa/Lusaka': 'South Africa Standard Time',
		'Africa/Harare': 'South Africa Standard Time',
		'Etc/GMT-2': 'South Africa Standard Time',
		'Asia/Colombo': 'Sri Lanka Standard Time',
		'Asia/Damascus': 'Syria Standard Time',
		'Asia/Taipei': 'Taipei Standard Time',
		'Australia/Hobart': 'Tasmania Standard Time',
		'Australia/Currie': 'Tasmania Standard Time',
		'Asia/Tokyo': 'Tokyo Standard Time',
		'Asia/Jayapura': 'Tokyo Standard Time',
		'Pacific/Palau': 'Tokyo Standard Time',
		'Asia/Dili': 'Tokyo Standard Time',
		'Etc/GMT-9': 'Tokyo Standard Time',
		'Pacific/Enderbury': 'Tonga Standard Time',
		'Pacific/Tongatapu': 'Tonga Standard Time',
		'Etc/GMT-13': 'Tonga Standard Time',
		'Europe/Istanbul': 'Turkey Standard Time',
		'America/Indianapolis': 'US Eastern Standard Time',
		'America/Indiana/Marengo': 'US Eastern Standard Time',
		'America/Indiana/Vevay': 'US Eastern Standard Time',
		'America/Dawson_Creek': 'US Mountain Standard Time',
		'America/Hermosillo': 'US Mountain Standard Time',
		'America/Phoenix': 'US Mountain Standard Time',
		'Etc/GMT+7': 'US Mountain Standard Time',
		'Etc/GMT': 'UTC',
		'America/Danmarkshavn': 'UTC',
		'Pacific/Tarawa': 'UTC+12',
		'Pacific/Majuro Pacific/Kwajalein': 'UTC+12',
		'Pacific/Nauru': 'UTC+12',
		'Pacific/Funafuti': 'UTC+12',
		'Pacific/Wake': 'UTC+12',
		'Pacific/Wallis': 'UTC+12',
		'Etc/GMT-12': 'UTC+12',
		'America/Noronha': 'UTC-02',
		'Atlantic/South_Georgia': 'UTC-02',
		'Etc/GMT+2': 'UTC-02',
		'Pacific/Pago_Pago': 'UTC-11',
		'Pacific/Niue': 'UTC-11',
		'Pacific/Midway': 'UTC-11',
		'Etc/GMT+11': 'UTC-11',
		'Asia/Ulaanbaatar': 'Ulaanbaatar Standard Time',
		'Asia/Choibalsan': 'Ulaanbaatar Standard Time',
		'America/Caracas': 'Venezuela Standard Time',
		'Asia/Vladivostok': 'Vladivostok Standard Time',
		'Asia/Sakhalin': 'Vladivostok Standard Time',
		'Australia/Perth': 'W. Australia Standard Time',
		'Antarctica/Casey': 'W. Australia Standard Time',
		'Africa/Luanda': 'W. Central Africa Standard Time',
		'Africa/Porto-Novo': 'W. Central Africa Standard Time',
		'Africa/Kinshasa': 'W. Central Africa Standard Time',
		'Africa/Bangui': 'W. Central Africa Standard Time',
		'Africa/Brazzaville': 'W. Central Africa Standard Time',
		'Africa/Douala': 'W. Central Africa Standard Time',
		'Africa/Algiers': 'W. Central Africa Standard Time',
		'Africa/Libreville': 'W. Central Africa Standard Time',
		'Africa/Malabo': 'W. Central Africa Standard Time',
		'Africa/Niamey': 'W. Central Africa Standard Time',
		'Africa/Lagos': 'W. Central Africa Standard Time',
		'Africa/Ndjamena': 'W. Central Africa Standard Time',
		'Africa/Tunis': 'W. Central Africa Standard Time',
		'Etc/GMT-1': 'W. Central Africa Standard Time',
		'Europe/Berlin': 'W. Europe Standard Time',
		'Europe/Andorra': 'W. Europe Standard Time',
		'Europe/Vienna': 'W. Europe Standard Time',
		'Europe/Zurich': 'W. Europe Standard Time',
		'Europe/Gibraltar': 'W. Europe Standard Time',
		'Europe/Rome': 'W. Europe Standard Time',
		'Europe/Vaduz': 'W. Europe Standard Time',
		'Europe/Luxembourg': 'W. Europe Standard Time',
		'Europe/Monaco': 'W. Europe Standard Time',
		'Europe/Malta': 'W. Europe Standard Time',
		'Europe/Amsterdam': 'W. Europe Standard Time',
		'Europe/Oslo': 'W. Europe Standard Time',
		'Europe/Stockholm': 'W. Europe Standard Time',
		'Arctic/Longyearbyen': 'W. Europe Standard Time',
		'Europe/San_Marino': 'W. Europe Standard Time',
		'Europe/Vatican': 'W. Europe Standard Time',
		'Asia/Tashkent': 'West Asia Standard Time',
		'Antarctica/Mawson': 'West Asia Standard Time',
		'Asia/Oral': 'West Asia Standard Time',
		'Asia/Aqtau': 'West Asia Standard Time',
		'Asia/Aqtobe': 'West Asia Standard Time',
		'Indian/Maldives': 'West Asia Standard Time',
		'Indian/Kerguelen': 'West Asia Standard Time',
		'Asia/Dushanbe': 'West Asia Standard Time',
		'Asia/Ashgabat': 'West Asia Standard Time',
		'Asia/Tashkent Asia/Samarkand': 'West Asia Standard Time',
		'Etc/GMT-5': 'West Asia Standard Time',
		'Antarctica/DumontDUrville': 'West Pacific Standard Time',
		'Pacific/Truk': 'West Pacific Standard Time',
		'Pacific/Guam': 'West Pacific Standard Time',
		'Pacific/Saipan': 'West Pacific Standard Time',
		'Pacific/Port_Moresby': 'West Pacific Standard Time',
		'Etc/GMT-10': 'West Pacific Standard Time',
		'Asia/Yakutsk': 'Yakutsk Standard Time',
		UTC: 'UTC',
		'Etc/UTC': 'UTC',
		GMT: 'Greenland Standard Time'
	};

	const result = olsonWindowsTimes[olsonTimeZoneId];
	return result;
};
