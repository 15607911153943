import { createGuid } from 'fxs-utils';
import { Subtract } from 'utility-types';

import {
	CrudMethod,
	EditorialCategoryPushType,
	EditorialCategoryType,
	MessageContentType,
	SidePushType,
	SideType
} from '../models/enums';
import {
	EditorialMessage,
	EditorialMessageResponse,
	Message,
	MessageResponse,
	TagsContentPush,
	TradeMessage,
	TradeMessageResponse
} from '../models/signalMessages';
import {
	EditorialMessagePush,
	TradeMessagePush
} from '../models/signalPushMessages';
import {
	RelatedCalendarContentPush,
	RelatedPostContentPush,
	RelatedRecordingContentPush,
	RelatedSignalContentPush,
	RelatedWebinarContentPush
} from '../models/signalRelatedContentPush';
import {
	mapRelatedCalendarContent,
	mapRelatedPostContent,
	mapRelatedRecordingContent,
	mapRelatedSignalContent,
	mapRelatedWebinarContent
} from './signalsRelatedContent';

const buildAuthorFromApi = (author) => {
	return {
		id: author.id,
		name: author.name,
		company: author.company,
		imageUrl: author.imageUrl
	};
};

const buildAuthorFromPush = (author) => {
	return {
		id: author.Id,
		name: author.Name,
		company: author.Company,
		imageUrl: author.ImageUrl
	};
};

const mapEditorialFromApi = (
	source: EditorialMessageResponse
): Subtract<EditorialMessage, Message> => ({
	title: source.title,
	description: source.description,
	category: source.category,
	market: source.market,
	tags: source.tags,
	imageUrl: source.imageUrl,
	link: source.link,
	mediaLink: source.mediaLink,
	author: source.author ? buildAuthorFromApi(source.author) : undefined,
	relatedPostContent: source.relatedPostContent,
	relatedWebinarContent: source.relatedWebinarContent,
	relatedRecordingContent: source.relatedRecordingContent,
	relatedCalendarContent: source.relatedCalendarContent,
	relatedSignalContent: source.relatedSignalContent
});

const mapTradeFromApi = (
	source: TradeMessageResponse
): Subtract<TradeMessage, Message> => ({
	positionMaintenanceId: source.positionMaintenanceId,
	side: source.side,
	symbol: source.symbol,
	entryPrice: source.entryPrice,
	profitPercent: source.profitPercent,
	stopLoss: source.stopLoss,
	takeProfit: source.takeProfit,
	isDisabled: source.isDisabled,
	actionType: source.actionType,
	limitType: source.limitType,
	pips: source.pips
});

export const mapSignalsMessages = (
	source: MessageResponse,
	unread?: boolean
): Message => ({
	id: source.id,
	dateUtc: source.dateUtc,
	contentType: source.contentType,
	unread: unread ?? false,
	liked: source.liked,
	bookmarked: source.bookmarked,

	...(source.contentType === MessageContentType.Editorial
		? mapEditorialFromApi(source.content as EditorialMessageResponse)
		: mapTradeFromApi(source.content as TradeMessageResponse))
});

export const mapTradeMessageFromPush = (
	source: TradeMessagePush
): TradeMessage => ({
	id: createGuid(),
	contentType: MessageContentType.Trade,
	dateUtc: new Date().toISOString(),
	isDisabled: false,
	unread: true,
	positionMaintenanceId: source.PositionMaintenanceId,
	side: source.SideType === SidePushType.Buy ? SideType.Buy : SideType.Sell,
	symbol: source.Asset,
	entryPrice: source.EntryPrice,
	profitPercent: source.ProfitPercent,
	stopLoss: source.StopLoss,
	takeProfit: source.TakeProfit,
	actionType: source.ActionType,
	limitType: source.LimitType,
	liked: false,
	bookmarked: false,
	pips: source.Pips
});

const mapEditorialCategoryTypeFromPush = (
	source?: EditorialCategoryPushType
): EditorialCategoryType | undefined => {
	switch (source) {
		case EditorialCategoryPushType.Analysis:
			return EditorialCategoryType.Analysis;

		case EditorialCategoryPushType.Education:
			return EditorialCategoryType.Education;

		case EditorialCategoryPushType.Warning:
			return EditorialCategoryType.Warning;

		case EditorialCategoryPushType.Review:
			return EditorialCategoryType.Review;

		default:
			return undefined;
	}
};

export const mapEditorialMessageFromPush = (
	source: EditorialMessagePush,
	crudMethod: CrudMethod
): EditorialMessage => ({
	id: source.Id,
	contentType: MessageContentType.Editorial,
	dateUtc: source.DateUtc,
	unread: crudMethod === CrudMethod.Create,
	title: source.Title,
	description: source.Description,
	category: mapEditorialCategoryTypeFromPush(source.Category),
	market: source.Market,
	tags: mapTagsContent(source.Tags),
	imageUrl: source.ImageUrl,
	link: source.Link,
	mediaLink: source.MediaLink,
	author: source.Author ? buildAuthorFromPush(source.Author) : undefined,
	liked: source.liked,
	bookmarked: source.bookmarked,
	relatedPostContent: mapRelatedPostContent(
		source.RelatedPostContent as unknown as RelatedPostContentPush
	),
	relatedWebinarContent: mapRelatedWebinarContent(
		source.RelatedWebinarContent as unknown as RelatedWebinarContentPush
	),
	relatedRecordingContent: mapRelatedRecordingContent(
		source.RelatedRecordingContent as unknown as RelatedRecordingContentPush
	),
	relatedCalendarContent: mapRelatedCalendarContent(
		source.RelatedCalendarEventContent as unknown as RelatedCalendarContentPush
	),
	relatedSignalContent: mapRelatedSignalContent(
		source.RelatedSignalContent as unknown as RelatedSignalContentPush
	)
});

const mapTagsContent = (source: TagsContentPush[]) => {
	if (!source) return null;

	const result = [];
	source.map((tag) => {
		result.push({
			id: tag.Id,
			name: tag.Name
		});
	});
	return result;
};
