import React from 'react';

import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const FXSToastWrapper = styled.div`
	position: relative;
	height: 2rem;
	width: 90%;
	margin: auto;
	text-align: center;
	&:before {
		content: '';
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		z-index: 0;
		height: 1px;
		background: ${COLORS.grey400};
	}
`;
export const FXToastSpan = styled.span`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	padding: 0 1rem;
	background: ${COLORS.grey300};
	font-size: 12px;
`;

type ToastProps = {
	text: string;
};

const Toast = ({ text }: ToastProps) => {
	return (
		<FXSToastWrapper>
			<FXToastSpan>{text}</FXToastSpan>
		</FXSToastWrapper>
	);
};

export default Toast;
