export default function IconLogout() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M12.5625 7.1875V9.01562H9.1875C8.23828 9.01562 7.5 9.78906 7.5 10.7031V13.832C7.5 14.7461 8.23828 15.5195 9.1875 15.5195H12.5625V17.3125C12.5625 18.8242 14.3555 19.5625 15.4102 18.5078L20.4727 13.4453C21.1406 12.8125 21.1406 11.7227 20.4727 11.0547L15.4102 5.99219C14.3555 4.9375 12.5625 5.71094 12.5625 7.1875ZM19.3125 12.25L14.25 17.3125V13.832H9.1875V10.7031H14.25V7.1875L19.3125 12.25ZM6.375 5.5C4.51172 5.5 3 7.01172 3 8.875V15.625C3 17.4883 4.51172 19 6.375 19H9.32812C9.53906 19 9.75 18.8242 9.75 18.5781V17.7344C9.75 17.5234 9.53906 17.3125 9.32812 17.3125H6.375C5.42578 17.3125 4.6875 16.5742 4.6875 15.625V8.875C4.6875 7.96094 5.42578 7.1875 6.375 7.1875H9.32812C9.53906 7.1875 9.75 7.01172 9.75 6.76562V5.92188C9.75 5.71094 9.53906 5.5 9.32812 5.5H6.375Z' />
		</svg>
	);
}
