import { useEffect, useRef } from 'react';

const useClickOutside = (onClickOutside: () => void) => {
	const elementRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handler = (event: MouseEvent) => {
			if (
				(event.target as HTMLButtonElement)?.tagName.toLowerCase() ===
				'button'
			) {
				return;
			}
			if (
				(event.target as SVGElement).localName === 'svg' ||
				(event.target as SVGElement).localName === 'path'
			) {
				return;
			}
			if (
				(event.target as HTMLAnchorElement).tagName.toLowerCase() ===
				'a'
			) {
				return;
			}
			if (!elementRef.current?.contains(event.target as Node)) {
				onClickOutside();
			}
		};
		document.addEventListener('mousedown', handler);
		return () => {
			document.removeEventListener('mousedown', handler);
		};
	});

	return elementRef;
};

export default useClickOutside;
