import React from 'react';

import { NewerMessage, OlderMessage } from 'fxs-signals-insights';

import { newerDefaults, olderDefaults } from '../constants/signalMessages';
import { InsightsType } from '../models/enums';
import { Message } from '../models/signalMessages';

export type SignalsInsightsState = {
	newer: NewerMessage;
	older: OlderMessage;
	messages: Message[];
	type: InsightsType;
};

type ContextProps = {
	state: SignalsInsightsState;
	saveState(newState: SignalsInsightsState): void;
};

export const initialState: SignalsInsightsState = {
	messages: undefined,
	newer: newerDefaults,
	older: olderDefaults,
	type: InsightsType.latest
};

export const SignalsInsightsContext = React.createContext<ContextProps>({
	state: initialState,
	saveState: () => null
});
