import styled from 'styled-components';
import { COLORS } from 'styles/colors';

/************************
  MOBILE
************************/
export const FXSHeaderMobile = styled.header`
	position: fixed;
	z-index: 3;
	grid-area: header;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 55px;
	width: 100%;
	background: ${COLORS.corp};
	line-height: 55px;
`;
export const FXSHeaderMobileWrapper = styled.div`
	display: flex;
	align-items: center;
`;
export const FXSHeaderHamburguerMenu = styled.button`
	height: 30px;
	width: auto;
	margin-left: 8px;
	margin-right: 8px;
	background: transparent;
	border: none;
`;
export const FXSHeaderMobileLogo = styled.img`
	height: 30px;
	width: auto;
`;
/************************
  DESKTOP
************************/
export const FXSHeaderDesktop = styled.header`
	grid-area: header;
	display: flex;
	justify-content: flex-end;
	height: 64px;
	background: ${COLORS.white};
	box-shadow: 0px 0px 14px 0px #00000026;
	padding: 12px 24px;
	gap: 12px;
	z-index: 13;
	position: relative;
	transform: translate(0);
`;
export const SmallVertDivider = styled.span`
	display: inline-block;
	width: 1px;
	height: 21px;
	line-height: 40px;
	background: ${COLORS.grey300};
	margin: 10.5px 0;
`;

export const DivJoinTab = styled.div`
	position: absolute;
	top: 64px;
	width: max-content;
	right: 0;
	background-color: white;
	border-radius: 0 0 4px 4px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	z-index: 1;
	transition: transform 1000ms ease;

	&:hover {
		background-color: #d4e4fd;
		p {
			color: #2a77f6;
		}
	}

	&:active {
		background-color: #2a77f6;
		p {
			color: white;
		}
	}

	&.popup-open {
		transform: translateY(-110%);
		transition: transform 300ms ease;
	}

	p {
		margin: 0;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
		color: #8c8d91;
		padding: 4px 20px;

		span {
			width: 10px;
			height: 10px;
			background-color: #ce0518;
			display: inline-block;
			border-radius: 50%;
			animation: blink 2.5s infinite;
			margin-right: 5px;
		}
	}
`;

export const DivDiscordPopupDesktop = styled.div`
	position: absolute;
	top: 64px;
	right: 0;
	max-width: 468px;
	display: flex;
	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	transform: translateY(-110%);
	max-height: 294px;
	transition:
		all 600ms ease,
		z-index 600ms ease 1000ms,
		max-height 600ms ease 600ms;
	z-index: 0;

	&.expanded {
		max-height: 800px;
		transition: all 600ms ease;
	}

	&.visible {
		transition:
			all 600ms ease,
			z-index 600ms ease;
		transform: translateY(0);
		z-index: 3;
	}

	.discord-analysts {
		max-width: 200px;
		padding: 14px 0 0 14px;
		background-color: #6472f8;
		overflow: hidden;
		img {
			display: block;
		}
	}

	.description {
		background-color: white;
		padding: 30px 24px 24px 24px;
		display: flex;
		flex-direction: column;

		h3 {
			font-size: 24px;
			line-height: 28px;

			.discord-brand {
				color:${COLORS.discord_primary};

				svg {
					height: 1.15em;
					width: auto;
					vertical-align: middle;

					* {
						fill:${COLORS.discord_primary};
					}
				}
			}
		}

		p {
			font-size: 14px;
			line-height: 20px;
			color: #49494f;
		}

		.more-info {
			color: #2a77f6;

			font-weight: bold;

			cursor: pointer;
			transition: all 125ms ease;

			&.hidden {
				max-height: 0;
				overflow: hidden;
				opacity: 0;
				margin: 0;
			}
		}

		.more-info-content {
			max-height: 0;
			opacity: 0;
			transition:
				all 900ms ease,
				opacity 600ms ease;
			overflow: hidden;

			&.visible {
				max-height: 350px;
				opacity: 1;
				overflow: visible;
			}
		}
	}

	button {
		display: block;
		margin: 0 auto;
		margin-top: auto;
		margin-bottom: 4px;
		padding: 5px 0;
		width: 100%;
		background-color: #e4871b;
		color: white;
		font-weight: bold;
		font-size: 12px;
		line-height: 18px;
	}

	&& .not-now {
		color: #8c8d91;
		text-align: center;
		padding: 5px 0;
		width: 100%;
		cursor: pointer;
		font-weight: lighter;
		font-size: 12px;
		line-height: 18px;
		margin: 0;

		&:hover {
			background-color: #dddedf;
		}
	}

	.close-button {
		color: #8c8d91;
		position: absolute;
		display: flex;
		top: 0;
		right: 0;
		padding: 12px;
		cursor: pointer;

		svg {
			width: 20px;
			height: 20px;
		}
	}
`;
