import { getUser } from '../utils/getUser';

const fetcher = async (eventId: string, eventTitle: string): Promise<void> => {
	return getUser().then(async (user) => {
		const userEventResponse = await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.UserEventFollows}`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ postId: eventId, email: user.email })
			}
		);
		const postApiResponse = await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.Lead}`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					CreationDate: new Date().toISOString(),
					Email: user.email,
					Name: user.name,
					Phone: user.phone,
					Broker: null,
					BrokerEmail: null,
					Type: 4,
					Country: user.country,
					Description: `Event Logged -> ${eventTitle}`,
					Culture: window.config.Variables.Culture
				})
			}
		);

		Promise.all([userEventResponse, postApiResponse]).then((responses) =>
			responses.map((response) => response.json())
		);
	});
};

export async function setEventFollow(
	eventId: string,
	eventTitle: string
): Promise<void> {
	return fetcher(eventId, eventTitle);
}
