enum Routes {
	HOME = '/',
	POPULAR = '/popular',
	BOOKMARKS = '/bookmarks',
	LIVE_COVERAGE = '/live',
	STRATEGIES = '/strategies/',
	SIGNALS = '/signals',
	WEBINARS = '/webinars',
	RECORDINGS = '/recordings',
	MASTERCLASS = '/masterclass',
	INSIGHTS = '/insights/',
	FAQ = '/faq/',
	CALENDAR = '/economic-calendar/'
}

export default Routes;
