import React from 'react';

import { TradePlan } from 'models/tradePlan';

import { Trade } from '../models/trades';

type ContextProps = {
	trades: Trade[];
	tradePlans: TradePlan[];
	saveTrades(newTrades: Trade[]): void;
	saveTradePlans(newTradePlans: TradePlan[]): void;
};

export const TradesContext = React.createContext<ContextProps>(null);
