import React, { useState } from 'react';

import { getTradePlan } from 'data/getTradePlan';
import { TradePlan } from 'models/tradePlan';

import { TradesContext } from '../contexts/tradesContext';
import { getTrades } from '../data/getTrades';
import { Trade } from '../models/trades';

export const TradesContextProvider = ({ children }) => {
	const [trades, setTrades] = useState(null as Trade[]);
	const [tradePlans, setTradePlans] = useState(null as TradePlan[]);
	const [alreadyCalled, setAlreadyCalled] = useState(false);

	if (!alreadyCalled) {
		setAlreadyCalled(true);
		getTrades().then((tradesData) => {
			setTrades(tradesData);
		});
		getTradePlan().then((tradePlansData) => {
			setTradePlans(tradePlansData);
		});
	}

	function saveTrades(newTrades: Trade[]): void {
		setTrades(newTrades);
	}

	function saveTradePlans(newTradePlans: TradePlan[]): void {
		setTradePlans(newTradePlans);
	}

	return (
		<TradesContext.Provider
			value={{ trades, tradePlans, saveTrades, saveTradePlans }}
		>
			{children}
		</TradesContext.Provider>
	);
};
