import { Tags } from 'fxs-signals-insights/dist/models/models';

import { getUser } from '../utils/getUser';

const fetcher = async () => {
	return getUser().then((user) => {
		return fetch(
			`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.MasterData}`,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			}
		).then((res) => res.json());
	});
};

export async function getTags(): Promise<Tags[]> {
	return fetcher();
}
