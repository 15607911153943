import { UserSettingsRequest, UserSettingsResponse } from 'models/userSettings';
import { getUser } from 'utils/getUser';

const getParams = (
	email: string,
	latestReadMessageDate: string,
	token: string
	// eslint-disable-next-line no-undef
): RequestInit => ({
	method: 'POST',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `bearer ${token}`
	},
	body: JSON.stringify({
		email,
		latestReadMessageDate
	} as UserSettingsRequest)
});

const createSettingsFetcher = async (
	email: string,
	latestReadMessageDate: string
): Promise<void> => {
	return getUser().then((user) => {
		if (user?.token) {
			fetch(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalUserSettings}`,
				getParams(email, latestReadMessageDate, user?.token)
			);
		}
	});
};

const fetcher = async (email: string): Promise<UserSettingsResponse> => {
	return getUser().then(async (user) => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalUserSettingsEmail.replace('{email}', email)}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `bearer ${user?.token}`
					}
				}
			);
			const result = await response.json();
			return await result[0];
		} catch (error) {
			console.log(error);
			const response = await createSettingsFetcher(
				email,
				new Date().toISOString()
			);
			return response;
		}
	});
};

export async function getSignalsSettings(
	email: string
): Promise<UserSettingsResponse> {
	return fetcher(email);
}
