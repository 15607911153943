import React from 'react';

import { TAGS } from 'constants/filter';
import { DefaultTags } from 'constants/tags';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'utils/useWindowWidth';

import { RowTypes } from './RowType';
import {
	FXSFilterModalRow,
	FXSFilterTag,
	FXSFilterTagWrapper,
	FXSFilterTitle
} from './styles';

const TagsRow = ({ activeTrades, state, handleClick }: RowTypes) => {
	const isLessThan833 = isLessThan(833);
	const { t } = useTranslationsContext();

	return (
		<FXSFilterModalRow activeTrade={activeTrades} isLast>
			<FXSFilterTitle isLessThan833={isLessThan833}>
				{t('topics')}
			</FXSFilterTitle>
			<FXSFilterTagWrapper>
				{DefaultTags.map((t) => (
					<FXSFilterTag
						key={t.id}
						checked={state?.tags?.includes(t.id)}
						disabled={activeTrades}
						onClick={(e) =>
							handleClick(e, t.id, TAGS, t.name, false)
						}
					>{`#${t.name}`}</FXSFilterTag>
				))}
			</FXSFilterTagWrapper>
		</FXSFilterModalRow>
	);
};

export default TagsRow;
