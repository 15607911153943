/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react';

import {
	CATEGORIES,
	FILTERS,
	MARKETS,
	NUM_FILTERS,
	TAGS
} from '../constants/filter';
import {
	FilterContext,
	FilterState,
	initialFilterState
} from '../contexts/filtersContext';

export const FilterContextProvider = ({ children }) => {
	const [filters, setFilters] = useState(initialFilterState);
	const localFilters = JSON.parse(window.localStorage.getItem(FILTERS));

	useEffect(() => {
		checkFilters();
	}, []);

	function saveFilters(newState: FilterState): void {
		window.localStorage.setItem(FILTERS, JSON.stringify(newState));
		setFilters(newState);
	}

	function clearFilters(): void {
		setFilters(initialFilterState);
		window.localStorage.setItem(
			FILTERS,
			JSON.stringify(initialFilterState)
		);
	}

	function checkFilters(): void {
		if (
			localFilters !== null &&
			Object.keys(localFilters).length < NUM_FILTERS
		) {
			if (!localFilters.hasOwnProperty(MARKETS))
				initiliazeFilter(MARKETS);
			if (!localFilters.hasOwnProperty(CATEGORIES))
				initiliazeFilter(CATEGORIES);
			if (!localFilters.hasOwnProperty(TAGS)) initiliazeFilter(TAGS);
		}
		if (localFilters !== null && Object.keys(localFilters) !== undefined) {
			setFilters(localFilters);
		} else {
			window.localStorage.setItem(
				FILTERS,
				JSON.stringify(initialFilterState)
			);
			setFilters(initialFilterState);
		}
	}

	function initiliazeFilter(type: string): void {
		localFilters[type] = [];
		setFilters(localFilters);
		window.localStorage.setItem(FILTERS, JSON.stringify(localFilters));
	}

	return (
		<FilterContext.Provider value={{ filters, saveFilters, clearFilters }}>
			{children}
		</FilterContext.Provider>
	);
};
