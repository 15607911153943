import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import { ConfigContext, initialConfiguration } from '../contexts/configContext';
import { getConfig } from '../data/getConfig';
import { Config } from '../models/config';
import useInterval from '../utils/useInterval';

export const ConfigContextProvider = ({ children }) => {
	const [config, setConfig] = useState(initialConfiguration);
	const [isReady, setIsReady] = useState(false);

	useInterval(async () => {
		return await getConfig().then((configResponse) => {
			if (JSON.stringify(config) !== JSON.stringify(configResponse)) {
				saveConfig({ ...configResponse });
			}
		});
	}, 180000);

	async function saveConfig(newConfig: Config): Promise<void> {
		setConfig({ ...newConfig });
		window.config = newConfig;
	}

	useEffect(() => {
		getConfig().then((config) => {
			saveConfig({ ...config });
			TagManager.initialize({ gtmId: config.Variables.GtmId });
			setIsReady(true);
		});
	}, []);

	return (
		isReady && (
			<ConfigContext.Provider value={{ config, saveConfig }}>
				{children}
			</ConfigContext.Provider>
		)
	);
};
