import React, { useContext, useEffect, useState } from 'react';

import { UserContext } from 'contexts/userContext';
import { getPremiumNewsletters, getUserNewsletters } from 'data/newsletters';
import { PremiumNewsletter, UserNewsletter } from 'models/newsletter';
import { User } from 'models/user';

export type NewsletterContextProps = {
	subscribedToAllNewsletters: (newsletters: PremiumNewsletter[]) => boolean;
	newsletters: PremiumNewsletter[];
	handleSubscribeAll: (newsletters: PremiumNewsletter[]) => void;
	isLoading: boolean;
	setNewsletters: React.Dispatch<React.SetStateAction<PremiumNewsletter[]>>;
	setChanged: React.Dispatch<React.SetStateAction<boolean>>;
	saveNewsletters: (
		setIsNewslettersModalOpened: (state: boolean) => void
	) => void;
	resetNewsletters: () => void;
	changed: boolean;
	saved: boolean;
	error: string;
};

export const NewsletterContext = React.createContext({});

export const NewsletterContextProvider = ({ children }) => {
	const [newsletters, setNewsletters] = useState([] as PremiumNewsletter[]);
	const [changed, setChanged] = useState(false);
	const [saved, setSaved] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState('');
	const { user } = useContext(UserContext);

	const getNewsletters = (user: User) => {
		getPremiumNewsletters()
			.then((premiumNewslettersData: PremiumNewsletter[]) => {
				getUserNewsletters(user).then(
					(userNewslettersData: UserNewsletter[]) => {
						setIsLoading(false);
						const mappedNewslettersData =
							premiumNewslettersData.map((premiumNewsletter) => {
								return {
									...premiumNewsletter,
									Subscribed: userNewslettersData.some(
										(userNewsletter: UserNewsletter) => {
											return (
												String(
													userNewsletter.newsletterId
												) === premiumNewsletter.Id
											);
										}
									),
									CountryCode:
										userNewslettersData[0]?.countryCode ||
										user.country
								};
							});
						setNewsletters(mappedNewslettersData);
					}
				);
			})
			.catch((error) => {
				`${error} error:  status - ${error.status}  | text:  ${error.statusText}`;
				setError(error);
				setIsLoading(false);
			});
	};

	const saveNewsletter = (newsletterId, countryCode) => {
		fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.UserNewsletters}`,
			{
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					Email: user.email,
					NewsletterId: newsletterId,
					CountryCode: countryCode
				})
			}
		)
			.then(() => {
				setChanged(false);
			})
			.catch((error) => {
				setError(error);
				`${error} error:  status - ${error.status}  | text:  ${error.statusText}`;
			});
	};

	const deleteNewsletter = (newsletterId: string) => {
		fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.UserNewsletters}/${user.email}/${newsletterId}`,
			{
				method: 'DELETE'
			}
		)
			.then(() => {
				setChanged(false);
			})
			.catch((error) => {
				console.log(
					`error:  status - ${error.status}  | text:  ${error.statusText}`
				);
			});
	};

	const saveNewsletters = (
		setIsNewslettersModalOpened: (state: boolean) => void
	) => {
		newsletters.map((newsletter: PremiumNewsletter) => {
			if (newsletter.Subscribed) {
				saveNewsletter(newsletter.Id, newsletter.CountryCode);
			} else {
				deleteNewsletter(newsletter.Id);
			}
		});
		setSaved(true);
		setTimeout(() => {
			setSaved(false);
			setIsNewslettersModalOpened(false);
		}, 2000);

		setChanged(false);
	};

	const resetNewsletters = () => {
		getNewsletters(user);
		setChanged(false);
	};

	const subscribedToAllNewsletters = (newsletters: PremiumNewsletter[]) => {
		return newsletters.every(
			(newsletter: PremiumNewsletter) => newsletter.Subscribed
		);
	};

	const handleSubscribeAll = (newsletters: PremiumNewsletter[]) => {
		if (subscribedToAllNewsletters(newsletters)) {
			setNewsletters(
				newsletters.map((newsletter: PremiumNewsletter) => {
					return { ...newsletter, Subscribed: false };
				})
			);
		} else {
			setNewsletters(
				newsletters.map((newsletter: PremiumNewsletter) => {
					return { ...newsletter, Subscribed: true };
				})
			);
		}
		setChanged(true);
	};

	useEffect(() => {
		if (user) {
			getNewsletters(user);
			setChanged(false);
		}
	}, []);

	return (
		<NewsletterContext.Provider
			value={{
				error,
				newsletters,
				setNewsletters,
				changed,
				setChanged,
				saved,
				isLoading,
				getNewsletters,
				saveNewsletters,
				resetNewsletters,
				subscribedToAllNewsletters,
				handleSubscribeAll
			}}
		>
			{children}
		</NewsletterContext.Provider>
	);
};
export const useNewsletterContext = () => {
	return useContext(NewsletterContext);
};
