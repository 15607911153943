import React from 'react';
import OneSignal from 'react-onesignal';

import { NOTIFICATIONS } from 'constants/notifications';
import {
	NotificationsContext,
	initialNotificationsState,
	noneNotificationsState
} from 'contexts/notificationsContext';

export const useNotification = (
	isNotificationsModalOpened: boolean,
	setIsNotificationsModalOpened: (boolean: boolean) => void
) => {
	const { notifications, saveNotifications, clearNotifications } =
		React.useContext(NotificationsContext);

	const [allNotificationsOn, setAllNotificationsOn] = React.useState(true);
	const [changesSaved, setChangesSaved] = React.useState(false);
	const [state, setState] = React.useState(notifications);
	const [areChanges, setAreChanges] = React.useState(false);

	const notificationFromStorage = JSON.parse(
		window.localStorage.getItem(NOTIFICATIONS)
	);

	React.useEffect(() => {
		if (notificationFromStorage !== null) setState(notificationFromStorage);
	}, [isNotificationsModalOpened]);

	React.useEffect(() => {
		Object.values(state).every((value) => value === true)
			? setAllNotificationsOn(true)
			: setAllNotificationsOn(false);
	}, [state]);

	const handleCloseModal = (
		e: React.MouseEvent<
			HTMLDivElement | SVGSVGElement | HTMLButtonElement,
			MouseEvent
		>
	) => {
		e.stopPropagation();
		setIsNotificationsModalOpened(false);
	};

	const handleAllNotificationsOn = () => {
		!allNotificationsOn ? resetNotifications() : disableAllNotifications();
		setAreChanges(true);
	};

	const handleNotifications = (key: string) => {
		setState({
			...state,
			[key]: !state[key]
		});
		setAreChanges(true);
	};

	const save = () => {
		const timer = 2000;
		saveNotifications(state);
		setAreChanges(false);
		setChangesSaved(true);
		Object.entries(state).forEach(([key, value]) =>
			OneSignal.sendTag(key, value)
		);
		setTimeout(() => {
			setChangesSaved(false);
			setIsNotificationsModalOpened(false);
		}, timer);
	};

	const resetNotifications = () => {
		clearNotifications();
		setState(initialNotificationsState);
		setAreChanges(false);
	};

	const disableAllNotifications = () => {
		clearNotifications();
		setState(noneNotificationsState);
		setAllNotificationsOn(false);
	};

	return {
		handleCloseModal,
		handleAllNotificationsOn,
		handleNotifications,
		save,
		resetNotifications,
		allNotificationsOn,
		changesSaved,
		state,
		areChanges
	};
};
