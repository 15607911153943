import styled from 'styled-components';
import { FXSBtnCta } from 'styles/button';
import { COLORS } from 'styles/colors';
import { TYPOGRAPHY } from 'styles/typography';

export const PopUpContainer = styled.div`
	position: absolute;
	right: 0;
	top: 55px;
	bottom: 0;
	z-index: 12;
	display: flex;
	align-items: center;
	justify-content: center;
	height: calc(100vh -55px);
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.55);
	@media screen and (min-width: 833px) {
		top: 66px;
		width: calc(100vw - 255px);
		height: 100vh;
	}
`;
export const PopUpWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #1a1d24;
	height: 280px;
	width: calc(100% - 32px);
	max-width: 788px;
	padding: 0 1.5rem;
	@media screen and (min-width: 833px) {
		justify-content: flex-start;
		padding: 0 2rem;
	}
`;
export const PopUpWrapperIcon = styled.div`
	display: flex;
	justify-content: center;
	height: 45px;
	width: 45px;
	margin-top: -45px;
	color: ${COLORS.grey600};
	font-size: 32px;
	line-height: 45px;
`;
export const PopUpTitle = styled.h2`
	max-width: 415px;
	margin-bottom: 1rem;
	color: ${COLORS.white};
	${TYPOGRAPHY.FXS_hd_med};
`;
export const PopUpLink = styled.a`
	${FXSBtnCta};
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
	width: 178px;
	font-size: 12.8px;
	line-height: 22px;
	&:hover {
		text-decoration: none;
	}
	@media screen and (min-width: 833px) {
		width: 206px;
		font-size: 14.7px;
	}
`;
