import styled, { css } from 'styled-components';
import { FXSBtnCta } from 'styles/button';
import { COLORS } from 'styles/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const NotificationsTutorialBackdrop = styled.div<{
	isNotificationsModalOpened: boolean;
}>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: ${({ isNotificationsModalOpened }) =>
		isNotificationsModalOpened ? 'flex' : 'none'};
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
export const NotificationsTutorialWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 606px;
	width: calc(100% - 6px);
	max-width: 354px;
	background: ${COLORS.white};
	@media (min-width: 833px) {
		height: 572px;
		width: 420px;
		max-width: 420px;
	}
`;
export const NotificationsTutorialCloseIcon = styled(FontAwesomeIcon)`
	position: absolute;
	top: 1.5rem;
	right: 1.5rem;
	height: 24px;
	width: 24px !important;
	color: ${COLORS.dark};
`;
export const NotificationsTutorialImg = styled.img`
	display: block;
	height: 329px;
	max-width: 354px;
	margin: 60px auto 0;
	@media (min-width: 833px) {
		height: 254px;
		width: 350px;
		margin: 69px auto 0;
	}
`;
const TutorialImgBtn = css`
	position: absolute;
	top: 60px;
	height: 329px;
	width: calc(354px / 2);
	max-width: 50vw;
	border: none;
	background: transparent;
`;
export const NotificationsTutorialImgBtnPrev = styled.button`
	${TutorialImgBtn};
	left: 0;
`;
export const NotificationsTutorialImgBtnNext = styled.button`
	${TutorialImgBtn};
	right: 0;
`;
export const NotificationsTutorialHeader = styled.div`
	position: relative;
	height: 409px;
	background: ${COLORS.grey200};
	@media (min-width: 833px) {
		height: 348px;
	}
`;
export const NotificationsTutorialBody = styled.div`
	padding: 1rem 1.5rem 1.5rem;
	@media (min-width: 833px) {
		padding: 1.5rem;
	}
`;
export const NotificationTutorialHeading = styled.h2`
	margin-bottom: 0.75rem;
	font-size: 17.6px;
	line-height: 21px;
	@media (min-width: 768px) {
		font-size: 22.4px;
		line-height: 26px;
	}
`;
export const NotificationTutorialP = styled.p`
	color: ${COLORS.corp};
	font-weight: 300;
	font-size: 14.72px;
	line-height: 20px;
	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 22px;
	}
`;
export const NotificationsTutorialBtnWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
export const NotificationsTutorialLink = styled.a`
	color: ${COLORS.dark};
	font-weight: 700;
	font-size: 12.8px;
	line-height: 22px;
	text-decoration-line: underline;
`;
export const NotificationsTutorialBtn = styled.button`
	${FXSBtnCta};
	width: 81px;
`;
