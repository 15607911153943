import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { INSIGHTS } from 'constants/locations';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { getSignalsMessageById } from 'data/getSignalsMessageById';
import { getEventFollow } from 'data/getUserEventFollow';
import { setBookmarkedSignals } from 'data/setBookmarkedSignals';
import { setLikedSignals } from 'data/setLikedSignals';
import { setTerms } from 'data/setTerms';
import { setEventFollow } from 'data/setUserEventFollow';
import * as fxsSignalsInsights from 'fxs-signals-insights';
import { SignalsMessageState } from 'models/signalMessages';

import { FXSInsightsWrapper } from './styles';

const initialState: SignalsMessageState = {
	messages: undefined,
	isFetching: true
};

const SignalsInsightsMessage = () => {
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();
	const [message, setMessage] = useState(initialState);
	const culture = window.config.Variables.Culture;

	const { pathname } = useLocation();
	const id = pathname.split('/')[2];

	useEffect(() => {
		getSignalsMessageById(id).then((payload) => {
			setMessage({ messages: payload.data, isFetching: false });
		});
	}, [id]);

	return (
		<FXSInsightsWrapper>
			<fxsSignalsInsights.SignalsInsights
				isPremium={user?.isPremium}
				data={message.messages}
				isFetching={message.isFetching}
				t={t}
				culture={culture}
				signalsPageUrl={window.config.Variables.SignalsLandingUrl}
				setLike={(id, status) => setLikedSignals(id, status)}
				setBookmark={(id, status) => setBookmarkedSignals(id, status)}
				getEventFollow={(eventId) => getEventFollow(eventId)}
				setEventFollow={(eventId, eventTitle) =>
					setEventFollow(eventId, eventTitle)
				}
				setTerms={(eventTitle) => setTerms(eventTitle)}
				messagePageUrl={`/${INSIGHTS}/`}
				isEmployee={user?.isEmployee}
			/>
		</FXSInsightsWrapper>
	);
};

export default SignalsInsightsMessage;
