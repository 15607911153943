import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { FilterDesktopBtn } from 'components/FilterBtn';
import { SignalsInsightsContext } from 'contexts/signalsInsightsContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { InsightsType } from 'models/enums';

import { TabLinks } from './TabLinks';
import {
	SInsightsTab,
	SInsightsTabLink,
	SInsightsTabWrapper,
	SSingalsHeaderInner
} from './styles';

const InsightsTab = () => {
	const { t } = useTranslationsContext();
	const { state, saveState } = useContext(SignalsInsightsContext);

	const { pathname } = useLocation();
	const splitLocation = pathname.split('/')[1].toLowerCase();

	return (
		<SInsightsTab>
			<SInsightsTabWrapper>
				<SSingalsHeaderInner>
					{TabLinks.map((tab) => (
						<SInsightsTabLink
							key={tab.id}
							exact
							to={tab.route}
							type='button'
							onClick={() => {
								saveState({
									...state,
									type: InsightsType[tab.type]
								});
							}}
						>
							{t(tab.label)}
						</SInsightsTabLink>
					))}
				</SSingalsHeaderInner>
				{splitLocation === InsightsType.latest && <FilterDesktopBtn />}
			</SInsightsTabWrapper>
		</SInsightsTab>
	);
};
export default InsightsTab;
