import React, { useState } from 'react';

import {
	SignalsInsightsContext,
	SignalsInsightsState,
	initialState
} from '../contexts/signalsInsightsContext';

export const SignalsInsightsContextProvider = ({ children }) => {
	const [state, setState] = useState(initialState);

	function saveState(newState: SignalsInsightsState): void {
		setState(newState);
	}

	return (
		<SignalsInsightsContext.Provider value={{ state, saveState }}>
			{children}
		</SignalsInsightsContext.Provider>
	);
};
