import React from 'react';

import { FilterState } from 'contexts/filtersContext';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSlidersVSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { SFilterActive } from './styles';

type FilterIconProps = {
	isLessThan833: boolean;
	filters: FilterState;
};

const FilterIcon = ({ filters, isLessThan833 }: FilterIconProps) => {
	return (
		<React.Fragment>
			<FontAwesomeIcon icon={faSlidersVSquare as IconProp} />
			{(filters.categories?.length > 0 ||
				filters.markets?.length > 0 ||
				filters.tags?.length > 0) && (
				<SFilterActive isLessThan833={isLessThan833} />
			)}
		</React.Fragment>
	);
};

export default FilterIcon;
