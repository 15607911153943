import { FullWidthMainLayout } from 'components/Layouts';
import YoutubeIframe from 'components/YoutubeIframe';
import { MASTER_CLASS_EMBED_CODE_QUERY } from 'constants/queryParams';
import { useTranslationsContext } from 'contexts/translationsContext';
import { FXS_hd_med_to_large } from 'styles/typography';
import { getQueryParam } from 'utils/getQueryParam';

export default function Masterclass() {
	const { t } = useTranslationsContext();

	const masterClassEmbedCode = getEmbedCode();

	return (
		<FullWidthMainLayout>
			<FXS_hd_med_to_large style={{ margin: '1rem 0 2rem' }}>
				{t('liveMasterclass')}
			</FXS_hd_med_to_large>
			{/*Convertir en texto dinámico junto con la fecha del countdown*/}
			{masterClassEmbedCode && (
				<YoutubeIframe embedCode={masterClassEmbedCode} />
			)}
		</FullWidthMainLayout>
	);

	function getEmbedCode() {
		let masterClassEmbedCode = window['masterClassEmbedCode'];
		if (getQueryParam(MASTER_CLASS_EMBED_CODE_QUERY))
			masterClassEmbedCode = getQueryParam(MASTER_CLASS_EMBED_CODE_QUERY);
		return masterClassEmbedCode;
	}
}
