import { useEffect, useState } from 'react';

import { ConnectedHubs } from '../contexts/pushContext';
import { CrudMethod } from '../models/enums';
import {
	MessagePushPayload,
	TradeMessagePush
} from '../models/signalPushMessages';
import { SignalPushManager } from '../utils/signalSubscribe';

let signalManager: SignalPushManager;

const HUB_NAME = 'signalsMessages';

const subscribeEditorial = (
	setNewMessage: (messages: MessagePushPayload) => void
) => {
	try {
		signalManager = new SignalPushManager(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Push}`
		);
		signalManager.signalEditorialSubscribe(
			(payload: MessagePushPayload) => {
				setNewMessage(payload);
			}
		);

		signalManager.signalSubscribe((signal: TradeMessagePush) => {
			setNewMessage({
				message: signal,
				method: CrudMethod.Create
			} as MessagePushPayload);
		});

		return true;
	} catch (error) {
		return false;
	}
};

export function useSignalMessagesPush(
	connected: ConnectedHubs
): MessagePushPayload {
	const [newMessage, setNewMessage] = useState(null as MessagePushPayload);

	if (!connected.hasHub(HUB_NAME)) {
		const subscribed = subscribeEditorial(setNewMessage);
		if (subscribed) connected.addHub(HUB_NAME);
	}

	useEffect(() => {
		return () => {
			signalManager.signalUnSubscribe();
			connected.removeHub(HUB_NAME);
		};
	}, []);

	return newMessage;
}
