import { useEffect, useState } from 'react';

import { TradePlanPushPayload } from 'models/tradePlanPush';

import { ConnectedHubs } from '../contexts/pushContext';
import { SignalPushManager } from '../utils/signalSubscribe';

let signalManager: SignalPushManager;

const HUB_NAME = 'signals_tradeplan';

const subscribeTrades = (
	setNewTrade: (tradePlan: TradePlanPushPayload) => void
) => {
	try {
		signalManager = new SignalPushManager(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Push}`
		);
		signalManager.signalTradePlanSubscribe(
			(tradePlan: TradePlanPushPayload) => {
				setNewTrade(tradePlan);
			}
		);

		return true;
	} catch (err) {
		return false;
	}
};

export function useTradePlanPush(
	connected: ConnectedHubs
): TradePlanPushPayload {
	const [newTrade, setNewTrade] = useState(null as TradePlanPushPayload);

	if (!connected.hasHub(HUB_NAME)) {
		const subscribed = subscribeTrades(setNewTrade);
		if (subscribed) connected.addHub(HUB_NAME);
	}
	useEffect(() => {
		return () => {
			signalManager.signalUnSubscribe();
			connected.removeHub(HUB_NAME);
		};
	}, []);

	return newTrade;
}
