import { DEFAULT_STRATEGY_ID } from '../constants/strategies';
import { SignalStrategyHistoricalResponse } from '../models/historicalData';
import { getUser } from '../utils/getUser';

const fetcher = async (
	strategyId?: string
): Promise<SignalStrategyHistoricalResponse> => {
	return await getUser().then(async (user) => {
		try {
			const strategy = strategyId ?? DEFAULT_STRATEGY_ID;
			const response = await fetch(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalHistorical.replace('{strategy}', strategy)}`,
				{
					method: 'GET',
					headers: {
						Accept: 'application/json',
						Authorization: `bearer ${user?.token}`
					}
				}
			);

			const data = await response.json();
			return { data } as SignalStrategyHistoricalResponse;
		} catch (error) {
			console.log(error);
			return { error: true } as SignalStrategyHistoricalResponse;
		}
	});
};

export async function getStrategyHistorical(
	strategyId?: string
): Promise<SignalStrategyHistoricalResponse> {
	return await fetcher(strategyId);
}
