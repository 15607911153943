import { useEffect, useState } from 'react';

import getLocalizationData from 'data/getLocalizationData';

const getTranslations = (json: any) => {
	return (key: string): string => {
		return (json && json[key]) || key;
	};
};

export const useTranslation = (): { t: (key: string) => string } => {
	const [translations, setTranslations] = useState(null);
	useEffect(() => {
		(async () => {
			let temporalData = null;
			await import('../translations/en.json')
				.then((jsonData) => {
					temporalData = jsonData.default;
					setTranslations(temporalData);
				})
				.catch((error) => {
					error.log(error);
				});

			await getLocalizationData().then((localization) => {
				if (localization) {
					const merged = Object.assign(
						{},
						temporalData,
						localization
					);
					setTranslations({ ...merged });
				}
			});
		})();
	}, []);

	return translations
		? { t: getTranslations(translations) }
		: { t: getTranslations({}) };
};
