import { useContext } from 'react';

import { FullWidthMainLayout } from 'components/Layouts';
import { FullWidthMainLayoutInner } from 'components/Layouts/styles';
import { ConfigContext } from 'contexts/configContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { EventList } from 'fxs-event-list';
import { FXS_hd_small_med } from 'styles/typography';

export default function Webinars() {
	const { t } = useTranslationsContext();
	const { config } = useContext(ConfigContext);

	return (
		<FullWidthMainLayout>
			<FullWidthMainLayoutInner>
				<FXS_hd_small_med style={{ marginBottom: '1rem' }}>
					{t('nextWebinarsTitle')}
				</FXS_hd_small_med>
				<EventList
					appId={config.Variables.AlgoliaAppId}
					apiKey={config.Variables.AlgoliaApiKey}
					indexName={config.Variables.AlgoliaIndex}
					take={40}
					culture={config.Variables.Culture}
					t={t}
				/>
			</FullWidthMainLayoutInner>
		</FullWidthMainLayout>
	);
}
