import { createContext, useContext } from 'react';

import { useTranslation } from 'utils/useTranslation';

export type ContextProps = {
	t?: (key: string) => string;
};

const Translations = createContext({});

export const TranslationsContext = ({ children }) => {
	const { t } = useTranslation();

	return (
		<Translations.Provider value={{ t }}>{children}</Translations.Provider>
	);
};

export const useTranslationsContext = (): ContextProps => {
	return useContext(Translations);
};
