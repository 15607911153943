import { SideType } from '../models/enums';
import {
	RelatedCalendarContent,
	RelatedPostContent,
	RelatedRecordingContent,
	RelatedSignalContent,
	RelatedWebinarContent
} from '../models/signalMessages';
import {
	RelatedCalendarContentPush,
	RelatedPostContentPush,
	RelatedRecordingContentPush,
	RelatedSignalContentPush,
	RelatedWebinarContentPush
} from '../models/signalRelatedContentPush';

export const mapRelatedPostContent = (source: RelatedPostContentPush) => {
	if (!source) return null;

	return {
		authorImageUrl: source?.AuthorImageUrl,
		authorName: source?.AuthorName,
		contentType: source?.ContentType,
		imageUrl: source?.ImageUrl,
		isPremium: source?.IsPremium,
		postId: source?.PostId,
		postUrl: source?.PostUrl,
		publicationDateUtc: source?.PublicationDateUtc,
		summary: source?.Summary,
		title: source?.Title
	} as RelatedPostContent;
};

export const mapRelatedWebinarContent = (source: RelatedWebinarContentPush) => {
	if (!source) return null;

	return {
		eventPostId: source?.EventPostId,
		eventPostUrl: source?.EventPostUrl,
		feedId: source?.FeedId,
		title: source?.Title,
		duration: source?.Duration,
		publicationDateUtc: source?.PublicationDateUtc,
		summary: source?.Summary,
		authorName: source?.AuthorName,
		authorImageUrl: source?.AuthorImageUrl,
		isPremium: source?.IsPremium
	} as RelatedWebinarContent;
};

export const mapRelatedRecordingContent = (
	source: RelatedRecordingContentPush
) => {
	if (!source) return null;

	return {
		liveVideoId: source.LiveVideoId,
		liveVideoUrl: source.LiveVideoUrl,
		videoUrl: source.VideoUrl,
		title: source.Title,
		contentType: source.ContentType,
		publicationDateUtc: source.PublicationDateUtc,
		feedId: source.FeedId,
		duration: source.Duration,
		summary: source.Summary,
		authorName: source.AuthorName,
		authorImageUrl: source.AuthorImageUrl,
		isPremium: source.IsPremium
	} as RelatedRecordingContent;
};

export const mapRelatedCalendarContent = (
	source: RelatedCalendarContentPush
) => {
	if (!source) return null;

	return {
		potency: source?.Potency,
		unit: source?.Unit,
		eventId: source.EventId,
		name: source?.Name,
		description: source?.Description,
		actual: source?.Actual,
		consensus: source?.Consensus,
		countryCode: source?.CountryCode,
		dateUtc: source?.DateUtc,
		id: source?.Id,
		source: source?.Source,
		isAllDay: source?.IsAllDay,
		isReport: source?.IsReport,
		isSpeech: source?.IsSpeech,
		previous: source?.Previous,
		urlSource: source?.UrlSource,
		volatility: source?.Volatility
	} as RelatedCalendarContent;
};

export const mapRelatedSignalContent = (source: RelatedSignalContentPush) => {
	if (!source) return null;

	return {
		asset: source.Asset,
		closeUtc: source?.CloseUtc,
		entryPrice: source.EntryPrice,
		openUtc: source.OpenUtc,
		pips: source?.Pips,
		profitPercent: source?.ProfitPercent,
		positionMaintenanceId: source.PositionMaintenanceId,
		sideType:
			source.SideType.toUpperCase() === SideType.Buy
				? SideType.Buy
				: SideType.Sell,
		stopLoss: source?.StopLoss,
		strategyFancyName: source.StrategyFancyName,
		strategyFancyType: source.StrategyFancyType,
		strategyId: source.StrategyId,
		takeProfit: source?.TakeProfit
	} as RelatedSignalContent;
};
