import React from 'react';

import { useTranslationsContext } from 'contexts/translationsContext';
import { PremiumNewsletter } from 'models/newsletter';
import { TranslateProps } from 'models/props';

import { EnvelopeSmall } from './envelopeSmall';
import {
	NewsletterDescription,
	NewsletterPreview,
	NewsletterTime,
	NewsletterTimeValue,
	NewslettersModalCheckbox,
	NewslettersModalDescription,
	NewslettersModalRow,
	NewslettersModalSwitchCircle,
	NewslettersModalSwitchWrapper
} from './styles';

type NewsletterRowProps = {
	newsletter: PremiumNewsletter;
	newsletters: PremiumNewsletter[];
	setNewsletters: React.Dispatch<React.SetStateAction<any[]>>;
	setChanged: React.Dispatch<React.SetStateAction<boolean>>;
} & TranslateProps;

export const NewsletterRow: React.FC<NewsletterRowProps> = ({
	newsletter,
	newsletters,
	setNewsletters,
	setChanged
}) => {
	const { t } = useTranslationsContext();

	const switchSubscribe = () => {
		setNewsletters(
			newsletters.map((newsletterItem) => {
				if (newsletterItem.Id === newsletter.Id) {
					return {
						...newsletterItem,
						Subscribed: !newsletterItem.Subscribed
					};
				} else {
					return newsletterItem;
				}
			})
		);
		setChanged(true);
	};

	return (
		<>
			<NewslettersModalRow>
				<NewslettersModalDescription>
					<h3>{newsletter.Name}</h3>
				</NewslettersModalDescription>
				<NewslettersModalCheckbox>
					<NewslettersModalSwitchWrapper
						isActive={newsletter.Subscribed}
						onClick={switchSubscribe}
					>
						<NewslettersModalSwitchCircle
							isActive={newsletter.Subscribed}
							onClick={switchSubscribe}
						/>
					</NewslettersModalSwitchWrapper>
				</NewslettersModalCheckbox>
			</NewslettersModalRow>
			<NewsletterTime>
				<EnvelopeSmall />
				<NewsletterTimeValue>{newsletter.Time}</NewsletterTimeValue>
			</NewsletterTime>
			<NewsletterDescription>
				{newsletter.Description}
			</NewsletterDescription>
			{newsletter.ArchiveUrl && (
				<NewsletterPreview href={newsletter.ArchiveUrl}>
					{t('Preview')}
				</NewsletterPreview>
			)}
		</>
	);
};
