import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
	FXSLeftColLayout,
	FXSRightColLayout,
	TwoColsMainLayout
} from 'components/Layouts';
import { StrategiesDisclamimer } from 'components/StrategyDisclaimer';
import UserNoPremium from 'components/UserNoPremium';
import StrategyFullStats from 'components/strategyFullStats';
import { ConfigContext } from 'contexts/configContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { getStrategyHistorical } from 'data/getStrategyHistorical';
import { useStrategyInfo } from 'data/useStrategyInfo';
import { useStrategyStats } from 'data/useStrategyStats';
import { SignalStrategyHistoricalChart } from 'fxs-signal-strategy-historical-chart';
import { SignalStrategyStatsTable } from 'fxs-signal-strategy-stats-table';
import SignalsStrategyDescription from 'fxs-signals-strategy-description';
import { SignalStrategyTitle } from 'fxs-signals-strategy-title';
import { mapHistoricalChart } from 'mappers/historicalChart';
import { mapStats } from 'mappers/strategyStats';
import { SignalStrategyHistoricalResponse } from 'models/historicalData';
import { PremiumProps } from 'models/props';
import { StatCalculationResponse } from 'models/strategy';
import { FXS_Subtitle, FXS_hd_small } from 'styles/typography';
import { isLessThan } from 'utils/useWindowWidth';

export default function Strategies({ isPremium }: PremiumProps) {
	const { t } = useTranslationsContext();

	const { id } = useParams<{ id: string }>();
	const strategyInfoResponse = useStrategyInfo(id);
	const [statCalculation, setStatCalculation] = useState(
		null as StatCalculationResponse
	);
	const [strategyHistorical, setStrategyHistorical] = useState(
		null as SignalStrategyHistoricalResponse
	);
	const strategyStatsResponse = useStrategyStats(id);
	const { config } = useContext(ConfigContext);

	useEffect(() => {
		setStatCalculation(strategyStatsResponse.statCalculation);
	}, [strategyStatsResponse]);

	useEffect(() => {
		getStrategyHistorical(id).then((response) => {
			setStrategyHistorical(response);
		});
		const main = document.querySelector('main');
		if (main !== null) {
			main.scrollTop = 0;
		}
	}, [id, config.Endpoints.SignalHistorical]);

	const isLessThan1280 = isLessThan(1280);
	const isLessThan833 = isLessThan(833);

	return (
		<TwoColsMainLayout>
			{!isPremium && <UserNoPremium />}
			<FXSLeftColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
			>
				{strategyInfoResponse && (
					<div>
						<SignalStrategyTitle
							data={strategyInfoResponse}
							t={t}
						/>
						<FXS_hd_small
							className={
								isLessThan833
									? 'fxs_marginBase_top'
									: 'fxs_marginBase_top_big'
							}
						>
							{t('Performance Stats')}
						</FXS_hd_small>
						<SignalStrategyHistoricalChart
							data={strategyHistorical?.data?.map((x) =>
								mapHistoricalChart(x)
							)}
							isFetching={
								!strategyHistorical?.data &&
								!strategyHistorical?.error
							}
							error={strategyHistorical?.error}
							t={t}
						/>
						<StrategiesDisclamimer>
							<p>{t('disclaimerText')}</p>
						</StrategiesDisclamimer>
						<SignalStrategyStatsTable
							error={false}
							isFetching={!statCalculation}
							data={statCalculation && mapStats(statCalculation)}
							t={t}
						/>
						<SignalsStrategyDescription
							data={strategyInfoResponse.description}
							t={t}
						/>
					</div>
				)}
			</FXSLeftColLayout>
			<FXSRightColLayout
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
				removePaddings={false}
			>
				<FXS_Subtitle>{t('allStrategiesTitle')}</FXS_Subtitle>
				<StrategyFullStats />
			</FXSRightColLayout>
		</TwoColsMainLayout>
	);
}
