import { useContext, useEffect, useRef, useState } from 'react';

import IconAngleDown from 'components/icons/IconAngleDown';
import IconLogout from 'components/icons/IconLogout';
import IconUser from 'components/icons/IconUser';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { logout } from 'utils/logout';

import { FXSLogoutBtn, FXSUserButton, FXSUserButtonText, FXSUserWrapper } from './styles';

function useOutsideAlerter(ref, callback) {
	useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		}
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export default function UserInfoDesktop() {
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();

	const [isLogoutOpen, setLogoutOpen] = useState(false);
	const wrapperRef = useRef(null);
	const outsideAlerterCallback = () => {
		setLogoutOpen(false);
	};
	useOutsideAlerter(wrapperRef, outsideAlerterCallback);

	return (
		user && (
			<FXSUserWrapper ref={wrapperRef}>
				<FXSUserButton
					type='button'
					onClick={() => {
						setLogoutOpen(!isLogoutOpen);
					}}
				>
					<IconUser />
					<FXSUserButtonText>{user.name}</FXSUserButtonText>
					<IconAngleDown />
				</FXSUserButton>
				{isLogoutOpen && (
					<FXSLogoutBtn
						type='button'
						onClick={() => {
							logout();
						}}
					>
						<IconLogout />
						<span>{t('Logout')}</span>
					</FXSLogoutBtn>
				)}
			</FXSUserWrapper>
		)
	);
}
