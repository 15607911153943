import React from 'react';

export type ConnectedHubs = {
	hubs: string[];
	addHub: (hub: string) => void;
	removeHub: (hub: string) => void;
	hasHub: (hub: string) => boolean;
};

export const PushContext = React.createContext<ConnectedHubs>(null);
