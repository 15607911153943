import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const Division = styled.div`
	position: fixed;
	width: 100vw;
	height: 100vh;
	z-index: 9999999999;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${COLORS.grey200};
`;
