import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { SignalStrategyStatsResponse } from '../models/strategyStats';
import { getUser } from '../utils/getUser';

const fetcher = (strategyId: string) => async () => {
	return getUser().then(async (user) => {
		const res = await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalStatsStrategyId.replace('{strategyId}', strategyId)}`,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			}
		);

		return await res.json();
	});
};

export function useStrategyStats(
	strategyId: string
): SignalStrategyStatsResponse {
	const [strategyStats, setStrategyStats] = useState(null);
	const { data, error } = useSWR(
		['getStrategyStats', strategyId],
		fetcher(strategyId)
	);

	if (error) console.log(error);

	useEffect(() => {
		setStrategyStats(data);
	}, [data]);

	return { ...strategyStats };
}
