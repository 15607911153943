import { NavLink } from 'react-router-dom';

import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'styles/colors';

const blinking = keyframes`
  0% {
    background-color: ${COLORS.white};
  }
  50% {
    background-color: ${COLORS.red_alternate_dark_1};
  }
  100% {
    background-color: ${COLORS.white};
  }
`;

export const FXSNavMenu = styled.nav`
	display: flex;
	flex-direction: column;
`;
const FXSLink = css`
	display: flex;
	align-items: center;
	height: 40px;
	padding-right: 2rem;
	padding-left: 8px;
	color: ${COLORS.grey500};
	font-size: 19.2px;
	font-weight: 400;
	line-height: 2.5rem;
	text-decoration: none;
	white-space: nowrap;
	position: relative;
	&:hover {
		text-decoration: none;
	}
`;

export const BetaBadget = styled.span`
	position: absolute;
	top: 2px;
	right: 2px;
	border-radius: 6px;
	background: #e4871b;
	color: #fff;
	text-align: center;
	font-size: 10px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	line-height: 13px;
	padding: 0 6px;
`;

export const FXSNavLink = styled(NavLink)`
	${FXSLink};
	border-left: 6px solid transparent;
	&.fxs_active {
		border-left: 6px solid ${COLORS.highlight};
		color: ${COLORS.white};
		background-color: rgba(255, 255, 255, 0.1);
	}
	.svg-inline--fa,
	svg:not(:root).svg-inline--fa {
		width: 24px;
		margin-right: 8px;
	}
`;
export const FXSNavLinkLiveMasterClass = styled(NavLink)<{
	isLiveMasterclass?: boolean;
}>`
	${FXSLink};
	position: relative;
	margin-top: 30px;
	border: 0;
	span,
	svg {
		z-index: 1;
		color: ${COLORS.interaction_blue_light_1};
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 21px;
		right: 21px;
		background-color: ${COLORS.blue};
		color: ${COLORS.interaction_blue_light_1};
		border-radius: 5px;
		z-index: 0;
	}
	&.fxs_active {
		span,
		svg {
			color: white;
		}
		&:before {
			background-color: rgba(255, 255, 255, 0.1);
			border: 1px solid ${COLORS.interaction_blue_light_1};
		}
	}

	@media screen and (min-height: 600px) {
		margin-top: 60px;
	}

	${({ isLiveMasterclass }) =>
		isLiveMasterclass &&
		css`
			position: relative;
			&:after {
				content: '';
				width: 8px;
				height: 8px;
				border-radius: 50%;
				position: absolute;
				top: 8px;
				left: 52px;
				animation: ${blinking} 2s ease-in-out infinite;
			}
		`}
`;
