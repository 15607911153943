import React, { useContext, useEffect, useState } from 'react';

import { FILTERS, TAGS } from 'constants/filter';
import { DefaultTags } from 'constants/tags';
import { FilterContext, initialFilterState } from 'contexts/filtersContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { EditorialCategoryType } from 'models/enums';
import { TranslateProps } from 'models/props';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import CategoryRow from './CategoryRow';
import MarketRow from './MarketRow';
import TagsRow from './TagsRow';
import TradesRow from './TradesRow';
import {
	FXSFIlterBodyWrapper,
	FXSFilterBtnApply,
	FXSFilterBtnReset,
	FXSFilterBtnsWrapper,
	FXSFilterMobileBg,
	FXSFilterMobileHeader,
	FXSFilterMobileHeaderCloseIcon,
	FXSFilterMobileHeaderTitle,
	FXSFilterMobileWrapper,
	FXSFilterWrapper
} from './styles';

type FilterModalProps = {
	show: boolean;
	setShow: (status: boolean) => void;
	elementRef?: React.RefObject<HTMLDivElement>;
} & TranslateProps;

const FilterModal = ({ show, setShow, elementRef }: FilterModalProps) => {
	const { t } = useTranslationsContext();

	const { filters, saveFilters, clearFilters } = useContext(FilterContext);
	const [state, setState] = useState(filters);
	const [tagNames, setTagNames] = useState([]);

	const activeTrades = state?.categories?.includes(
		EditorialCategoryType.Trade
	);
	const localFilters = JSON.parse(window.localStorage.getItem(FILTERS));

	const isLessThan833 = isLessThan(833);

	useEffect(() => {
		if (filters.tags !== null) {
			filters.tags.filter((tag) =>
				DefaultTags.filter((defaultTag) => {
					if (
						tag === defaultTag.id &&
						!tagNames.includes(defaultTag.name)
					) {
						tagNames.push(defaultTag.name);
					}
				})
			);
		}
	}, [filters.tags]);

	useEffect(() => {
		setState(localFilters);
	}, [show]);

	function saveFilter(value: string, type: string) {
		const index =
			type === TAGS
				? state[type]?.indexOf(value)
				: state[type]?.indexOf(value.toLowerCase());
		if (index !== -1) {
			state[type]?.splice(index, 1);
			setState({ ...state });
		} else {
			setState({
				...state,
				[type]: [...state[type], value]
			});
		}
	}

	function applyFilter() {
		if (activeTrades) {
			resetToDefault();
			setState({
				...initialFilterState,
				categories: [EditorialCategoryType.Trade]
			});
		}

		saveFilters(state);
		setShow(!show);
	}

	function resetToDefault() {
		clearFilters();
		setTagNames([]);
		setState(initialFilterState);
	}

	const handleClick = (
		e: React.MouseEvent<
			SVGElement | HTMLDivElement | HTMLButtonElement | MouseEvent
		>,
		value: string,
		type: string,
		tagName: string,
		onlyTrade: boolean
	) => {
		e.stopPropagation();

		if (tagNames.includes(tagName)) {
			const tagIndex = tagNames.indexOf(tagName);
			tagNames.splice(tagIndex, 1);
			setTagNames([...tagNames]);
		}

		if (tagName !== null) tagNames.push(tagName);

		if (onlyTrade && !activeTrades) {
			setState({
				...initialFilterState,
				categories: [EditorialCategoryType.Trade]
			});
		}

		saveFilter(value, type);
	};

	const FilterModalBody = () => {
		return (
			<FXSFIlterBodyWrapper
				activeTrade={activeTrades}
				onClick={(e) => e.stopPropagation()}
			>
				<TradesRow
					activeTrades={activeTrades}
					handleClick={handleClick}
				/>
				<MarketRow
					activeTrades={activeTrades}
					state={state}
					handleClick={handleClick}
					translate={t}
				/>
				<CategoryRow
					activeTrades={activeTrades}
					state={state}
					handleClick={handleClick}
					translate={t}
				/>
				<TagsRow
					activeTrades={activeTrades}
					state={state}
					handleClick={handleClick}
					translate={t}
				/>
				<FXSFilterBtnsWrapper>
					<FXSFilterBtnReset
						onClick={() => resetToDefault()}
						type='reset'
						value='Reset to default'
						data-ga4event-clk-name='insights_filter'
						data-ga4event-markets={''}
						data-ga4event-categories={''}
						data-ga4event-tags={''}
					/>
					<FXSFilterBtnApply
						onClick={() => applyFilter()}
						type='submit'
						value='Apply'
						data-ga4event-clk-name='insights_filter'
						data-ga4event-markets={
							state?.markets?.length > 0 && !activeTrades
								? state.markets.sort()
								: ''
						}
						data-ga4event-categories={
							state?.categories?.length > 0
								? !activeTrades
									? state.categories.sort()
									: EditorialCategoryType.Trade
								: ''
						}
						data-ga4event-tags={
							tagNames?.length > 0 && !activeTrades
								? tagNames.sort()
								: ''
						}
					/>
				</FXSFilterBtnsWrapper>
			</FXSFIlterBodyWrapper>
		);
	};

	return isLessThan833 ? (
		<FXSFilterMobileBg show={show} onClick={() => setShow(false)}>
			<FXSFilterMobileWrapper>
				<FXSFilterMobileHeader>
					<FXSFilterMobileHeaderTitle>
						{t('filterInsights')}
					</FXSFilterMobileHeaderTitle>
					<FXSFilterMobileHeaderCloseIcon
						icon={faTimes as IconProp}
						onClick={() => setShow(false)}
					/>
				</FXSFilterMobileHeader>
				<FilterModalBody />
			</FXSFilterMobileWrapper>
		</FXSFilterMobileBg>
	) : (
		show && (
			<FXSFilterWrapper ref={elementRef}>
				<FilterModalBody />
			</FXSFilterWrapper>
		)
	);
};

export default FilterModal;
