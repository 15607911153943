import React from 'react';

import { useTranslationsContext } from 'contexts/translationsContext';
import styled from 'styled-components';
import { TYPOGRAPHY } from 'styles/typography';
import { isLessThan } from 'utils/useWindowWidth';

const SEmptyBookmarks = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 2.5rem;
	@media screen and (min-width: 833px) {
		padding-top: 3.75rem;
	}
`;
const SEmptyBookmarkImg = styled.img`
	width: 174px;
	height: 174px;
	margin-bottom: 1.5rem;
`;
const SEmptyBookmarksTitle = styled.h3`
	width: 315px;
	margin: 0 0 3px 0;
	${TYPOGRAPHY.FXS_txt_large_bold};
	text-align: center;
	@media screen and (min-width: 833px) {
		margin: 0 0 6px 0;
	}
`;
const SEmptyBookmarkText = styled.p`
	width: 258px;
	margin: 0;
	${TYPOGRAPHY.FXS_txt_large};
	text-align: center;
	@media screen and (min-width: 833px) {
		width: 315px;
	}
`;

const SignalsEmptyBookmarks = () => {
	const isLessThan833 = isLessThan(833);
	const { t } = useTranslationsContext();

	return (
		<SEmptyBookmarks>
			<SEmptyBookmarkImg
				src={`${window.config.Variables.StaticContentBaseUrl}TradingStudio/${
					isLessThan833
						? 'emptyBookmarksMobile.svg'
						: 'emptyBookmarks.svg'
				}`}
			/>
			<SEmptyBookmarksTitle>
				{t('emptyBookmarkTitle')}
			</SEmptyBookmarksTitle>
			<SEmptyBookmarkText>
				{isLessThan833
					? t('emptyBookmarkTextMobile')
					: t('emptyBookmarkText')}
			</SEmptyBookmarkText>
		</SEmptyBookmarks>
	);
};

export default SignalsEmptyBookmarks;
