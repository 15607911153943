import * as styled from 'styled-components';
import { COLORS } from 'styles/colors';

export default styled.createGlobalStyle`
	.faq-button {
		position: relative;
		display: block;
		padding: 8px;
		height: 40px;
		border-radius: 4px;

		&:hover {
			background: ${COLORS.interaction_blue_light_2};
			text-decoration: none;

			svg {
				.icon {
					fill: ${COLORS.interaction_blue};
				}
			}
		}

		@media (max-width: 833px) {
			display: flex;
			gap: 8px;
			align-items: center;
			color: white;
			text-decoration: none;
			width: calc(100% - 24px);

			&:hover {
				color: white;
				background: none;

				svg {
					.icon {
						fill: white;
					}
				}
			}

			svg {
				.icon {
					fill: white;
				}
			}
		}
	}
`;
