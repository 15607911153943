import styled from 'styled-components';

export const FXSPageTitle = styled.div`
	padding: 1rem 1.25rem;
	@media (min-width: 833px) {
		padding: 0.5rem 0 1.25rem;
	}
	h3 {
		margin: 0;
	}
`;
export const FXSPageTitleResponsive = styled.div`
	display: none;
	@media (min-width: 833px) {
		display: block;
		padding: 0.5rem 0 1.25rem;
	}
	h3 {
		margin: 0;
	}
`;
