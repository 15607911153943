import { NavLink } from 'react-router-dom';

import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'styles/colors';
import { TYPOGRAPHY } from 'styles/typography';

export const SInsightsTab = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 0;
	background: ${COLORS.grey300};
`;
export const SInsightsTabWrapper = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	@media (min-width: 833px) {
		width: calc(95% - 6px);
	}
	&:before {
		content: '';
		width: 100%;
		background-color: #dddedf;
		height: 2px;
		position: absolute;
		bottom: 0;
	}
`;
export const SSingalsHeaderInner = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-around;
	@media (min-width: 833px) {
		justify-content: flex-start;
	}
`;

const breatheAnimation = keyframes`
 50% { opacity: 0; }
`;

const InsightsTabLink = css`
	position: relative;
	padding: 1rem 1rem 13px;
	background-color: transparent;
	border: none;
	border-bottom: 3px solid transparent;
	color: ${COLORS.grey};
	${TYPOGRAPHY.FXS_hd_small};
	text-align: center;
	text-decoration: none;
	transition: all 0.25s ease-in;
	&:hover {
		color: ${COLORS.highlight};
		text-decoration: none;
	}
	&.active {
		color: ${COLORS.highlight};
		border-bottom: 3px solid ${COLORS.highlight};
	}
	@media screen and (min-width: 833px) {
		min-width: 95px;
		width: max-content;
	}
`;
export const SInsightsTabLink = styled(NavLink)`
	${InsightsTabLink}
`;

export const SInsightsTabLinkLiveCoverage = styled(NavLink)`
	${InsightsTabLink}
	& > span {
		position: relative;
	}
	.tooltip {
		display: none;
	}
	&.disabled {
		color: ${COLORS.grey500};
		cursor: not-allowed;
		&:hover {
			.tooltip {
				position: absolute;
				top: -10px;
				right: 45px;
				display: block;
				width: 150px;
				padding: 6px;
				background-color: ${COLORS.grey};
				color: white;
				font-weight: 400;
				font-size: 11px;
				line-height: 12px;
				text-align: left;
				@media (min-width: 680px) {
					top: -8px;
				}
				@media (min-width: 833px) {
					top: -50px;
					left: unset;
					right: -17px;
				}
				&:after {
					content: '';
					position: absolute;
					top: 14px;
					right: -16px;
					width: 0;
					height: 0;
					border-left: 8px solid ${COLORS.grey};
					border-right: 8px solid transparent;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					@media (min-width: 833px) {
						top: 45px;
						right: 60px;
						border-left: 10px solid transparent;
						border-right: 10px solid transparent;
						border-bottom: 10px solid transparent;
						border-top: 10px solid ${COLORS.grey};
					}
				}
			}
		}
	}
	&.liveActive:not(.active) {
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -10px;
			width: 9px;
			height: 9px;
			background-color: ${COLORS.red_alternate};
			border-radius: 50%;
			animation: ${breatheAnimation} 3s infinite;
		}
	}
`;
