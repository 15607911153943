const removeCookie = function (name, domain) {
	document.cookie =
		name +
		'=;path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;max-age=0;' +
		(domain ? 'domain=' + domain + ';' : '');
};

export async function logout() {
	fetch(
		`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Logout}`,
		{
			method: 'GET'
		}
	)
		.then(() => {
			removeCookie(
				window.config.Variables.AuthenticationCookie,
				window.config.Variables.CookieDomain
			);
			window.location.reload();
		})
		.catch((err) => console.log(err));
}
