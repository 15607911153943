import React from 'react';

import { TimezoneApiResponse } from 'constants/timezones';

type ContextProps = {
	timeZones: TimezoneApiResponse;
};
export const initialState: TimezoneApiResponse = {
	TimeZones: [],
	SetAutomaticallyTimeZone: {
		TimeZoneId: null,
		TimeSpan: null,
		Location: null,
		CurrentTimeZone: false,
		HoursUtcOffset: null
	},
	CurrentTimeZone: {
		TimeZoneId: null,
		TimeSpan: null,
		Location: null,
		CurrentTimeZone: false,
		HoursUtcOffset: null
	},
	Translations: undefined,
	IsTwelveTimeFormat: false
};

export const TimeZoneContext = React.createContext<ContextProps>(null);
