/* eslint-disable no-prototype-builtins */
import React, { useEffect } from 'react';

import { NOTIFICATIONS } from 'constants/notifications';
import {
	NotificationsContext,
	NotificationsState,
	initialNotificationsState
} from 'contexts/notificationsContext';

export const NotificationsContextProvider: React.FC = ({ children }) => {
	const [notifications, setNotifications] = React.useState(
		initialNotificationsState
	);
	const localNotifications = JSON.parse(
		window.localStorage.getItem('notifications')
	);

	useEffect(() => {
		checkNotifications();
	}, []);

	const saveNotifications = (newState: NotificationsState) => {
		window.localStorage.setItem(NOTIFICATIONS, JSON.stringify(newState));
		setNotifications(newState);
	};

	const clearNotifications = () => {
		window.localStorage.setItem(
			NOTIFICATIONS,
			JSON.stringify(initialNotificationsState)
		);
		setNotifications(initialNotificationsState);
	};

	function checkNotifications(): void {
		if (
			localNotifications !== null &&
			Object.keys(localNotifications).length < 5
		) {
			if (!localNotifications.hasOwnProperty('signal'))
				initiliazeFilter('signal');
			if (!localNotifications.hasOwnProperty('economic'))
				initiliazeFilter('economic');
			if (!localNotifications.hasOwnProperty('analysis'))
				initiliazeFilter('analysis');
			if (!localNotifications.hasOwnProperty('community'))
				initiliazeFilter('community');
			if (!localNotifications.hasOwnProperty('special'))
				initiliazeFilter('special');
		}
		if (
			localNotifications !== null &&
			Object.keys(localNotifications) !== undefined
		) {
			setNotifications(localNotifications);
		} else {
			window.localStorage.setItem(
				'notifications',
				JSON.stringify(initialNotificationsState)
			);
			setNotifications(initialNotificationsState);
		}
	}

	function initiliazeFilter(type: string): void {
		localNotifications[type] = [];
		setNotifications(localNotifications);
		window.localStorage.setItem(
			'notifications',
			JSON.stringify(localNotifications)
		);
	}

	return (
		<NotificationsContext.Provider
			value={{ notifications, saveNotifications, clearNotifications }}
		>
			{children}
		</NotificationsContext.Provider>
	);
};
