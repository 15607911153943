import React, { useContext, useEffect, useState } from 'react';

import { FilterContext } from 'contexts/filtersContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'utils/useWindowWidth';

import FilterModal from '../FilterModal';
import FilterIcon from './icon';
import { SFilterMobileBtn, SFilterMobileWrapper } from './styles';

const FilterMobileBtn = () => {
	const { t } = useTranslationsContext();
	const { filters } = useContext(FilterContext);
	const [show, setShow] = useState(false);

	const isLessThan833 = isLessThan(833);

	useEffect(() => {
		return () => {
			setShow(false);
		};
	}, []);

	return (
		<React.Fragment>
			<SFilterMobileWrapper onClick={() => setShow(!show)}>
				<SFilterMobileBtn>
					{t('filterInsights')}
					<FilterIcon
						filters={filters}
						isLessThan833={isLessThan833}
					/>
				</SFilterMobileBtn>
			</SFilterMobileWrapper>
			<FilterModal show={show} setShow={setShow} translate={t} />
		</React.Fragment>
	);
};

export default FilterMobileBtn;
