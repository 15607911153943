import Routes from 'Routes';

import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import {
	BOOKMARKS,
	CALENDAR,
	POPULAR,
	RECORDINGS,
	SIGNALS,
	WEBINARS
} from 'constants/locations';
import { LIVE_MASTER_CLASS_QUERY } from 'constants/queryParams';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { getQueryParam } from 'utils/getQueryParam';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCalendar as faCalendarSolid,
	faChartLine as faChartLineFill,
	faPlayCircle as faPlayCircleSolid,
	faThLarge as faThLargeFill,
	faVideo as faVideoFill
} from '@fortawesome/free-solid-svg-icons';
import {
	faChartLine,
	faUsersClass,
	faVideo
} from '@fortawesome/pro-light-svg-icons';
import {
	faCalendar as faCalendarRegular,
	faPlayCircle as faPlayCircleRegular,
	faThLarge,
	faUsersClass as faUsersClassFill
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FXSNavLink, FXSNavLinkLiveMasterClass, FXSNavMenu } from './styles';

export default function NavMenu() {
	const { t } = useTranslationsContext();
	const { user } = useContext(UserContext);
	const { isPremium } = user;

	const location = useLocation();
	const splitLocation = location.pathname.split('/')[1].toLowerCase();

	const isDashboard =
		splitLocation === '' ||
		splitLocation === POPULAR ||
		splitLocation === BOOKMARKS;

	const isMasterClassNavLinkActive =
		location.pathname === Routes.MASTERCLASS || false;

	return (
		<FXSNavMenu>
			<FXSNavLink
				to={Routes.HOME}
				activeClassName={isDashboard ? 'fxs_active' : ''}
				data-ga4event-clk-name='sidebar_click'
				data-ga4event-element='dashboard'
			>
				<FontAwesomeIcon
					icon={
						isDashboard
							? (faThLargeFill as IconProp)
							: (faThLarge as IconProp)
					}
				/>
				<span>{t('dashboard')}</span>
			</FXSNavLink>
			<FXSNavLink
				exact
				to={Routes.SIGNALS}
				activeClassName='fxs_active'
				data-ga4event-clk-name='sidebar_click'
				data-ga4event-element='signals'
			>
				<FontAwesomeIcon
					icon={
						splitLocation === SIGNALS
							? (faChartLineFill as IconProp)
							: (faChartLine as IconProp)
					}
				/>
				<span>{t('signals')}</span>
			</FXSNavLink>
			<FXSNavLink
				exact
				to={Routes.CALENDAR}
				activeClassName='fxs_active'
				data-ga4event-clk-name='sidebar_click'
				data-ga4event-element='economic-calendar'
			>
				<FontAwesomeIcon
					icon={
						splitLocation === CALENDAR
							? (faCalendarSolid as IconProp)
							: (faCalendarRegular as IconProp)
					}
				/>
				<span>{t('Calendar')}</span>
			</FXSNavLink>
			<FXSNavLink
				exact
				to={Routes.WEBINARS}
				activeClassName='fxs_active'
				data-ga4event-clk-name='sidebar_click'
				data-ga4event-element='webinars'
			>
				<FontAwesomeIcon
					icon={
						splitLocation === WEBINARS
							? (faUsersClassFill as IconProp)
							: (faUsersClass as IconProp)
					}
				/>
				<span>{t('webinars')}</span>
			</FXSNavLink>
			<FXSNavLink
				exact
				to={Routes.RECORDINGS}
				activeClassName='fxs_active'
				data-ga4event-clk-name='sidebar_click'
				data-ga4event-element='recordings'
			>
				<FontAwesomeIcon
					icon={
						splitLocation === RECORDINGS
							? (faVideoFill as IconProp)
							: (faVideo as IconProp)
					}
				/>
				<span>{t('recordings')}</span>
			</FXSNavLink>
			{isPremium && isLiveMasterClass() && (
				<FXSNavLinkLiveMasterClass
					isLiveMasterclass={isLiveMasterClass()}
					exact
					to={Routes.MASTERCLASS}
					activeClassName='fxs_active'
					data-ga4event-clk-name='sidebar_click'
					data-ga4event-element='masterclass'
				>
					<FontAwesomeIcon
						icon={
							isMasterClassNavLinkActive
								? (faPlayCircleSolid as IconProp)
								: (faPlayCircleRegular as IconProp)
						}
					/>
					<span>{t('liveMasterclass')}</span>
				</FXSNavLinkLiveMasterClass>
			)}
		</FXSNavMenu>
	);
	function isLiveMasterClass() {
		let isActive = window['isLiveMasterclass'];
		if (getQueryParam(LIVE_MASTER_CLASS_QUERY))
			isActive = parseInt(getQueryParam(LIVE_MASTER_CLASS_QUERY));
		return isActive > 0;
	}
}
