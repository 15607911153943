import styled from 'styled-components';
import { COLORS } from 'styles/colors';

import {
	entryPlain_txt,
	entryPlain_txt_large,
	entryPlain_txt_small
} from './EntryPlain';
import {
	hd_highlight,
	hd_large,
	hd_med,
	hd_med_to_large,
	hd_small,
	hd_small_med,
	hd_tiny
} from './Headings';
import {
	txt_large,
	txt_large_bold,
	txt_nano,
	txt_nano_bold,
	txt_small,
	txt_small_bold,
	txt_xsmall,
	txt_xsmall_bold
} from './Txt';

export const TYPOGRAPHY = {
	FXS_hd_large: hd_large,
	FXS_hd_med_to_large: hd_med_to_large,
	FXS_hd_med: hd_med,
	FXS_hd_small_med: hd_small_med,
	FXS_hd_small: hd_small,
	FXS_hd_tiny: hd_tiny,
	FXS_hd_highlight: hd_highlight,

	FXS_txt_large: txt_large,
	FXS_txt_large_bold: txt_large_bold,
	FXS_txt_small: txt_small,
	FXS_txt_small_bold: txt_small_bold,
	FXS_txt_xsmall: txt_xsmall,
	FXS_txt_xsmall_bold: txt_xsmall_bold,
	FXS_txt_nano: txt_nano,
	FXS_txt_nano_bold: txt_nano_bold,

	FXS_entryPlain_txt_large: entryPlain_txt_large,
	FXS_entryPlain_txt: entryPlain_txt,
	FXS_extryPlain_txt_small: entryPlain_txt_small
};

export const FXS_hd_large = styled.h1`
	${hd_large};
`;
export const FXS_hd_med_to_large = styled.h2`
	${hd_med_to_large};
`;
export const FXS_hd_med = styled.h3`
	${hd_med};
`;
export const FXS_hd_med_padding_left = styled.h3`
	${hd_med};
	padding: 1rem;
	@media (min-width: 833px) {
		padding: 0 1.5rem;
	}
`;
export const FXS_hd_small_med = styled.h4`
	${hd_small_med};
`;
export const FXS_hd_small = styled.h5`
	${hd_small};
`;
export const FXS_hd_tiny = styled.h6`
	${hd_tiny};
`;
export const FXS_hd_highlight = styled.h3`
	${hd_highlight};
`;
export const FXS_Subtitle = styled.h3`
	color: ${COLORS.grey};
	margin-bottom: 1rem;
	border-bottom: 2px solid ${COLORS.grey400};
	font-weight: 700;
	font-style: italic;
	font-size: 1rem;
	letter-spacing: -0.01em;
	line-height: 1.1875rem;
	text-transform: uppercase;
`;
