import { getUser } from '../utils/getUser';

const fetcher = async (eventId: string): Promise<boolean> => {
	return getUser().then(async (user) => {
		const url = window.config.Endpoints.UserEventFollowsByPostId.replace(
			'{eventId}',
			eventId
		);
		url.replace('{email}', user.email);
		const res = await fetch(`${process.env.REACT_APP_GATEWAY_URL}${url}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `bearer ${user?.token}`
			}
		});
		try {
			if (res.ok) {
				return true;
			}
			return false;
		} catch (error) {
			return false;
		}
	});
};

export async function getEventFollow(eventId: string): Promise<boolean> {
	return fetcher(eventId);
}
