import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	LogLevel
} from '@microsoft/signalr';

import { PostPush } from '../models/postPush';

export class PostPushManager {
	serverUrl: string;
	culture: string;
	connection: HubConnection = null;

	constructor(serverUrl: string, culture: string) {
		this.serverUrl = serverUrl;
		this.culture = culture;
	}

	postSubscribe(callback: (post: PostPush) => void, channels: string[]) {
		let timeoutId = null;
		let retry = 5;
		let stoppedForTime = false;

		this.connection = new HubConnectionBuilder()
			.withUrl(`${this.serverUrl}/posthub`)
			.configureLogging(LogLevel.Information)
			.build();

		const self = this;
		function start() {
			if (self.connection.state === HubConnectionState.Disconnected) {
				self.connection
					.start()
					.then(() => {
						if (channels) {
							for (let i = 0; i < channels.length; i++) {
								self.connection.invoke(
									'subscribe',
									self.culture,
									channels[i]
								);
							}
						}
						if (timeoutId !== null) {
							window.clearTimeout(timeoutId);
						}
						retry = 5;
						stoppedForTime = false;

						timeoutId = window.setTimeout(
							() => {
								timeoutId = null;
								retry = 5;
								stoppedForTime = true;

								self.connection.stop();
							},
							480 * 60 * 1000
						);
					})
					.catch((err) => {
						console.log(err);

						retry--;
						if (retry > 0) {
							setTimeout(() => {
								start();
							}, Math.random() * 120000);
						}
					});
			}
		}

		this.connection.on('postCreated', (msg) => {
			callback(msg);
		});

		this.connection.onclose(() => {
			if (!stoppedForTime) start();
		});

		start();
	}

	postUnSubscribe() {
		this.connection.stop();
	}
}
