import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const StrategiesDisclamimer = styled.div`
  margin-bottom: 1rem;
  padding: 8px 1rem;
  border: 1px solid ${COLORS.grey200};
  overflow-y: visible;
  & p {
    margin-bottom: 0;
    color: ${COLORS.grey500}
    font-size: 11px;
  }
`;
