import { useState } from 'react';
import OneSignal from 'react-onesignal';

import {
	NOTIFICATIONS_CHANGED,
	ONESIGNAL_NOTIFICATIONS_LOCAL_KEY
} from 'constants/notificationPermissions';
import { NotificationsPermissionType } from 'models/enums';

export default async function initOneSignal(appId: string) {
	await OneSignal.init({
		appId: appId,
		allowLocalhostAsSecureOrigin: true
	});
}

export function useOneSignal(notifications): boolean {
	const [showNotificationModal, setShowNotificationModal] = useState(false);

	OneSignal.on(NOTIFICATIONS_CHANGED, function (permissionChange) {
		console.log(
			'The notifications has just changed, actual state: ',
			permissionChange.to
		);
		if (permissionChange.to === NotificationsPermissionType.granted) {
			OneSignal.setSubscription(true).then(() => {
				OneSignal.setExternalUserId(window.userInfo.id).then(() => {
					OneSignal.sendTags(notifications);
				});
				setShowNotificationModal(true);
			});
		} else if (permissionChange.to === NotificationsPermissionType.denied) {
			setShowNotificationModal(false);
			Object.entries(notifications).forEach(([key]) =>
				OneSignal.sendTag(key, false)
			);
		} else {
			setShowNotificationModal(false);
			OneSignal.registerForPushNotifications();
			Object.entries(notifications).forEach(([key]) =>
				OneSignal.sendTag(key, false)
			);
			localStorage.removeItem(ONESIGNAL_NOTIFICATIONS_LOCAL_KEY);
		}
	});
	return showNotificationModal;
}

export function isNotificationSupported() {
	const browserInfo = navigator.userAgent.toLowerCase();
	const browserNotificationApi = window.Notification;

	if (!browserNotificationApi) {
		return false;
	}

	if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
		return false;
	}
	if (
		[
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.platform)
	) {
		return false;
	}
	return true;
}
