import React, { useContext } from 'react';

import { useTranslationsContext } from 'contexts/translationsContext';
import { TranslateProps } from 'models/props';

import {
	DiscordBtnLinkDesktop,
} from './styles';
import { getDiscordUrlUtils } from 'utils/discord';
import { UserContext } from 'contexts/userContext';
import IconDiscord from 'components/icons/IconDiscord';

type ButtonProps = {
	translate?: (key: string) => string;
} & TranslateProps;

const DiscordContainer = ({ children }) => {
	return <div id='discord-btn-container'>{children}</div>;
};

const DiscordBtn: React.FC<ButtonProps> = () => {
	const { t } = useTranslationsContext();
	const { user } = useContext(UserContext);

	return (
		<DiscordContainer>
			<DiscordBtnLinkDesktop
				href={getDiscordUrlUtils(user.emailEncrypted)}
				target='_blank'
				rel='noopener noreferrer'
			>
				<IconDiscord />
				{t('joinDiscord')}
			</DiscordBtnLinkDesktop>
		</DiscordContainer>
	);
};

export default DiscordBtn;
