export default function IconAngleDown() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			className='IconAngleDown'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M11.6836 15.4844C11.8594 15.6602 12.1055 15.6602 12.2812 15.4844L17.4844 10.3164C17.6602 10.1758 17.6602 9.89453 17.4844 9.71875L16.7812 9.05078C16.6406 8.875 16.3594 8.875 16.1836 9.05078L12 13.1992L7.78125 9.05078C7.60547 8.875 7.35938 8.875 7.18359 9.05078L6.48047 9.71875C6.30469 9.89453 6.30469 10.1758 6.48047 10.3164L11.6836 15.4844Z' />
		</svg>
	);
}
