import { TradePlan } from 'models/tradePlan';
import { TradePlanPush } from 'models/tradePlanPush';

export const mapFromPush = (source: TradePlanPush): TradePlan => ({
	dateUtc: source.DateUtc,
	entryPrice: source.EntryPrice,
	id: source.Id,
	side: source.Side,
	stopLoss: source.StopLoss,
	symbol: source.Symbol,
	takeProfit: source.TakeProfit,
	queryLink: source.InsightsQuery,
	state: source.State
});
