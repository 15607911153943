import styled from 'styled-components';
import { FXSBtnCta } from 'styles/button';
import { COLORS } from 'styles/colors';
import { TYPOGRAPHY } from 'styles/typography';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type FilterProps = {
	checked: boolean;
	disabled?: boolean;
};

type ActiveTradesProps = {
	activeTrade: boolean;
};

type FiltersTitlePros = {
	isLessThan833: boolean;
};

/************************
  MOBILE
************************/
export const FXSFilterMobileBg = styled.div<{ show?: boolean }>`
	position: fixed;
	top: ${({ show }) => (show ? '55px' : '100%')};
	z-index: 1001;
	height: 100%;
	width: 100vw;
	padding: 1rem 1rem 70px;
	background-color: rgba(0, 0, 0, 0.5);
	transition: all 0.6s ease-in-out;
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
export const FXSFilterMobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	height: 100%;
	max-width: 336px;
	margin: auto;
	background-color: ${COLORS.white};
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	overflow-y: scroll;
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
	@media screen and (max-width: 833px) and (min-height: 835px) {
		height: max-content;
	}
`;
export const FXSFilterMobileHeader = styled.div`
	position: sticky;
	top: 0px;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 57px;
	padding: 1rem;
	padding-right: 1.25rem;
	background: ${COLORS.grey200};
`;
export const FXSFilterMobileHeaderTitle = styled.h2`
	${TYPOGRAPHY.FXS_hd_med};
	margin: 0;
`;
export const FXSFilterMobileHeaderCloseIcon = styled(FontAwesomeIcon)`
	color: ${COLORS.dark};
	cursor: pointer;
`;
/************************
  DESKTOP WRAPPER
************************/
export const FXSFilterWrapper = styled.div`
	position: absolute;
	top: 65px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1002;
	height: calc(100% - 75px);
	width: calc(100vw - 20px);
	max-width: 336px;
	background: ${COLORS.white};
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	@media screen and (min-width: 833px) {
		top: 40px;
		left: unset;
		right: 0;
		transform: translateX(0);
		height: auto;
		max-height: calc(100vh - 150px);
		width: 262px;
		overflow-y: auto;
		-ms-overflow-style: none;
		scrollbar-width: none;
		&::-webkit-scrollbar {
			display: none;
		}
	}
`;

export const FXSFIlterBodyWrapper = styled.div<ActiveTradesProps>`
	cursor: ${({ activeTrade }) => activeTrade && 'not-allowed'};
`;
/************************
  ROW AND TITLE
************************/
export const FXSFilterModalRow = styled.div<
	{ isLast?: boolean } & ActiveTradesProps
>`
	border: none;
	border-bottom: 1px solid;
	border-bottom-color: ${({ isLast }) =>
		isLast ? 'transparent' : `${COLORS.grey300}`};
	padding-bottom: ${({ isLast }) => (isLast ? '40px' : '0')};
	opacity: ${({ activeTrade }) => (activeTrade ? '.5' : '1')};
	pointer-events: ${({ activeTrade }) => activeTrade && 'none'};
`;
export const FXSFilterTitle = styled.h2<FiltersTitlePros>`
	padding-left: 1rem;
	margin: 18px 0;
	color: ${COLORS.grey};
	${({ isLessThan833 }) =>
		isLessThan833
			? `${TYPOGRAPHY.FXS_hd_small}`
			: `${TYPOGRAPHY.FXS_hd_tiny}`};
	text-align: left;
	@media screen and (min-width: 833px) {
		margin: 1rem 0;
	}
`;
/************************
  TRADES
************************/
export const FXSFilterTradesWrapper = styled.div`
	margin-top: 2px;
	border-bottom: 1px solid ${COLORS.grey300};
	cursor: pointer;
	@media screen and (min-width: 833px) {
		margin-top: 0;
	}
`;
export const FXSFilterTradesInner = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 30px 1fr;
	align-items: flex-start;
	margin-top: 2px;
	padding: 1rem 1.5rem;
	background-color: ${COLORS.grey100};
	border-radius: 3px;
	@media screen and (min-width: 833px) {
		grid-template-columns: 30px auto auto;
		align-items: center;
		margin: 10px 1rem;
		padding: 13px;
	}
`;
// SWITCH
export const FXSFilterTradesSwitchWrapper = styled.div<ActiveTradesProps>`
	position: relative;
	height: 16px;
	width: 30px;
	background-color: ${({ activeTrade }) =>
		activeTrade ? `${COLORS.ui_blue}` : ` ${COLORS.grey500}`};
	border-radius: 9px;
	cursor: pointer;
`;
export const FXSFilterTradesSwitchCircle = styled.div<ActiveTradesProps>`
	position: absolute;
	top: 1px;
	left: ${({ activeTrade }) => (activeTrade ? 'unset' : '1px')};
	right: ${({ activeTrade }) => (activeTrade ? '1px' : 'unset')};
	height: 14px;
	width: 14px;
	background: ${COLORS.white};
	border-radius: 50%;
`;
export const FXSFilterTradesInnerText = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 1rem;
`;
export const FXSFilterTradesLabel = styled.h2<FiltersTitlePros>`
	margin: 0;
	color: ${COLORS.grey};
	${({ isLessThan833 }) =>
		isLessThan833
			? `${TYPOGRAPHY.FXS_hd_small}`
			: `${TYPOGRAPHY.FXS_hd_tiny}`};
	@media screen and (min-width: 833px) {
		margin: 0 0.5rem;
	}
`;
export const FXSFilterTradesSmall = styled.small`
	color: ${COLORS.grey};
	font-size: 11px;
	line-height: 14px;
`;
export const FXSFilterTradesIcon = styled(FontAwesomeIcon)`
	height: 13px;
	width: 13px;
	color: ${COLORS.dark};
	cursor: pointer;
`;
// TOOLTIP
export const FXSFilterTradesTooltip = styled.div<{ show: boolean }>`
	position: absolute;
	bottom: -90px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
	display: ${({ show }) => (show ? 'block' : 'none')};
	width: 250px;
	padding: 9px 13px 14px;
	background: ${COLORS.grey};
	color: ${COLORS.white};
`;
export const FXSFilterTradesTooltipText = styled.p`
	margin: 0;
	font-weight: 400;
	font-size: 14.75px;
	line-height: 20px;
	text-align: left;
`;
export const FXSFilterTradeTooltipTriangle = styled.div`
	position: absolute;
	top: -5px;
	right: 35px;
	border-left: 13px solid ${COLORS.grey};
	border-bottom: 13px solid transparent;
	transform: rotate(45deg);
`;
/************************
  MARKETS
************************/
export const FXSFilterMarketWrapper = styled.div`
	display: grid;
	grid-template-columns: 130px 1fr;
	gap: 1rem 0;
	max-width: 100%;
	padding: 0 1rem 1.5rem;
	@media screen and (min-width: 833px) {
		grid-template-columns: 100px 1fr;
	}
`;
export const FXSFilterMarketBtn = styled.div<FilterProps>`
	display: flex;
	align-items: center;
	max-width: max-content;
	padding: 0 12px;
	background: ${({ checked }) =>
		checked ? `${COLORS.interaction_blue}` : `${COLORS.grey100}`};
	border: 1.5px solid transparent;
	border-radius: 20px;
	color: ${({ checked }) => (checked ? `${COLORS.white}` : `${COLORS.grey}`)};
	font-weight: ${({ checked }) => (checked ? '700' : '400')};
	font-size: 14.72px;
	line-height: 29px;
	text-transform: capitalize;
	cursor: ${({ disabled }) => disabled && 'not-allowed'};
	transition: all 0.25s ease-in-out;
	&:hover {
		background: ${COLORS.interaction_blue_light_2};
		border: 1.5px solid ${COLORS.interaction_blue};
		color: ${COLORS.interaction_blue};
	}
	.svg-inline--fa.fa-check.fa-w-16 {
		margin-right: 2px;
	}
`;
/************************
  CATEGORIES
************************/
export const FXSFilterCategoryRow = styled.div<Omit<FilterProps, 'checked'>>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1.5rem 0 2rem;
	margin-bottom: 14px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
export const FXSFilterCategoryLeft = styled.div`
	display: flex;
	align-items: center;
`;
export const FXSFilterCategoryIcon = styled(FontAwesomeIcon)`
	color: ${COLORS.grey600};
`;
export const FXSFilterCategoryLabel = styled.p`
	margin: auto 0 auto 12px;
	font-weight: 400;
	font-size: 1rem;
	line-height: 20px;
	@media screen and (min-width: 833px) {
		font-size: 14.72px;
	}
`;
export const FXSFilterCheckbox = styled(FontAwesomeIcon)<FilterProps>`
	color: ${({ checked }) =>
		checked ? `${COLORS.ui_blue}` : ` ${COLORS.grey}`};
	font-size: 19px;
	cursor: ${({ disabled }) => disabled && 'not-allowed'};
`;
/************************
  TOPICS
************************/
export const FXSSearchInputWrapper = styled.div`
	position: relative;
	display: grid;
	place-items: center;
`;
export const FXSSearchIcon = styled(FontAwesomeIcon)`
	position: absolute;
	top: 50%;
	left: 28px;
	transform: translateY(-50%);
	color: ${COLORS.grey};
	@media screen and (min-width: 833px) {
		left: 21px;
	}
`;
export const FXSSearchInput = styled.input`
	height: 35px;
	width: 301px;
	padding-left: 35px !important;
	font-size: 14.72px !important;
	line-height: 20px;
	&:focus {
		border-color: ${COLORS.grey} !important;
	}
	@media screen and (min-width: 833px) {
		height: 28px !important;
		width: 232px;
		padding-left: 28px !important;
	}
`;
export const FXSFilterTagWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 0.5rem;
	padding: 0 0.8125rem;
`;
export const FXSFilterTag = styled.div<FilterProps>`
	padding: 5px 7px;
	background: ${({ checked }) =>
		checked ? `${COLORS.interaction_blue_light_2}` : 'transparent'};
	border: none;
	border-radius: 6px;
	color: ${({ checked }) =>
		checked ? `${COLORS.interaction_blue}` : `${COLORS.grey600}`};
	font-weight: 700;
	font-size: 12.8px;
	line-height: 17px;
	cursor: ${({ disabled }) => disabled && 'not-allowed'};
	&:hover {
		color: ${COLORS.interaction_blue};
	}
`;
/************************
  BUTTONS
************************/
export const FXSFilterBtnsWrapper = styled.div`
	position: sticky;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.125rem;
	height: 77px;
	width: 100%;
	background: ${COLORS.white};
	&:before {
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		height: 40px;
		width: 100%;
		background: linear-gradient(
			to top,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
`;
export const FXSFilterBtnReset = styled.input`
	display: grid;
	place-items: center;
	height: 33px;
	margin-right: 32px;
	background: ${COLORS.white};
	color: ${COLORS.highlight};
	font-weight: 700;
	font-size: 12px;
	line-height: 17px;
	cursor: pointer;
	transition: all 0.25s ease-in;
	&:hover {
		color: ${COLORS.dark};
	}
`;
export const FXSFilterBtnApply = styled.input`
	${FXSBtnCta};
	height: 33px;
	width: 155px;
	font-size: 12.8px;
	@media screen and (min-width: 833px) {
		width: 100px;
	}
`;
