import { css } from 'styled-components';

const entryPlain_txt_large = css`
	font-weight: 300;
	font-size: 1rem;
	line-height: 1.35rem;
	@media screen and (min-width: 680px) {
		font-size: 1.2rem;
		line-height: 1.4rem;
	}
`;
const entryPlain_txt = css`
	font-weight: 300;
	font-size: 0.9187rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 1rem;
		line-height: 1.35rem;
	}
`;
const entryPlain_txt_small = css`
	font-weight: 300;
	font-size: 0.8rem;
	line-height: 1.0625rem;
	@media screen and (min-width: 680px) {
		font-size: 0.9187rem;
		line-height: 1.2437rem;
	}
`;

export { entryPlain_txt, entryPlain_txt_large, entryPlain_txt_small };
