import { useContext, useEffect, useState } from 'react';

import { ConfigContext } from 'contexts/configContext';
import { TimeZoneContext, initialState } from 'contexts/timeZoneContext';
import { getTimeZones } from 'data/getTimeZones';

export const TimeZoneContextProvider = ({ children }) => {
	const [timeZones, setTimeZones] = useState(initialState);
	const { config } = useContext(ConfigContext);

	const url = config.Variables.FxsSite;

	useEffect(() => {
		getTimeZones(url).then((timeZoneApiResponse) => {
			setTimeZones({ ...timeZoneApiResponse });
		});
	}, []);

	return (
		<TimeZoneContext.Provider value={{ timeZones }}>
			{children}
		</TimeZoneContext.Provider>
	);
};
