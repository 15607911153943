import { createContext, useContext, useState } from 'react';

import Spinner from 'components/Spinner';

type SplashScreenContextType = {
	isLoading: boolean;
	setIsLoading: (isLoading: boolean) => void;
};

const SplashScreenContext = createContext<SplashScreenContextType>({
	isLoading: true,
	setIsLoading: () => {}
});

export const SplashScreenProvider = ({ children }) => {
	const [isLoading, setIsLoading] = useState(true);
	return (
		<SplashScreenContext.Provider value={{ isLoading, setIsLoading }}>
			{isLoading && <Spinner />}
			{children}
		</SplashScreenContext.Provider>
	);
};

export const useSplashScreenContext = () => {
	return useContext(SplashScreenContext);
};
