import React, { useContext } from 'react';

import { ConfigContext } from '../../contexts/configContext';
import { useTranslationsContext } from '../../contexts/translationsContext';
import {
	Iframe,
	MarketBiasDescription,
	MarketBiasSheet,
	MarketBiasTitle,
	TitleContainer
} from './styles';

export function EmbededMarketBias() {
	const { t } = useTranslationsContext();
	const { config } = useContext(ConfigContext);

	return (
		<MarketBiasSheet>
			<TitleContainer>
				<MarketBiasTitle className='fxs_headline_small fxs_txt_dark_4_clr'>
					{t('marketBiasTitle')}
				</MarketBiasTitle>
				<MarketBiasDescription className='fxs_txt_size_xsmall fxs_txt_light_1_clr'>
					{t('marketBiasDescription')}
				</MarketBiasDescription>
			</TitleContainer>
			<Iframe src={config.MarketBias.GoogleExcelURL} />
		</MarketBiasSheet>
	);
}
