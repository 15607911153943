import React, { useState } from 'react';

import { StrategyFullStatsContext } from '../contexts/strategyFullStatsContext';
import { getStrategyFullStats } from '../data/getStrategiesStats';
import { SignalStrategyStatsResponse } from '../models/strategy';

export const StrategyFullStatsContextProvider = ({ children }) => {
	const [strategyFullStats, setStrategyFullStats] = useState(
		null as SignalStrategyStatsResponse
	);
	const [alreadyCalled, setAlreadyCalled] = useState(false);

	if (!alreadyCalled) {
		setAlreadyCalled(true);
		getStrategyFullStats().then((tradesData) => {
			setStrategyFullStats(tradesData);
		});
	}

	return (
		<StrategyFullStatsContext.Provider value={strategyFullStats}>
			{children}
		</StrategyFullStatsContext.Provider>
	);
};
