const fetcher = async () => {
	return await fetch(
		`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Localization.replace('{culture}', 'en-US')}?applicationId=${window.config.Variables.LocalizationApplicationId}`,
		{
			method: 'GET',
			headers: { Accept: 'application/json' }
		}
	).then(async (res) => {
		const data = await res.json();
		return dataMapper(data);
	});
};

const dataMapper = (data: ApiResult) => {
	const scores = {};
	data.Values.map((obj: LocalizationRecord) => {
		scores[obj.Key] = obj.Value;
	});

	return scores;
};

export default async function getLocalizationData(): Promise<
	Record<string, string>
	> {
	return await fetcher();
}

export interface ApiResult {
	Values: Array<LocalizationRecord>;
}

export type LocalizationRecord = {
	LanguageId: string;
	ApplicationId: string;
	ResourceType: string;
	Key: string;
	Value: string;
	InvariantValue: string;
	Description: string;
};
