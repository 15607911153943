export const mapNonFXTags = (tags: string) => {
	const result = [tags.toLowerCase()];
	const NonFXAssets = window.config.Variables.NonFXAssets;

	NonFXAssets.forEach((x) => {
		Object.keys(x).forEach((key) => {
			if (key === tags && x[key] !== tags) {
				result.push(x[key].toLowerCase());
			}
		});
	});
	return result;
};
