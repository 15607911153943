import { MESSAGES_PER_REQUEST } from '../constants/signalMessages';
import { FilterState } from '../contexts/filtersContext';
import { mapSignalsMessages } from '../mappers/signalMessages';
import { EditorialCategoryType } from '../models/enums';
import {
	Message,
	MessageOlderPayload,
	MessageResponse
} from '../models/signalMessages';
import { getUser } from '../utils/getUser';

const fetcher = async (
	latestReadMessage: string,
	offset: number,
	filters?: FilterState
): Promise<MessageOlderPayload> => {
	return getUser().then(async (user) => {
		const apiEndpointUrl = `${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalMessages}`;
		try {
			let resourceUrl = `limit=${MESSAGES_PER_REQUEST}&offset=${offset}`;

			if (latestReadMessage)
				resourceUrl += `&dateTime=${latestReadMessage}`;
			if (filters.categories.includes(EditorialCategoryType.Trade)) {
				resourceUrl += '&categories=trade';
			} else {
				if (filters.categories?.length > 0)
					filters.categories.map(
						(filter) => (resourceUrl += `&categories=${filter}`)
					);
				if (filters.markets?.length > 0)
					filters.markets.map(
						(filter) => (resourceUrl += `&markets=${filter}`)
					);
				if (filters.tags?.length > 0)
					filters.tags.map(
						(filter) => (resourceUrl += `&tags=${filter}`)
					);
			}
			const response = await fetch(`${apiEndpointUrl}?${resourceUrl}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			});

			if (response.ok) {
				const mappedMessages = (await response.json())?.map(
					(x: MessageResponse) => mapSignalsMessages(x, false)
				) as Message[];

				const nextOffset = offset + MESSAGES_PER_REQUEST;

				return {
					data: mappedMessages,
					nextOffset
				} as MessageOlderPayload;
			}
			return { data: [], nextOffset: null } as MessageOlderPayload;
		} catch (error) {
			console.log(error);
			return null;
		}
	});
};

export async function loadOlderMessages(
	latestReadMessage: string,
	offset: number,
	filters?: FilterState
): Promise<MessageOlderPayload> {
	return fetcher(latestReadMessage, offset, filters);
}
