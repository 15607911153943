import React, { useEffect, useState } from 'react';

import IconPreferences from 'components/icons/IconPreferences';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { NotificationsPermissionType } from 'models/enums';
import { NotificationProps } from 'models/props';
import { isNotificationSupported, useOneSignal } from 'utils/oneSignalManager';
import { resetOneSignalPrompt } from 'utils/resetOneSignalPrompt';
import { HeaderButton, HeaderTooltip } from 'components/Header/HeaderBtn/styles';

const NotificationsBtn: React.FC<Partial<NotificationProps>> = ({
	setIsNotificationsModalOpened
}) => {
	const { t } = useTranslationsContext();
	const localNotifications = JSON.parse(
		window.localStorage.getItem('notifications')
	);
	const showOneSignalPrompt = useOneSignal(localNotifications);
	const isLessThan833 = isLessThan(833);

	const [showTooltip, setShowTooltip] = useState(false);

	useEffect(() => {}, [showOneSignalPrompt]);

	return (
		isNotificationSupported() && (
			<HeaderButton
				type='button'
				onClick={() => {
					!showOneSignalPrompt &&
					Notification.permission ===
						NotificationsPermissionType.default
						? resetOneSignalPrompt()
						: setIsNotificationsModalOpened(true);
				}}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
			>
				<IconPreferences />
				{isLessThan833 && <span>{t('notificationPreferences')}</span>}
				{showTooltip && !isLessThan833 && (
					<HeaderTooltip>
						<span>{t('NotificationsPref')}</span>
					</HeaderTooltip>
				)}
			</HeaderButton>
		)
	);
};

export default NotificationsBtn;
