export enum SideType {
	Buy = 'BUY',
	Sell = 'SELL'
}

export enum SidePushType {
	Buy = 0,
	Sell = 1
}

export enum LimitType {
	None = 0,
	Static = 1,
	Dynamic = 2
}

export enum TradeActionType {
	Opened = 0,
	Closed = 1,
	Updated = 2
}

export enum EditorialCategoryType {
	Analysis = 'analysis',
	Education = 'education',
	Warning = 'warning',
	Review = 'review',
	Trade = 'trade'
}

export enum EditorialMarketType {
	Forex = 'forex',
	Cryptos = 'cryptos',
	Stocks = 'stocks',
	Commodities = 'commodities'
}

export enum MessageContentType {
	Editorial = 'editorial',
	Trade = 'trade',
	Welcome = 'welcome'
}

export enum EditorialCategoryPushType {
	Analysis = 0,
	Education = 1,
	Warning = 2,
	Review = 3
}

export enum StatusType {
	Running = 'running',
	Win = 'win',
	Loss = 'loss'
}

export enum CrudMethod {
	Create = 'C',
	Update = 'U',
	Delete = 'D'
}

export enum InsightsType {
	latest = '',
	popular = 'popular',
	bookmarks = 'bookmarks',
	liveCoverage = 'live'
}

export enum RelatedPostContentType {
	Analysis = 'analysis',
	News = 'news',
	Videos = 'videos'
}

export enum NotificationsPermissionType {
	granted = 'granted',
	denied = 'denied',
	default = 'default'
}
