import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const RecordingsHeader = styled.h3`
	margin-bottom: 1rem;
	border-bottom: 1px solid ${COLORS.highlight};
	font-size: 1.1rem;
	line-height: 1.3rem;
	@media (min-width: 680px) {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}
`;
