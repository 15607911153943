import { useEffect, useState } from 'react';

import { ConnectedHubs } from '../contexts/pushContext';
import { mapFromPush } from '../mappers/trades';
import { TradeMessagePush } from '../models/signalPushMessages';
import { Trade } from '../models/trades';
import { SignalPushManager } from '../utils/signalSubscribe';

let signalManager: SignalPushManager;

const HUB_NAME = 'signals_trade';
const subscribeTrades = (setNewTrade: (trade: Trade) => void) => {
	try {
		signalManager =
			signalManager ||
			new SignalPushManager(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Push}`
			);
		signalManager.signalSubscribe((signal: TradeMessagePush) => {
			const signalMapped = mapFromPush(signal);
			setNewTrade(signalMapped);
		});

		return true;
	} catch (err) {
		return false;
	}
};

export function useSignalPush(
	connected: ConnectedHubs,
	notificationsEnabled: boolean
): Trade {
	const [newTrade, setNewTrade] = useState(null as Trade);
	if (notificationsEnabled && !connected.hasHub(HUB_NAME)) {
		const subscribed = subscribeTrades(setNewTrade);
		if (subscribed) connected.addHub(HUB_NAME);
	}
	useEffect(() => {
		return () => {
			if (signalManager) signalManager.signalUnSubscribe();
			connected.removeHub(HUB_NAME);
		};
	}, []);

	return newTrade;
}
