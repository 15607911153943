import { useEffect, useState } from 'react';

import { ConnectedHubs } from '../contexts/pushContext';
import { PostPush } from '../models/postPush';
import { PostPushManager } from '../utils/postSubscribe';

let postManager: PostPushManager;
const subscribePosts = (
	channel: string,
	setLatestPost: (postId: string) => void
): boolean => {
	try {
		postManager = new PostPushManager(
			`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Push}`,
			window.config.Variables.Culture
		);
		postManager.postSubscribe(
			(post: PostPush) => {
				console.log(`post received:${post.Id}`);
				setTimeout(() => {
					setLatestPost(post.Id);
				}, 5000);
			},
			[channel]
		);

		return true;
	} catch (err) {
		return false;
	}
};

export function usePostPush(
	connected: ConnectedHubs,
	notificationsEnabled: boolean,
	channel: string
): string {
	const [latestPost, setLatestPost] = useState<string>(null);
	const hubName = `post-${channel}`;

	if (notificationsEnabled && !connected.hasHub(hubName)) {
		const subscribed = subscribePosts(channel, setLatestPost);
		if (subscribed) connected.addHub(hubName);
	}
	useEffect(() => {
		return () => {
			if (postManager) postManager.postUnSubscribe();
			connected.removeHub(hubName);
		};
	}, []);

	return latestPost;
}
