import { AuthorResponse } from '../models/author';

export async function getAuthors(): Promise<AuthorResponse> {
	try {
		const authors = [
			{
				name: 'Sam Seiden',
				imgUrl: 'https://editorial.azureedge.net/authors/Sam%20Seiden.JPG',
				profileUrl: '/author/sam-seiden',
				description: 'Support and resistance trading'
			},
			{
				name: 'Ed Ponsi',
				imgUrl: 'https://editorial.azureedge.net/authors/Ed%20Ponsi.jpg',
				profileUrl: '/author/ed-ponsi',
				description: 'Technical and fundamental'
			},
			{
				name: 'Sam Evans',
				imgUrl: 'https://editorial.fxstreet.com/authors/sam-evans75x75_XtraSmall.png',
				profileUrl: '/author/sam-evans',
				description: 'Simplicity over indicators and strategies'
			},
			{
				name: 'Navin Prithyani',
				imgUrl: 'https://editorial.fxstreet.com/authors/Navin7575_XtraSmall.png',
				profileUrl: '/author/navin-prithyani',
				description: 'Price Action, technical, swing'
			},
			{
				name: 'Kaia Parv',
				imgUrl: 'https://editorial.fxstreet.com/authors/Kaia_Parv75x75-637375033215851026_XtraSmall.jpg',
				profileUrl: '/author/kaia-parv',
				description: 'Fundamental, technical'
			},
			{
				name: 'David Pegler',
				imgUrl: 'https://editorial.azureedge.net/authors/David%20Pegler.jpg',
				profileUrl: '/author/david-pegler',
				description: 'Multiple time-frames, price action'
			},
			{
				name: 'Walter Peters',
				imgUrl: 'https://editorial.fxstreet.com/authors/Walter%20Peters_XtraSmall.JPG',
				profileUrl: '/author/walter-peterst',
				description: 'Indicator-free trading systems'
			},
			{
				name: 'Sarid Harper',
				imgUrl: 'https://editorial.azureedge.net/authors/Sarid%20Harper%20(75x75)_XtraSmall.jpg',
				profileUrl: '/author/sarid-harper',
				description: 'Supply and demand'
			}
		];
		return {
			data: authors,
			error: false
		};
	} catch (error) {
		return { error: true } as AuthorResponse;
	}
}
