export const COLORS = {
	// PRIMARY
	// Brand
	corp: '#242d38',
	blue: '#304c70',
	blue_highlight_light_3: '#7598c4',
	blue_brand_highlight_light_4: '#99b2de',
	blue_highlight_light_6: '#e0e8f2',
	ui_blue: '#2a77f6',
	// Grayscale
	white: '#ffffff',
	grey100: '#f0f0f1',
	grey200: '#f3f3f8',
	grey300: '#ececf1',
	grey400: '#dddedf',
	grey500: '#a3a3a8',
	grey600: '#8c8d91',
	grey: '#49494f',
	dark: '#1b1c23',
	// Highlight
	orange_highlight_dark_3: '#5b360b',
	orange_highlight_dark_2: '#895110',
	orange_highlight_dark_1: '#b66c16',
	highlight: '#e4871b',
	orange_highlight_light_1: '#e99f49',
	orange_highlight_light_2: '#efb776',
	orange_highlight_light_3: '#f4cfa4',
	orange_highlight_light_4: '#fae7d1',
	//FEEDBACK
	// Error
	error: '#d25746',
	red_error_light_2: '#e6a298',
	red_error_light_4: '#faecea',
	// Success
	green_success_dark_1: '#255f53',
	success: '#338473',
	green_success_light_2: '#5dc0ab',
	green_success_light_5: '#cbebe4',
	green_success_light_6: '#f0f9f7',
	// Warning
	warning_dark_1: '#b38419',
	warning: '#f5c861',
	warning_light_5: '#faeab0',
	warning_light_6: '#fef9ec',
	// Info
	blue_info_dark_2: '#3a5563',
	blue_info_dark_1: '#4c6f8f',
	info: '#6289ac',
	blue_info_light_2: '#a5bbcf',
	blue_info_light_3: '#c6d4e1',
	// SECONDARY
	// Interation blue
	interaction_blue: '#1c73d6',
	interaction_blue_light_1: '#74c0fc',
	interaction_blue_light_2: '#eff6ff',
	// Red alternative
	red_alternate_dark_1: '#9c0412',
	red_alternate: '#ce0518',
	red_alternate_light_2: '#fa3f50',
	red_alternate_light_4: '#fda2ab',
	red_alternate_light_5: '#fed4d8',
	// Turquoise
	turquoise_highlight_dark_1: '#098996',
	turquoise_highlight_icon: '#0cb5c6',
	turquoise_highlight_light_1: '#05daea',
	turquoise_highlight_light_3: '#74ebf7',
	turquoise_highlight_light_4: '#86f7fc',
	turquoise_highlight_light_6: '#e9feff',
	// EXTERNAL
	// Discord
	discord_primary: '#5865f2',
	discord_dark: '#252d38'
};
