import { useContext } from 'react';

import PreSignalLatest from 'components/Insights/Pre-signal-latest';
import {
	FXSLeftColLayout,
	FXSRightColLayout,
	TwoColsMainLayout
} from 'components/Layouts';
import UserNoPremium from 'components/UserNoPremium';
import SignalsMini from 'components/signalsMini';
import { PRE_SIGNAL_QUERY } from 'constants/queryParams';
import { ConfigContext } from 'contexts/configContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { EventList } from 'fxs-event-list';
import { isLessThan } from 'fxs-utils';
import { PremiumProps } from 'models/props';
import { FXS_Subtitle, FXS_hd_med_padding_left } from 'styles/typography';
import { getQueryParam } from 'utils/getQueryParam';

export default function PreSignalInfo({ isPremium }: PremiumProps) {
	const { t } = useTranslationsContext();
	const isLessThan1280 = isLessThan(1280);
	const isLessThan833 = isLessThan(833);
	const { config } = useContext(ConfigContext);

	const preOrderTitle = getQueryParam(PRE_SIGNAL_QUERY);

	return (
		<TwoColsMainLayout>
			{!isPremium && <UserNoPremium />}
			<FXSLeftColLayout
				id='fxs_signals_messaging_bar_inner'
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
				removePaddings={true}
				removeScroll={true}
				removeBg={true}
				isInsights={true}
			>
				<FXS_hd_med_padding_left>
					{preOrderTitle}
					{t('preOrderInfoTitle')}
				</FXS_hd_med_padding_left>
				{isPremium && <PreSignalLatest />}
			</FXSLeftColLayout>
			{!isLessThan1280 && (
				<FXSRightColLayout
					isLessThan1280={isLessThan1280}
					isLessThan833={isLessThan833}
					removePaddings={true}
					removeBg={true}
				>
					<div style={{ marginTop: '32px' }}>
						<FXS_Subtitle>{t('fxsSignal')}</FXS_Subtitle>
						<SignalsMini />
						<FXS_Subtitle style={{ marginTop: '4rem' }}>
							{t('nextWebinarsTitle')}
						</FXS_Subtitle>
						<EventList
							appId={config.Variables.AlgoliaAppId}
							apiKey={config.Variables.AlgoliaApiKey}
							indexName={config.Variables.AlgoliaIndex}
							take={config.Variables.AlgoliaTake}
							culture={config.Variables.Culture}
							t={t}
						/>
					</div>
				</FXSRightColLayout>
			)}
		</TwoColsMainLayout>
	);
}
