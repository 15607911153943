import { useContext, useEffect, useRef } from 'react';

import IconDiscord from 'components/icons/IconDiscord';
import { isLessThan } from 'fxs-utils';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import {
	faAngleDown,
	faArrowUpRightFromSquare
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ContextProps, useDiscordContext } from '../Context/DiscordContext';
import { getDiscordUrlUtils } from 'utils/discord';
import { UserContext } from 'contexts/userContext';
import { useTranslationsContext } from 'contexts/translationsContext';

export default () => {
	const moreInfoContent = useRef(null);
	const moreInfoLink = useRef(null);
	const popupMobile = useRef(null);
	const { isOpen, setIsOpen } = useDiscordContext() as ContextProps;
	const isLessThan833 = isLessThan(833);
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();

	useEffect(() => {
		if (!isOpen && isLessThan833) {
			setTimeout(() => {
				moreInfoContent.current?.classList.remove('visible');
				moreInfoLink.current?.classList.remove('hidden');
			}, 600);
		}
	}, [isOpen]);

	return (
		<>
			<div
				ref={popupMobile}
				className={`${isOpen ? 'open ' : ''}bottom-popup-mobile`}
			>
				<div className='discord-screenshot'>
					<span
						className='close-button'
						onClick={() => setIsOpen(!isOpen)}
					>
						<FontAwesomeIcon icon={faTimes as IconProp} />
					</span>
					<img
						src='https://editorial.fxstreet.com/images/TradingStudio/discord-analyst.png'
						alt='Discord Analyst'
					/>
				</div>
				<div className='description'>
					<h3>
						Live community on{' '}
						<span className='discord-brand'>
							Discord <IconDiscord />
						</span>
					</h3>
					<p>
						A place that makes it easy to talk every day with our
						Expert Analysts and other traders.
					</p>
					<span
						ref={moreInfoLink}
						className='more-info'
						onClick={() => {
							moreInfoContent.current.classList.add('visible');
							moreInfoLink.current.classList.add('hidden');
						}}
					>
						More information{' '}
						<FontAwesomeIcon icon={faAngleDown as IconProp} />
					</span>
					<div ref={moreInfoContent} className='more-info-content'>
						<p>
							<strong>Tap into our analysts</strong> to resolve
							ANY question related to your trades, makets moves,
							anything.
						</p>
						<p>
							Direct access to all <strong>insights</strong> and{' '}
							<strong>signals</strong>.
						</p>
						<p>
							<strong>Meet like-minded people</strong> that speak
							the same trading language as you.{' '}
							<strong>Don't trade alone!</strong>
						</p>
						<p>
							Do you need some help?Contact us at{' '}
							<a href='mailto:customercare@fxstreet.com'>
								customercare@fxstreet.com
							</a>
						</p>
					</div>
					<button
						onClick={() =>
							window.open(getDiscordUrlUtils(user.emailEncrypted))
						}
					>
						{t('joinDiscord')}
						<FontAwesomeIcon
							icon={faArrowUpRightFromSquare as IconProp}
						/>
					</button>
					<p className='not-now' onClick={() => setIsOpen(!isOpen)}>
						Not now, maybe later
					</p>
				</div>
			</div>
			<div
				className={`${isOpen ? 'visible ' : ''}background-overlay`}
				onClick={() => setIsOpen(!isOpen)}
			></div>
		</>
	);
};
