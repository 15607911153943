import { SCROLL_CONTAINER_ID } from '../constants/signalMessages';

export const scrollOnTop = (scrollContainer: HTMLElement): boolean => {
	return scrollContainer.scrollTop <= 1;
};

export const scrollOnTopReached = (scrollContainer: HTMLElement): boolean => {
	return scrollContainer.scrollTop < 1;
};

export const scrollOnBottomReached = (
	scrollContainer: HTMLElement
): boolean => {
	const bottomDistance =
		scrollContainer.scrollHeight -
		(scrollContainer.scrollTop + scrollContainer.clientHeight);
	return bottomDistance < 100;
};

export const scrollToTop = (scrollContainer: HTMLElement) => {
	scrollContainer.scrollTo({ top: 1 });
};

export const scrollToInit = (scrollContainer: HTMLElement) => {
	scrollContainer.scrollTo({ top: 0 });
};

export const scrollMove = (scrollContainer: HTMLElement, count: number) => {
	const element =
		scrollContainer ?? document.getElementById(SCROLL_CONTAINER_ID);
	const top = element.scrollTop + count;
	element.scrollTo({ top });
};
