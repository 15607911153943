import styled from 'styled-components';
import { COLORS } from 'styles/colors';

interface ColsLayoutProps {
	isLessThan1280: boolean;
	isLessThan833: boolean;
	removePaddings?: boolean;
	removeScroll?: boolean;
	removeBg?: boolean;
	isInsights?: boolean;
}

const paddingChooser = (removePaddings: boolean, isLessThan833: boolean) => {
	let returnPaddingValue = '';
	if (removePaddings) {
		returnPaddingValue = '0';
	} else if (isLessThan833) {
		returnPaddingValue = '15px';
	} else {
		returnPaddingValue = '25px';
	}
	return returnPaddingValue;
};
const scrollChooser = (
	removeScroll: boolean,
	isLessThan1280: boolean,
	isInsights: boolean
) => {
	let returnHeightValue = '';
	if (removeScroll || !isLessThan1280) {
		returnHeightValue = '100%';
	} else {
		returnHeightValue = 'auto';
	}
	if (isInsights) {
		returnHeightValue = '100%';
	}
	return returnHeightValue;
};

export const FXSLeftColLayout = styled.div<ColsLayoutProps>`
	height: ${(props) =>
		scrollChooser(
			props.removeScroll,
			props.isLessThan1280,
			props.isInsights
		)};
	width: ${(props) =>
		props.isLessThan1280 ? '100%' : 'calc( 100% - 430px)'};
	padding: ${(props) =>
		paddingChooser(props.removePaddings, props.isLessThan833)};
	background: ${({ removeBg }) => !removeBg && `${COLORS.white}`};
	scrollbar-width: none;
	overflow-y: ${(props) => (props.isInsights ? 'hidden' : 'auto')};
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
export const FXSRightColLayout = styled.div<ColsLayoutProps>`
	max-height: ${(props) => (props.isLessThan1280 ? 'none' : '100%')};
	width: ${(props) => (props.isLessThan1280 ? '100%' : '405px')};
	margin-left: ${(props) => (props.isLessThan1280 ? '0' : '25px')};
	padding: ${(props) =>
		paddingChooser(props.removePaddings, props.isLessThan833)};
	background: ${({ removeBg }) => !removeBg && `${COLORS.white}`};
	scrollbar-width: none;
	overflow-y: auto;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
