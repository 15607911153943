import { UserNewsletter } from 'models/newsletter';

export const getPremiumNewsletters = async () => {
	return fetch(
		`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.Newsletters}`
	)
		.then((premiumNewslettersResponse) => {
			return premiumNewslettersResponse.json();
		})
		.catch((error) => {
			console.log(
				`${error} error:  status - ${error.status}  | text:  ${error.statusText}`
			);
		});
};
export const getUserNewsletters = async (user) => {
	return fetch(
		`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.UserNewsletters}?email=${user.email}`
	)
		.then((userNewslettersResponse) => {
			if (userNewslettersResponse.ok) {
				return userNewslettersResponse.json();
			}
			return [] as UserNewsletter[];
		})
		.catch((error) => {
			console.log(
				`${error} error:  status - ${error.status}  | text:  ${error.statusText}`
			);
		});
};
