import React, { useState } from 'react';

import { useTranslationsContext } from 'contexts/translationsContext';
import { NewslettersProps } from 'models/props';

import { HeaderButton, HeaderTooltip } from 'components/Header/HeaderBtn/styles';
import { EnvelopeIcon } from './envelopeIcon';
import { isLessThan } from 'fxs-utils';

const NewsletterBtn: React.FC<Partial<NewslettersProps>> = ({
	isNewslettersModalOpened,
	setIsNewslettersModalOpened
}) => {
	const { t } = useTranslationsContext();
	const [showTooltip, setShowTooltip] = useState(false);
	const isLessThan833 = isLessThan(833);

	return (
		<HeaderButton
			type='button'
			onClick={() =>
				setIsNewslettersModalOpened(!isNewslettersModalOpened)
			}
			onMouseEnter={() => setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			<EnvelopeIcon />
			{isLessThan833 && <span>{t('PremiumNewsletters')}</span>}
			{showTooltip && (
				<HeaderTooltip>
					<span>{t('PremiumNewsletters')}</span>
				</HeaderTooltip>
			)}
		</HeaderButton>
	);
};

export default NewsletterBtn;
