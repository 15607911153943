import React, { useState } from 'react';

import { AuthorsContext } from '../contexts/authorsContext';
import { getAuthors } from '../data/getAuthors';
import { AuthorResponse } from '../models/author';

export const AuthorContextProvider = ({ children }) => {
	const [authorsResponse, setAuthors] = useState(null as AuthorResponse);
	const [alreadyCalled, setAlreadyCalled] = useState(false);

	if (!alreadyCalled) {
		setAlreadyCalled(true);
		getAuthors().then((authorsData) => {
			setAuthors(authorsData);
		});
	}

	return (
		<AuthorsContext.Provider value={{ authorsResponse }}>
			{children}
		</AuthorsContext.Provider>
	);
};
