import { Models } from 'fxs-signals-strategies-stats';

import {
	SignalStrategyFullStatsResponse,
	StatCalculationResponse
} from '../models/strategy';

const mapStrategiesStatsCalculation = (
	source: StatCalculationResponse
): Models.StatCalculation => {
	const mappedData = {
		averageDuration: new Models.DurationType(source.averageDuration),
		numberOfTrades: source.numberOfTrades,
		netProfit: source.netProfit,
		winRate: source.winRate,
		lossRate: source.lossRate,
		numberOfWinningTrades: source.numberOfWinningTrades,
		numberOfLosingTrades: source.numberOfLosingTrades,
		grossProfit: source.grossProfit,
		grossLoss: source.grossLoss,
		averageProfit: source.averageProfit,
		averageLoss: source.averageLoss
	};
	return mappedData;
};

export const mapStrategyFullStats = (
	source: SignalStrategyFullStatsResponse
): Models.SignalStrategyFullStatsModel => ({
	strategyId: source.strategyId,
	strategyFancyName: source.strategyFancyName,
	isDisabled: source.isDisabled,
	statCalculation: mapStrategiesStatsCalculation(source.statCalculation)
});
