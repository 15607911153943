export default function IconUser() {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M15.1289 13.9375C17.7305 13.9375 19.875 16.082 19.875 18.6836V19.5625C19.875 20.5117 19.1016 21.25 18.1875 21.25H5.8125C4.86328 21.25 4.125 20.5117 4.125 19.5625V18.6836C4.125 16.082 6.23438 13.9375 8.83594 13.9375C9.85547 13.9375 10.3125 14.5 12 14.5C13.6523 14.5 14.1094 13.9375 15.1289 13.9375ZM18.1875 19.5625V18.6836C18.1875 16.9961 16.8164 15.625 15.1289 15.625C14.6016 15.625 13.793 16.1875 12 16.1875C10.1719 16.1875 9.36328 15.625 8.83594 15.625C7.14844 15.625 5.8125 16.9961 5.8125 18.6836V19.5625H18.1875ZM12 13.375C9.1875 13.375 6.9375 11.125 6.9375 8.3125C6.9375 5.53516 9.1875 3.25 12 3.25C14.7773 3.25 17.0625 5.53516 17.0625 8.3125C17.0625 11.125 14.7773 13.375 12 13.375ZM12 4.9375C10.1367 4.9375 8.625 6.48438 8.625 8.3125C8.625 10.1758 10.1367 11.6875 12 11.6875C13.8281 11.6875 15.375 10.1758 15.375 8.3125C15.375 6.48438 13.8281 4.9375 12 4.9375Z'/>
		</svg>
	);
}
