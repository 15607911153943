import { useContext, useState } from 'react';

import { FilterContext } from 'contexts/filtersContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import useClickOutside from 'utils/useClickOutside';

import FilterModal from '../FilterModal';
import FilterIcon from './icon';
import { SFilterDesktopBtn } from './styles';

const FilterDesktopBtn = () => {
	const { t } = useTranslationsContext();

	const { filters } = useContext(FilterContext);
	const [show, setShow] = useState(false);

	const elementRef = useClickOutside(() => setShow(false));
	const isLessThan833 = isLessThan(833);

	return (
		<SFilterDesktopBtn onClick={() => setShow(!show)} show={show}>
			{t('filter')}
			<FilterIcon filters={filters} isLessThan833={isLessThan833} />
			<FilterModal
				show={show}
				setShow={setShow}
				elementRef={elementRef}
			/>
		</SFilterDesktopBtn>
	);
};

export default FilterDesktopBtn;
