import { useContext } from 'react';

import { NUM_CLOSED_SIGNALS, NUM_OPEN_SIGNALS } from 'constants/signalsMini';
import { TradesContext } from 'contexts/tradesContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import * as fxsSignalsMini from 'fxs-signals-mini';
import { mapFromApi } from 'mappers/trades';

export default function SignalsMini() {
	const { t } = useTranslationsContext();

	const { user } = useContext(UserContext);
	const { trades } = useContext(TradesContext);
	const culture = window.config.Variables.Culture;

	return (
		<div>
			<fxsSignalsMini.SignalsMini
				data={trades && trades.map((x) => mapFromApi(x))}
				isPremium={user?.isPremium}
				numClosedSignals={NUM_OPEN_SIGNALS}
				numOpenSignals={NUM_CLOSED_SIGNALS}
				signalsLandingUrl={window.config.Variables.SignalsLandingUrl}
				signalsPageUrl={window.config.Variables.SignalsPageUrl}
				error={false}
				isFetching={!trades}
				culture={culture}
				t={t}
			/>
		</div>
	);
}
