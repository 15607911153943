import React from 'react';

import Dashboard from 'components/Dashboard';
import { ChildrenProps } from 'models/props';
import { isLessThan } from 'utils/useWindowWidth';

import { FXSFullWidthCol } from './styles';

export default function FullWidthMainLayout({ children }: ChildrenProps) {
	const isLessThan833 = isLessThan(833);

	return (
		<Dashboard>
			<FXSFullWidthCol isLessThan833={isLessThan833}>
				{children}
			</FXSFullWidthCol>
		</Dashboard>
	);
}
