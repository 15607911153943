import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
	faBullhorn,
	faChalkboardTeacher,
	faExchangeAlt
} from '@fortawesome/free-solid-svg-icons';
import { faCalendarStar, faGem } from '@fortawesome/pro-regular-svg-icons';

export const NOTIFICATIONS = 'notifications';
export const SIGNAL = 'signal';
export const ECONOMIC = 'economic';
export const ANALYSIS = 'analysis';
export const COMMUNITY = 'community';
export const SPECIAL = 'special';

export type NotificationsProps = {
	id: string;
	title: string;
	description: string;
	icon: IconDefinition;
};

export const NotificationList: NotificationsProps[] = [
	{
		id: SIGNAL,
		title: 'signalNotificationTitle',
		description: 'signlaNotificationDescription',
		icon: faExchangeAlt
	},
	{
		id: ECONOMIC,
		title: 'economicNotificationTitle',
		description: 'economicNotificationDescription',
		icon: faCalendarStar
	},
	{
		id: ANALYSIS,
		title: 'analysisNotificationTitle',
		description: 'analysisNotificationDescription',
		icon: faChalkboardTeacher
	},
	{
		id: COMMUNITY,
		title: 'communtiyNotificationTitle',
		description: 'communityNotificationDescription',
		icon: faGem
	},
	{
		id: SPECIAL,
		title: 'specialNotificationTitle',
		description: 'specialNotificationDescription',
		icon: faBullhorn
	}
];

declare global {
	interface Window {
		NotificationHelper: () => void;
	}
}
export const notificationHelper = () => {
	(window as any).NotificationHelper = () => {
		console.log('NOTIFICATION HELPER ENTERED');
		let events = [];
		return new Promise(function (resolve) {
			resolve({
				getSubscriptions: function () {
					return new Promise(function (res) {
						res(events);
					});
				},
				unsubscribe: function (eventId) {
					return new Promise(function (res) {
						events = events.filter((x) => x !== eventId);
						res(events);
					});
				},
				subscribe: function (eventId) {
					return new Promise(function (res) {
						if (events.indexOf(eventId) < 0) events.push(eventId);
						res(events);
					});
				},
				isPushNotificationsEnabled: function () {
					return new Promise(function (res) {
						res(true);
					});
				},
				isPushNotificationsSupported: function () {
					return new Promise(function (res) {
						res(true);
					});
				},
				onPermissionChanged: function () {
					return new Promise(function (res) {
						res(true);
					});
				}
			});
		});
	};
};
