import { css } from 'styled-components';
import { COLORS } from 'styles/colors';

const BtnBase = css`
	display: inline-block;
	height: 35px;
	background-color: transparent;
	border: 1px solid transparent;
	font-weight: 700;
	text-align: center;
	font-smooth: antialiased;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	vertical-align: middle;
	cursor: pointer;
	transition: all 0.25s ease-in;
`;
const BtnSmall = css`
	${BtnBase};
	height: 27px;
`;
const BtnDisabled = css`
	${BtnBase};
	cursor: not-allowed !important;
`;
const BtnIcon = css`
	.svg-inline--fa {
		margin-right: 5px;
	}
`;
/************************
  CTA
************************/
const BtnCta = css`
	background: ${COLORS.highlight};
	border-color: ${COLORS.highlight};
	color: ${COLORS.white};
	&:hover,
	&:active,
	&:focus {
		background: rgba(228, 135, 27, 0.8);
		border-color: rgba(228, 135, 27, 0.4);
	}
`;
export const FXSBtnCta = css`
	${BtnBase};
	${BtnCta};
`;
export const FXSBtnCtaDisabled = css`
	${BtnDisabled};
	${BtnCta};
`;
export const FXSBtnCtaSmall = css`
	${BtnSmall};
	${BtnCta};
`;
export const FXSBtnCtaIcon = css`
	${BtnIcon};
	${BtnCta};
`;
/************************
  DEFAULT
************************/
const BtnDefault = css`
	${BtnBase};
	background: ${COLORS.blue};
	border-color: ${COLORS.blue};
	color: ${COLORS.white};
	&:hover,
	&:active,
	&:focus {
		background: rgba(48, 76, 112, 0.8);
		border-color: rgba(48, 76, 112, 0.4);
	}
`;
export const FXSBtnDefault = css`
	${BtnBase};
	${BtnDefault};
`;
export const FXSBtnDefaultDisabled = css`
	${BtnDisabled};
	${BtnDefault};
`;
export const FXSBtnDefaultSmall = css`
	${BtnSmall};
	${BtnDefault};
`;
export const FXSBtnDefaultIcon = css`
	${BtnIcon};
	${BtnDefault};
`;
/************************
  PHANTOM
************************/
const BtnPhantom = css`
	${BtnBase};
	color: ${COLORS.dark};
	&:hover,
	&:active,
	&:focus {
		background: ${COLORS.grey400};
	}
`;
export const FXSBtnPhantom = css`
	${BtnBase};
	${BtnPhantom};
`;
export const FXSBtnPhantomDisabled = css`
	${BtnDisabled};
	${BtnPhantom};
`;
export const FXSBtnPhantomSmall = css`
	${BtnSmall};
	${BtnPhantom};
`;
export const FXSBtnPhantomIcon = css`
	${BtnIcon};
	${BtnPhantom};
`;
/************************
  LINE
************************/
const BtnLine = css`
	${BtnBase};
	background: ${COLORS.white};
	border-color: ${COLORS.dark};
	color: ${COLORS.dark};
	&:hover,
	&:active,
	&:focus {
		background: ${COLORS.grey400};
		border-color: ${COLORS.dark};
	}
`;
export const FXSBtnLine = css`
	${BtnBase};
	${BtnLine};
`;
export const FXSBtnLineDisabled = css`
	${BtnDisabled};
	${BtnLine};
`;
export const FXSBtnLineSmall = css`
	${BtnSmall};
	${BtnLine};
`;
export const FXSBtnLineIcon = css`
	${BtnIcon};
	${BtnLine};
`;
/************************
  DARK
************************/
const BtnDark = css`
	${BtnBase};
	background: ${COLORS.dark};
	border-color: ${COLORS.dark};
	color: ${COLORS.white};
	&:hover,
	&:active,
	&:focus {
		background: ${COLORS.grey};
		border-color: ${COLORS.dark};
	}
`;
export const FXSBtnDark = css`
	${BtnBase};
	${BtnDark};
`;
export const FXSBtnDarkDisabled = css`
	${BtnDisabled};
	${BtnDark};
`;
export const FXSBtnDarkSmall = css`
	${BtnSmall};
	${BtnDark};
`;
export const FXSBtnDarkIcon = css`
	${BtnIcon};
	${BtnDark};
`;
