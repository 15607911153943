// import getFaqData from "data/getFaqData";
import React from 'react';

import Dashboard from 'components/Dashboard';
import { FXSFullWidth } from 'components/Layouts/styles';
import Spinner from 'components/Spinner';
import { useTranslationsContext } from 'contexts/translationsContext';
import FAQ from 'fxs-faq';
import { isLessThan } from 'utils/useWindowWidth';

const FaqPage = React.memo(function FaqPage() {
	const { t } = useTranslationsContext();
	const isLessThan833 = isLessThan(833);
	const res = t('faq');
	const data = res === 'faq' ? null : JSON.parse(res);

	return (
		<Dashboard>
			<FXSFullWidth isLessThan833={isLessThan833}>
				{data ? <FAQ data={data} /> : <Spinner />}
			</FXSFullWidth>
		</Dashboard>
	);
});

export default FaqPage;
