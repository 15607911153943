import { useContext, useState } from 'react';

import IconLogout from 'components/icons/IconLogout';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { logout } from 'utils/logout';

import {
	FXSLogoutBtn,
	FXSUserInfoMobile,
	FXSUserWrapper
} from './styles';
import IconUser from 'components/icons/IconUser';

export default function UserInfoMobile() {
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();

	const [isLogoutOpen, setLogoutOpen] = useState(false);

	return (
		user && (
			<FXSUserWrapper>
				<FXSUserInfoMobile
					type='button'
					onClick={() => {
						setLogoutOpen(!isLogoutOpen);
					}}
				>
					<IconUser />
				</FXSUserInfoMobile>
				{isLogoutOpen && (
					<FXSLogoutBtn
						type='button'
						onClick={() => {
							logout();
						}}
					>
						<IconLogout />
						<span>{t('Logout')}</span>
					</FXSLogoutBtn>
				)}
			</FXSUserWrapper>
		)
	);
}
