import React from 'react';
import OneSignal from 'react-onesignal';

import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { NotificationProps } from 'models/props';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import {
	NotificationsTutorialBackdrop,
	NotificationsTutorialBody,
	NotificationsTutorialBtn,
	NotificationsTutorialBtnWrapper,
	NotificationsTutorialCloseIcon,
	NotificationsTutorialHeader,
	NotificationsTutorialImg,
	NotificationsTutorialImgBtnNext,
	NotificationsTutorialImgBtnPrev,
	NotificationsTutorialLink,
	NotificationsTutorialWrapper
} from './styles';
import useNotificationTutorial from './useNotificationTutorial';

const NotificationsTutorial: React.FC<NotificationProps> = ({
	elementRef,
	isNotificationsModalOpened,
	setIsNotificationsModalOpened
}) => {
	const { t } = useTranslationsContext();

	const {
		currentImgIndex,
		imgs,
		handleCloseModal,
		handlePrevImg,
		handleNextImg
	} = useNotificationTutorial(
		isNotificationsModalOpened,
		setIsNotificationsModalOpened
	);

	const isLessThan833 = isLessThan(833);

	return (
		<NotificationsTutorialBackdrop
			isNotificationsModalOpened={isNotificationsModalOpened}
		>
			<NotificationsTutorialWrapper ref={elementRef}>
				<NotificationsTutorialHeader>
					<NotificationsTutorialCloseIcon
						icon={faTimes as IconProp}
						onClick={handleCloseModal}
					/>
					{isLessThan833 ? (
						<>
							<NotificationsTutorialImgBtnPrev
								onClick={handlePrevImg}
							/>
							<NotificationsTutorialImg
								src={`${window.config.Variables.StaticContentBaseUrl}TradingStudio/${imgs[currentImgIndex]}`}
								alt='notifications-tutorial'
							/>
							<NotificationsTutorialImgBtnNext
								onClick={handleNextImg}
							/>
						</>
					) : (
						<NotificationsTutorialImg
							src={`${window.config.Variables.StaticContentBaseUrl}TradingStudio/notificationsTutorialDesktop.png`}
							alt='notifications-tutorial'
						/>
					)}
				</NotificationsTutorialHeader>
				<NotificationsTutorialBody>
					<h2>{t('notificationsTutorialHeading')}</h2>
					<p>{t('notificationsTutorialText')}</p>
					<NotificationsTutorialBtnWrapper>
						<NotificationsTutorialLink
							href={
								window.config.Variables
									.HowEnableNotificationsUrl
							}
							target='_blank'
							rel='noopener noreferrer'
						>
							{t('enableNotificationsLink')}
						</NotificationsTutorialLink>
						<NotificationsTutorialBtn
							onClick={() => {
								OneSignal.showSlidedownPrompt();
								setIsNotificationsModalOpened(false);
							}}
						>
							{t('gotIt')}
						</NotificationsTutorialBtn>
					</NotificationsTutorialBtnWrapper>
				</NotificationsTutorialBody>
			</NotificationsTutorialWrapper>
		</NotificationsTutorialBackdrop>
	);
};

export default NotificationsTutorial;
