import { createContext, useContext, useEffect, useState } from 'react';

import { UserContext } from 'contexts/userContext';

export type ContextProps = {
	isOpen: boolean;
	setIsOpen: (state: boolean) => boolean;
	isDiscord: boolean;
	setIsDiscord: (state: boolean) => boolean;
};

export const Context = createContext({});

export const DiscordContext = ({ children }) => {
	const [isOpen, setIsOpen] = useState(null);
	const [isDiscord, setIsDiscord] = useState(null);
	const [isReady, setIsReady] = useState(false);
	const { user } = useContext(UserContext);
	const { isPremium } = user;

	const getDiscordData = () => {
		setIsDiscord(isPremium);
		setIsReady(true);
	};

	useEffect(() => {
		getDiscordData();
	}, []);

	return (
		isReady && (
			<Context.Provider value={{ isOpen, setIsOpen, isDiscord }}>
				{children}
			</Context.Provider>
		)
	);
};

export const useDiscordContext = () => {
	return useContext(Context);
};
