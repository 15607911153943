import { UserSettingsRequest } from '../models/userSettings';
import { getUser } from '../utils/getUser';

const getParams = (
	email: string,
	latestReadMessageDate: string,
	token: string
	// eslint-disable-next-line no-undef
): RequestInit => ({
	method: 'PUT',
	headers: {
		'Content-Type': 'application/json',
		Authorization: `bearer ${token}`
	},
	body: JSON.stringify({
		email,
		latestReadMessageDate
	} as UserSettingsRequest),
	keepalive: true
});

const fetcher = async (
	userSettingsId: string,
	email: string,
	latestReadMessageDate: string
): Promise<void> => {
	return getUser().then((user) => {
		if (user?.token && userSettingsId) {
			fetch(
				`${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalUserSettingsId.replace('{userSettingsId}', userSettingsId)}`,
				getParams(email, latestReadMessageDate, user?.token)
			);
		}
	});
};

export async function updateSignalsSettings(
	userSettingsId: string,
	email: string,
	latestReadMessageDate: string
): Promise<void> {
	return fetcher(userSettingsId, email, latestReadMessageDate);
}
