import { useContext } from 'react';

import { StrategyFullStatsContext } from 'contexts/strategyFullStatsContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { SignalsStrategiesStats } from 'fxs-signals-strategies-stats';
import { mapStrategyFullStats } from 'mappers/strategyFullStats';
import { isLessThan } from 'utils/useWindowWidth';

export default function StrategyFullStats() {
	const { t } = useTranslationsContext();

	const strategyFullStatsResponse = useContext(StrategyFullStatsContext);
	const isLessThan1280 = isLessThan(1280);

	const getHref = (key: string): string => {
		return `/strategies/${key}`;
	};

	return (
		<SignalsStrategiesStats
			data={
				strategyFullStatsResponse &&
				strategyFullStatsResponse?.data?.map((x) =>
					mapStrategyFullStats(x)
				)
			}
			isFetching={
				!strategyFullStatsResponse?.data &&
				!strategyFullStatsResponse?.error
			}
			error={strategyFullStatsResponse?.error}
			href={getHref}
			size={isLessThan1280 ? 'big' : 'small'}
			t={t}
			isSPA
			notSPAUrl={' '}
		/>
	);
}
