import { FilterState } from 'contexts/filtersContext';
import { EditorialMarketType } from 'fxs-signals-insights/dist/models/enums';
import { EditorialMessage } from 'models/signalMessages';

const isFilterApplied = (filters: FilterState) => {
	return Object.keys(filters).some((filter) => {
		return filters[filter].length > 0;
	});
};

export const isMessageMatch = (
	filters: FilterState,
	mapped: EditorialMessage
) => {
	if (!isFilterApplied(filters)) return true;

	const result =
		filters.categories.includes(mapped.category) ||
		filters.markets.includes(mapped.market as EditorialMarketType) ||
		filters.tags.some((tagId) =>
			mapped.tags.some(
				(mappedTag) => tagId === mappedTag['id'].toUpperCase()
			)
		);
	return result;
};
