import React, { useContext, useEffect, useRef } from 'react';

import DiscordBtn from 'components/DiscordBtn';
import {
	ContextProps,
	useDiscordContext
} from 'components/DiscordBtnOnboarding/Context/DiscordContext';
import JoinTab from 'components/DiscordBtnOnboarding/Desktop/JoinTab';
import { MemoFaqBtn } from 'components/FaqBtn';
import NewsletterBtn from 'components/NewsletterBtn';
import NotificationsBtn from 'components/NotificationsBtn';
import { UserInfoMobile } from 'components/UserInfo';
import UserInfoDesktop from 'components/UserInfo/Desktop';
import IconDiscord from 'components/icons/IconDiscord';
import { UserContext } from 'contexts/userContext';
import { DashboardProps } from 'models/props';
import { isLessThan } from 'utils/useWindowWidth';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faAngleDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	DivDiscordPopupDesktop,
	FXSHeaderDesktop,
	FXSHeaderHamburguerMenu,
	FXSHeaderMobile,
	FXSHeaderMobileWrapper,
	FXSHeaderMobileLogo,
	SmallVertDivider
} from './styles';
import { getDiscordUrlUtils } from 'utils/discord';
import { useTranslationsContext } from 'contexts/translationsContext';
import IconHamburguer from 'components/icons/IconHamburguer';

export default function HeaderMobile({
	setLateralMobileMenuStatus,
	setIsNotificationsModalOpened,
	setIsNewslettersModalOpened
}: DashboardProps) {
	const isLessThan833 = isLessThan(833);
	const { isDiscord, isOpen, setIsOpen } =
		useDiscordContext() as ContextProps;
	const moreInfoContent = useRef(null);
	const moreInfoLink = useRef(null);
	const popupDesktop = useRef(null);
	const { user } = useContext(UserContext);
	const { isPremium } = user;
	const { t } = useTranslationsContext();

	useEffect(() => {
		if (!isOpen && !isLessThan833) {
			moreInfoContent.current?.classList.remove('visible');
			moreInfoLink.current?.classList.remove('hidden');
		}
	}, [isOpen]);

	if (isLessThan833) {
		return (
			<FXSHeaderMobile>
				<FXSHeaderMobileWrapper>
					<FXSHeaderHamburguerMenu
						type='button'
						data-ga4event-clk-name='header_click'
						data-ga4event-element='hamburguer'
						onClick={() => {
							setLateralMobileMenuStatus(true);
						}}
					>
						<IconHamburguer />
					</FXSHeaderHamburguerMenu>
					<FXSHeaderMobileLogo
						src={`${window.config.Variables.StaticContentBaseUrl}premiumLanding/PremiumAcronymTransparentBackgroundMobile.svg`}
						alt='fxstreet premium mobile logo'
					/>
				</FXSHeaderMobileWrapper>
				<FXSHeaderMobileWrapper>
					<DiscordBtn />
					<UserInfoMobile />
				</FXSHeaderMobileWrapper>
			</FXSHeaderMobile>
		);
	}

	if (!isLessThan833) {
		return (
			<>
				{isDiscord === false && isPremium && <JoinTab />}
				<FXSHeaderDesktop>
					{isPremium && (
						<>
							<NewsletterBtn
								setIsNewslettersModalOpened={
									setIsNewslettersModalOpened
								}
							/>
							{window.config.Variables.NotificationEnabled && (
								<NotificationsBtn
									setIsNotificationsModalOpened={
										setIsNotificationsModalOpened
									}
								/>
							)}
						</>
					)}
					<MemoFaqBtn />
					<SmallVertDivider />
					<DiscordBtn />
					<SmallVertDivider />
					<UserInfoDesktop />
				</FXSHeaderDesktop>

				{isDiscord === false && isPremium && (
					<DivDiscordPopupDesktop
						className={`${isOpen ? 'visible' : ''} discord-popup-desktop`}
						ref={popupDesktop}
					>
						<span
							className='close-button'
							onClick={() => setIsOpen(!isOpen)}
						>
							<FontAwesomeIcon icon={faTimes as IconProp} />
						</span>
						<div className='discord-analysts'>
							<img
								src='https://editorial.fxstreet.com/images/TradingStudio/discord-analysts-desktop.png'
								alt='Discord'
							/>
						</div>
						<div className='description'>
							<h3>
								Live community on{' '}
								<span className='discord-brand'>
									Discord <IconDiscord />
								</span>{' '}
							</h3>
							<p>
								A place that makes it easy to talk every day
								with our Expert Analysts and other traders.{' '}
								<span
									ref={moreInfoLink}
									className='more-info'
									onClick={() => {
										moreInfoContent.current.classList.add(
											'visible'
										);
										moreInfoLink.current.classList.add(
											'hidden'
										);
										popupDesktop.current.classList.add(
											'expanded'
										);
									}}
								>
									More info{' '}
									<FontAwesomeIcon
										icon={faAngleDown as IconProp}
									/>
								</span>
							</p>
							<div
								ref={moreInfoContent}
								className={'more-info-content'}
							>
								<p>
									<strong>Tap into our analysts</strong> to
									resolve ANY question related to your trades,
									markets moves, anything.
								</p>
								<p>
									Direct access to all{' '}
									<strong>insights</strong> and{' '}
									<strong>signals.</strong>
								</p>
								<p>
									<strong>Meet like-minded people</strong>{' '}
									that speak the same trading language as you.{' '}
									<strong>Don't trade alone!</strong>
								</p>
								<p>
									<strong>Share ideas</strong>, opinions
									knowledge.
								</p>
								<p>
									Do you need some help? Contact us at{' '}
									<a href='mailto:customercare@fxstreet.com'>
										customercare@fxstreet.com
									</a>
								</p>
							</div>
							<button onClick={() => window.open(getDiscordUrlUtils(user.emailEncrypted)) }>
								{t('joinDiscord')}
								<FontAwesomeIcon
									icon={faArrowUpRightFromSquare as IconProp}
								/>
							</button>
							<p
								className='not-now'
								onClick={() => {
									setIsOpen(!isOpen);
									document.cookie =
										'discordPopup=dismissed;path=/';
								}}
							>
								Not now, maybe later
							</p>
						</div>
					</DivDiscordPopupDesktop>
				)}
			</>
		);
	}
}

export const MemHeaderMobile = React.memo(HeaderMobile);
