import { mapTypeformUserState } from 'mappers/typeformUserState';
import { TypeformSettingsFilterResponse } from 'models/userSettings';

import { getUser } from '../utils/getUser';

const fetcher = async (
	email: string
): Promise<TypeformSettingsFilterResponse> => {
	return getUser().then(async (user) => {
		const url = window.config.Endpoints.UserTypeformSettings.replace(
			'{email}',
			email
		);
		const res = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/${url}`, {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				Authorization: `bearer ${user?.token}`,
				'Content-Type': 'application/json'
			}
		});

		if (res.ok) {
			const data = await res.json();
			const mappedSettings = data.map(
				(item: TypeformSettingsFilterResponse) =>
					mapTypeformUserState(item)
			) as TypeformSettingsFilterResponse[];
			const result = mappedSettings.reduce((a, b) =>
				a.DateUtc > b.DateUtc ? a : b
			);

			return result;
		}

		return null;
	});
};

export async function getTypeformUserState(
	email: string
): Promise<TypeformSettingsFilterResponse> {
	return fetcher(email);
}
