import styled from 'styled-components';

export const FXSInsightsWrapper = styled.div`
	position: relative;
	margin-top: 1rem;
	scrollbar-width: none;
	overflow: auto;
	-ms-overflow-style: none;
	max-height: calc(100% - 70px);
	@media (min-width: 833px) {
		max-height: calc(100% - 60px);
	}

	&::-webkit-scrollbar {
		display: none;
	}
`;
