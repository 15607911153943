export type TagsProps = {
	id: string;
	name: string;
};

export const DefaultTags: TagsProps[] = [
	{
		id: '5F91AD8F-26CD-4643-9233-46BD18B03A70',
		name: 'EURUSD'
	},
	{
		id: '501AFD67-4C54-4EF8-A2E1-3650EAD6F211',
		name: 'Bitcoin'
	},
	{
		id: 'A6744C19-FE88-488F-8044-FB1F574CA818',
		name: 'GBPUSD'
	},
	{
		id: 'F629E19C-42A3-4D63-A9C6-246FA4960867',
		name: 'Ethereum'
	},
	{
		id: '71F084AA-8636-45A8-B08C-BA41A091BE85',
		name: 'USDJPY'
	},
	{
		id: '91953627-3E2C-433D-97E3-6A50D83C3F9D',
		name: 'USDCAD'
	},
	{
		id: '2B905E80-74EB-4224-8163-DE2557A73750',
		name: 'AUDUSD'
	},
	{
		id: 'B97E5E97-9BDB-48AA-AF9B-013F3B95C773',
		name: 'technical-analysis'
	},

	{
		id: '5307A4E8-6869-4229-9720-2A6C4C5359A6',
		name: 'Oil'
	},
	{
		id: '8DD7C338-C37E-45D1-AE48-FC1FB8676E28',
		name: 'fundamental-analysis'
	},
	{
		id: '56b92bf1-0d81-44f1-9e59-6030ea8fc601',
		name: 'Psychology'
	},
	{
		id: '6fdc5ec4-f743-4a8e-ab81-c9677516ad81',
		name: 'user-question'
	},
	{
		id: 'A07DCC82-2D47-4B16-BE1F-9EF08755EBAD',
		name: 'Gold'
	}
];
