import { NewerMessage, OlderMessage } from 'fxs-signals-insights';

export const MESSAGES_PER_REQUEST = 20;
export const NUM_UNREAD_MESSAGES = 3;
export const SCROLL_CONTAINER_ID = 'fxs_signals_messaging_bar_inner';

export const newerDefaults = {
	offset: -(NUM_UNREAD_MESSAGES + MESSAGES_PER_REQUEST),
	error: false,
	isFetching: false,
	noMore: false,
	receiving: false
} as NewerMessage;

export const olderDefaults = {
	offset: 0,
	error: false,
	isFetching: false
} as OlderMessage;
