import React, { useState } from 'react';

import { CATEGORIES } from 'constants/filter';
import { useTranslationsContext } from 'contexts/translationsContext';
import { isLessThan } from 'fxs-utils';
import { EditorialCategoryType } from 'models/enums';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { RowTypes } from './RowType';
import {
	FXSFilterTradeTooltipTriangle,
	FXSFilterTradesIcon,
	FXSFilterTradesInner,
	FXSFilterTradesInnerText,
	FXSFilterTradesLabel,
	FXSFilterTradesSmall,
	FXSFilterTradesSwitchCircle,
	FXSFilterTradesSwitchWrapper,
	FXSFilterTradesTooltip,
	FXSFilterTradesTooltipText,
	FXSFilterTradesWrapper
} from './styles';

const TradesRow = ({ activeTrades, handleClick }: Omit<RowTypes, 'state'>) => {
	const [showTooltip, setShowTooltip] = useState(false);
	const { t } = useTranslationsContext();

	const isLessThan833 = isLessThan(833);

	const Switcher = () => {
		return (
			<FXSFilterTradesSwitchWrapper
				activeTrade={activeTrades}
				onClick={(e) =>
					handleClick(
						e,
						EditorialCategoryType.Trade,
						CATEGORIES,
						null,
						true
					)
				}
			>
				<FXSFilterTradesSwitchCircle
					activeTrade={activeTrades}
					onClick={(e) =>
						handleClick(
							e,
							EditorialCategoryType.Trade,
							CATEGORIES,
							null,
							true
						)
					}
				/>
			</FXSFilterTradesSwitchWrapper>
		);
	};
	return (
		<FXSFilterTradesWrapper>
			{isLessThan833 ? (
				<FXSFilterTradesInner>
					<Switcher />
					<FXSFilterTradesInnerText>
						<FXSFilterTradesLabel isLessThan833={isLessThan833}>
							{t('tradesFilterLabel')}
						</FXSFilterTradesLabel>
						<FXSFilterTradesSmall>
							{t('tradesFilterTooltip')}
						</FXSFilterTradesSmall>
					</FXSFilterTradesInnerText>
				</FXSFilterTradesInner>
			) : (
				<FXSFilterTradesInner>
					<Switcher />
					<FXSFilterTradesLabel isLessThan833={isLessThan833}>
						{t('tradesFilterLabel')}
					</FXSFilterTradesLabel>
					<FXSFilterTradesIcon
						icon={faInfoCircle as IconProp}
						onMouseEnter={() => setShowTooltip(true)}
						onMouseLeave={() => setShowTooltip(false)}
					/>
					<FXSFilterTradesTooltip show={showTooltip}>
						<FXSFilterTradeTooltipTriangle />
						<FXSFilterTradesTooltipText>
							{t('tradesFilterTooltip')}
						</FXSFilterTradesTooltipText>
					</FXSFilterTradesTooltip>
				</FXSFilterTradesInner>
			)}
		</FXSFilterTradesWrapper>
	);
};

export default TradesRow;
