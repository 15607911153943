import React from 'react';

import { YoutubeWrapper } from './styles';

type YoutubeProps = {
	embedCode: string;
};

const YoutubeIframe = ({ embedCode }: YoutubeProps) => {
	const masterClassEmbedDomain = window.location.hostname;

	return (
		<YoutubeWrapper>
			<iframe
				width='100%'
				height='100%'
				src={`https://www.youtube.com/embed/${embedCode}?autoplay=1`}
				title='YouTube video player'
				frameBorder='0'
				allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
				allowFullScreen
			></iframe>
			<iframe
				width='100%'
				height='100%'
				src={`https://www.youtube.com/live_chat?v=${embedCode}&embed_domain=${masterClassEmbedDomain}`}
				frameBorder='0'
			></iframe>
		</YoutubeWrapper>
	);
};

export default YoutubeIframe;
