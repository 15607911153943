import { css } from 'styled-components';

const hd_large = css`
	font-weight: 700;
	font-size: 1.9312rem;
	line-height: 2rem;
	@media screen and (min-width: 680px) {
		font-size: 2.6375rem;
		line-height: 2.75rem;
	}
`;
const hd_med_to_large = css`
	font-weight: 700;
	font-size: 1.55rem;
	line-height: 1.6rem;
	@media screen and (min-width: 680px) {
		font-size: 2rem;
		line-height: 2.2rem;
	}
`;
const hd_med = css`
	font-weight: 700;
	font-size: 1.3rem;
	line-height: 1.4rem;
	@media screen and (min-width: 680px) {
		font-size: 1.6812rem;
		line-height: 2rem;
	}
`;
const hd_small_med = css`
	font-weight: 700;
	font-size: 1.1rem;
	line-height: 1.3rem;
	@media screen and (min-width: 680px) {
		font-size: 1.4rem;
		line-height: 1.6rem;
	}
`;
const hd_small = css`
	font-weight: 700;
	font-size: 1rem;
	line-height: 1.25rem;
	@media screen and (min-width: 680px) {
		font-size: 1.1rem;
		line-height: 1.3rem;
	}
`;
const hd_tiny = css`
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 1.0625rem;
	@media screen and (min-width: 680px) {
		font-size: 1rem;
		line-height: 1.25rem;
	}
`;
const hd_highlight = css`
	font-weight: 700;
	font-size: 1rem;
	font-style: italic;
	line-height: 1.4rem;
	@media screen and (min-width: 680px) {
		font-size: 1.2rem;
		line-height: 1.7rem;
	}
`;

export {
	hd_large,
	hd_med_to_large,
	hd_med,
	hd_small_med,
	hd_small,
	hd_tiny,
	hd_highlight
};
