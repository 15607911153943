import styled, { css, keyframes } from 'styled-components';
import { FXSBtnCta, FXSBtnPhantom } from 'styles/button';
import { COLORS } from 'styles/colors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ActiveNewslettersProps = {
	isActive?: boolean;
};

const Sidebar = css`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	height: max-content;
	margin-left: auto;
	background: ${COLORS.white};
	justify-content: space-between;
`;

export const NewslettersBackdrop = styled.div<{
	isNewslettersModalOpened: boolean;
}>`
	position: fixed;
	top: 0;
	bottom: 0;
	left: ${({ isNewslettersModalOpened }) =>
		isNewslettersModalOpened ? '0' : '100%'};
	right: ${({ isNewslettersModalOpened }) =>
		isNewslettersModalOpened ? '0' : '-200%'};
	display: flex;
	justify-content: flex-start;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1001;
	min-height: 100vh;
	height: 100%;
	overflow-y: auto;
	transition: all 0.3s ease-in-out;
	scrollbar-width: none;
	-ms-overflow-style: none;
	&::-webkit-scrollbar {
		display: none;
	}
`;
export const NewslettersSidebar = styled.aside`
	${Sidebar};
	width: 288px;
	@media (min-width: 833px) {
		width: 480px;
	}
`;
/************************
  HEADER
************************/
export const NewslettersSidebarHeader = styled.div`
	position: sticky;
	top: 0;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.5rem;
	background: ${COLORS.white};
	border-bottom: 1px solid ${COLORS.grey300};
`;
export const NewslettersGreybox = styled.div<{ show?: boolean }>`
	display: ${(props) => (props.show ? 'flex' : 'none')};
	flex-direction: column;
	align-items: center;
	background-color: #e0e7ee;
	padding: 17px 24px;
	overflow: hidden;
	@media (min-width: 833px) {
		padding: 20px 24px;
		flex-direction: row;
	}
	svg {
		width: 177px;
		position: relative;
		bottom: -55px;
		margin-top: -55px;
		flex: 0 0 177px;
		@media (min-width: 833px) {
			bottom: -20px;
			margin-top: -20px;
		}
	}
`;
export const NewslettersDescription = styled.p`
	color: #49494f;
	font-size: 16px;
	line-height: 20px;
	@media (min-width: 833px) {
		font-size: 20px;
		line-height: 24px;
	}
`;
export const NewslettersCloseText = styled.button`
	font-weight: 700;
	font-size: 14px;
	line-height: 20px;
	color: #2a77f6;
	&,
	&:hover,
	&:active {
		background-color: transparent;
		border: 0;
	}
`;
export const NewslettersSidebarTitle = styled.h2`
	margin: 0;
	font-size: 16px;
	line-height: 20px;
	@media (min-width: 833px) {
		font-size: 22.4px;
		line-height: 26px;
	}
`;
export const CloseIcon = styled(FontAwesomeIcon)`
	position: initial;
	top: unset;
	right: unset;
	height: 24px;
	width: 24px !important;
	color: ${COLORS.dark};
	@media (min-width: 833px) {
		position: absolute;
		top: 1.5rem;
		right: 1.5rem;
	}
`;
/************************
  BODY
************************/
export const NewslettersModalBody = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 1.5rem;
	padding: 1.5rem 0 20px;
	border-bottom: 1px solid ${COLORS.grey300};
	@media (min-width: 833px) {
		padding: 1.5rem 0 24px;
	}
`;
/************************
  ALL ON
************************/
export const NewslettersModalAllOnWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-items: flex-end;
	margin-bottom: 20px;
`;
export const NewslettersModalAllOnP = styled.p`
	flex: 1;
	margin-bottom: 0;
	margin-right: 13px;
	font-weight: 700;
	font-size: 12px;
	line-height: 16px;
	text-align: end;
`;
/************************
  ROW
************************/
export const NewslettersModalRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	margin-bottom: 4px;
`;
export const NewslettersModalDescription = styled.div`
	flex: 1 1 calc(100% - 30px);
	display: flex;
	flex-direction: column;
	gap: 4px;
	& h3 {
		margin: 0;
		font-size: 16px;
		line-height: 20px;
	}
	& p {
		margin: 0;
		font-weight: 300;
		font-size: 14.72px;
		line-height: 20px;
	}
`;
/************************
  CHECKBOX
************************/
export const NewslettersModalCheckbox = styled.div`
	flex: 0 0;
	padding-right: unset;
	@media (min-width: 768px) {
		padding-right: 12px;
	}
`;
export const NewslettersModalSwitchWrapper = styled.div<ActiveNewslettersProps>`
	position: relative;
	height: 16px;
	width: 30px;
	background-color: ${({ isActive }) =>
		isActive ? `${COLORS.ui_blue}` : ` ${COLORS.grey500}`};
	border-radius: 9px;
	cursor: pointer;
`;
export const NewslettersModalSwitchCircle = styled.div<ActiveNewslettersProps>`
	position: absolute;
	top: 1px;
	left: ${({ isActive }) => (isActive ? 'unset' : '1px')};
	right: ${({ isActive }) => (isActive ? '1px' : 'unset')};
	height: 14px;
	width: 14px;
	background: ${COLORS.white};
	border-radius: 50%;
`;
/************************
NEWSLETTER TIME
************************/
export const NewsletterTime = styled.div`
	margin-bottom: 4px;
	svg {
		width: 14px;
		margin-right: 6px;
	}
`;
export const NewsletterTimeValue = styled.span`
	font-style: italic;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #a3a3a8;
`;
/************************
NEWSLETTER DESCRIPTION
************************/
export const NewsletterDescription = styled.p`
	font-weight: 300;
	font-size: 14px;
	line-height: 20px;
	color: #49494f;
	margin-bottom: 8px;
`;
/************************
NEWSLETTER PREVIEW
************************/
export const NewsletterPreview = styled.a`
	cursor: pointer;
	align-self: flex-start;
	color: #2a77f6;
	font-weight: 500;
	font-size: 14px;
	line-height: 28px;
	background: #f4f8ff;
	border-radius: 4px;
	padding: 0 17px;
	&:hover {
		background: #dddedf;
		text-decoration: none;
	}
`;

/************************
  SNACKBAR
************************/
const fade = keyframes`
0% {
  opacity: 0;
}
50% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;
export const NewslettersSnackbar = styled.div`
	position: absolute;
	top: -30px;
	left: 0;
	display: flex;
	align-items: center;
	height: 30px;
	width: 100%;
	padding: 5px 10px;
	background: ${COLORS.success};
	color: ${COLORS.white};
	animation: 3s ${fade} ease-in-out;
`;
export const NewslettersIcon = styled(FontAwesomeIcon)`
	margin-right: 8px;
	color: inherit;
`;
export const NotificatiionSnackbarP = styled.p`
	margin: 0;
	font-size: 14.72px;
	line-height: 20px;
`;
/************************
  BUTTONS
************************/
export const NewslettersModalBtnWrapper = styled.div`
	position: sticky;
	bottom: 0;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding: 1.5rem;
	background: ${COLORS.white};
	&:before {
		content: '';
		position: absolute;
		top: -40px;
		left: 0;
		right: 0;
		height: 40px;
		width: 100%;
		background: linear-gradient(
			to top,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}
`;
export const NewslettersModalBtnSave = styled.button`
	${FXSBtnCta};
	width: 115px;
	@media (min-width: 833px) {
		width: 156px;
	}
	&:hover {
		background: #e99f49;
		border: 1px solid #e4871b;
	}
	&:active {
		background: #e99f49;
		border: 1px solid #b66c16;
	}
	&[disabled] {
		background-color: #dddedf;
		color: #fff;
		border: 1px solid #dddedf;
		cursor: not-allowed;
	}
`;
export const NewslettersModalBtnReset = styled.button`
	${FXSBtnPhantom};
	width: 115px;
	background: ${COLORS.white};
	border: 1px solid ${COLORS.dark};
	color: ${COLORS.dark};
	@media (min-width: 833px) {
		width: 156px;
	}
	&:hover {
		background: #dddedf;
	}
	&:active {
		background: #1b1c23;
		color: white;
	}
	&[disabled] {
		background-color: #dddedf;
		color: #fff;
		border: 1px solid #dddedf;
	}
`;
