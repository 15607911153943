import styled from 'styled-components';
import { COLORS } from 'styles/colors';

export const SFilterActive = styled.span<{ isLessThan833: boolean }>`
	position: ${({ isLessThan833 }) =>
		isLessThan833 ? 'relative' : 'absolute'};
	right: ${({ isLessThan833 }) => (isLessThan833 ? '4px' : '5px')};
	top: ${({ isLessThan833 }) => (isLessThan833 ? '-7px' : '6px')};
	display: inline-block;
	height: 7px;
	width: 7px;
	background: ${COLORS.highlight};
	border: 1px solid ${COLORS.grey300};
	border-radius: 50%;
`;
/************************
  MOBILE
************************/
export const SFilterMobileWrapper = styled.footer`
	position: fixed;
	bottom: 0;
	z-index: 1001;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 100%;
	background: ${COLORS.grey200};
	box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
	cursor: pointer;
`;
export const SFilterMobileBtn = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	background: transparent;
	border: none;
	color: ${COLORS.grey};
	font-weight: 700;
	font-size: 14.72px;
	line-height: 20px;
	.svg-inline--fa.fa-sliders-v-square.fa-w-14 {
		margin-left: 6px;
		vertical-align: middle;
		font-size: 1.125rem;
	}
`;
/************************
  DESKTOP
************************/
export const SFilterDesktopBtn = styled.button<{ show: boolean }>`
	display: none;
	@media screen and (min-width: 833px) {
		position: relative;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		line-height: 35px;
		margin-right: 0;
		padding: 7px 9px;
		background: ${(props) =>
		props.show ? `${COLORS.grey200}` : 'transparent'};
		border: none;
		color: ${COLORS.grey};
		font-size: 14.72px;
		line-height: 20px;
		&:hover {
			background-color: ${COLORS.grey200};
		}
		.svg-inline--fa.fa-sliders-v-square.fa-w-14 {
			margin-left: 6px;
			font-size: 1.125rem;
		}
	}
`;
