import React from 'react';
import { Helmet } from 'react-helmet';

export default function CustomHead() {
	return (
		<div>
			<Helmet>
				{process.env.REACT_APP_NEW_RELIC_SNIPPET && (
					<script
						type='text/javascript'
						dangerouslySetInnerHTML={{
							__html: process.env.REACT_APP_NEW_RELIC_SNIPPET
						}}
					/>
				)}
			</Helmet>
		</div>
	);
}
