import styled from 'styled-components';
import { COLORS } from 'styles/colors';
import { TYPOGRAPHY } from 'styles/typography';

export const FXSGoTo = styled.a`
	position: absolute;
	bottom: 0rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 65px;
	max-width: 320px;
	margin: 0 1.375rem 0 1rem;
	color: ${COLORS.blue};
	${TYPOGRAPHY.FXS_hd_tiny};
	font-weight: 700;
	text-decoration: none;
	white-space: nowrap;
	.svg-inline--fa.fa-w-14 {
		color: ${COLORS.blue_brand_highlight_light_4};
	}
	span {
		margin-left: 8px;
		color: ${COLORS.blue_highlight_light_6};
		${TYPOGRAPHY.FXS_hd_tiny};
	}
	&:hover {
		text-decoration: none;
		span,
		.svg-inline--fa.fa-w-14 {
			color: ${COLORS.highlight};
		}
	}
`;
