export type SpeechScoreType = {
	isLocked: boolean;
	score: number;
	scoreLevel: number;
	averageScore: number;
	isVotingMember: boolean;
	averageScoreLevel: number;
};

declare global {
	interface Window {
		getSpeechScore: (eventDate) => SpeechScoreType | null;
	}
}

export const getSpeechScore = function (eventDate) {
	try {
		const SpeechScoreRanges = [
			{ min: 0, max: 3.5, level: 1 },
			{ min: 3.6, max: 6.4, level: 0 },
			{ min: 6.5, max: 10, level: -1 }
		];

		const GetSpeechScore = function (score) {
			if (score === null || isNaN(score)) return null;

			const matchingRange = SpeechScoreRanges.find(
				(range) => score >= range.min && score <= range.max
			);

			return matchingRange ? matchingRange.level : null;
		};

		const result = {} as SpeechScoreType;

		if (eventDate === null) {
			console.error("EventDate hasn't been provided");
			return null;
		}

		const score = eventDate.speech?.score;
		const averageScore = eventDate.speech?.averageScore;
		const isVotingMember = eventDate.speech?.isVotingMember;

		const scoreLevel = GetSpeechScore(score);
		const averageScoreLevel = GetSpeechScore(averageScore);

		result.isLocked = false;
		result.score = score;
		result.scoreLevel = scoreLevel;
		result.averageScore = averageScore;
		result.averageScoreLevel = averageScoreLevel;
		result.isVotingMember = isVotingMember;

		return result;
	} catch (error) {
		console.error('speech-score:' + error);
		return null;
	}
};
