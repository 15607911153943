import { CrudMethod, MessageContentType } from '../models/enums';
import { EditorialMessage, Message } from '../models/signalMessages';
import { Trade } from '../models/trades';

export const getLatestReadMessageDate = (
	data?: Message[]
): string | undefined => {
	if (data) {
		const unread = data.filter((x) => !x.unread);
		if (unread && unread.length > 0) {
			const result = unread[0].dateUtc;
			return result;
		}
	}
	return new Date().toISOString();
};

export const addOlderMessages = (
	olderMessages: Message[],
	data?: Message[]
) => {
	if (!data) return olderMessages;
	const diffOlderMessages = olderMessages.filter(
		(x) => data.findIndex((y) => y.id === x.id) === -1
	);
	return [...data, ...diffOlderMessages];
};

export const addNewerMessages = (
	newerMessages: Message[],
	data?: Message[]
) => {
	if (!data) return newerMessages;
	const diffNewerMessages = newerMessages.filter(
		(x) => data.findIndex((y) => y.id === x.id) === -1
	);
	return [...diffNewerMessages, ...data];
};

const updateMessageInList = (
	list: Message[],
	newMessage: Message
): Message[] => {
	const currentIndex = list.findIndex((s) => s.id === newMessage.id);
	const result = [...list];
	result[currentIndex] = newMessage;
	return result;
};

const deleteMessageInList = (
	list: Message[],
	newMessage: Message
): Message[] => {
	const currentIndex = list.findIndex((s) => s.id === newMessage.id);
	const result = [...list];
	result.splice(currentIndex, 1);
	return result;
};

export const addTradeMessageInList = (
	list: Trade[],
	newMessage: Trade
): Trade[] => {
	const result = [...list];
	if (
		list.some(
			(x) => x.positionMaintenanceId === newMessage.positionMaintenanceId
		)
	) {
		const signalIndex = list.findIndex(
			(o) => o.positionMaintenanceId === newMessage.positionMaintenanceId
		);
		if (signalIndex > -1) {
			result[signalIndex] = newMessage;
		}
	} else {
		result.unshift(newMessage);
	}

	return result;
};

export const addOrUpdateMessage = (
	newMessage: EditorialMessage,
	method: CrudMethod,
	data?: Message[]
) => {
	if (!data) return undefined;

	switch (method) {
		case CrudMethod.Create:
			return [newMessage, ...data];

		case CrudMethod.Update:
			return updateMessageInList(data, newMessage);

		case CrudMethod.Delete:
			return deleteMessageInList(data, newMessage);

		default:
			return data;
	}
};

export const updateOffset = (offset: number, method: CrudMethod) => {
	switch (method) {
		case CrudMethod.Create:
			return offset + 1;
		case CrudMethod.Delete:
			return offset - 1;
		default:
			return offset;
	}
};

export const markAsRead = (messageId: string, data?: Message[]) => {
	if (!data) return undefined;

	let currentIndex = data.findIndex((s) => s.id === messageId);
	const result = [...data];
	while (currentIndex < data.length && data[currentIndex].unread) {
		result[currentIndex] = { ...data[currentIndex], unread: false };
		currentIndex += 1;
	}
	return result;
};

export const markAllMessages = (data?: Message[]) => {
	if (!data) return undefined;

	const result = [...data];
	for (let i = 0; i < data.length; i += 1) {
		if (!data[i].unread) break;
		result[i] = { ...data[i], unread: false };
	}

	return result;
};

export const getWelcomeMessage = (): Message => {
	return {
		dateUtc: new Date().toISOString(),
		id: 'welcomeMessage',
		contentType: MessageContentType.Welcome,
		unread: false,
		liked: false,
		bookmarked: false
	};
};
