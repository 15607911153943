import { useContext, useEffect, useState } from 'react';

import { FullWidthMainLayout } from 'components/Layouts';
import { FullWidthMainLayoutInner } from 'components/Layouts/styles';
import { RecordingsHeader } from 'components/RecordingsHeader';
import { ConfigContext } from 'contexts/configContext';
import { PushContext } from 'contexts/pushContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { usePostPush } from 'data/usePostPush';
import { Recordings } from 'fxs-recordings';
import useSound from 'use-sound';

export default function RecordingsPage() {
	const { t } = useTranslationsContext();

	const { user } = useContext(UserContext);
	const connected = useContext(PushContext);
	const [soundOn, setSoundOn] = useState(user?.isPremium);
	const { config } = useContext(ConfigContext);

	const [play, { stop }] = useSound(config.Variables.NotificationSoundUrl);

	const count = usePostPush(
		connected,
		user?.isPremium,
		'VideoPushNotification'
	);

	useEffect(() => {
		if (soundOn) play();
		return () => {
			stop();
		};
	}, [count]);

	return (
		<FullWidthMainLayout>
			<FullWidthMainLayoutInner>
				<RecordingsHeader>{t('latestVideos')}</RecordingsHeader>
				<Recordings
					appId={config.Variables.AlgoliaAppId}
					apiKey={config.Variables.AlgoliaApiKey}
					indexName={config.Variables.AlgoliaIndex}
					hitsPerPage={25}
					culture={config.Variables.Culture}
					t={t}
					onToggleNotificationsSound={(activated) => {
						setSoundOn(activated);
					}}
				/>
			</FullWidthMainLayoutInner>
		</FullWidthMainLayout>
	);
}
