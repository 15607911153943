import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { EmbededMarketBias } from 'components/EmbededMarketBias';
import { FullWidthMainLayout } from 'components/Layouts';
import { SIGNALS } from 'constants/locations';
import { ConfigContext } from 'contexts/configContext';
import { PushContext } from 'contexts/pushContext';
import { TradesContext } from 'contexts/tradesContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { useSignalPush } from 'data/useSignalPush';
import { useTradePlanPush } from 'data/useTradePlanPush';
import { SignalsTradePlan } from 'fxs-signals-pre-order';
import SignalsTable from 'fxs-signals-table';
import { mapFromPush } from 'mappers/tradePlan';
import { mapFromApi } from 'mappers/trades';
import { StatusType } from 'models/enums';
import { FXSPageTitle } from 'styles/mixins';
import { FXS_hd_med } from 'styles/typography';
import useSound from 'use-sound';
import { addTradeMessageInList } from 'utils/messages';
import { addOrUpdateTradePlan } from 'utils/tradePlans';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function navigateToChip(positionMaintenanceId: string) {
	const highlightChip = document.getElementById(positionMaintenanceId);
	const signalsTableWrapper = document.getElementById('SignalsTableWrapper');
	if (highlightChip && signalsTableWrapper) {
		signalsTableWrapper.scrollTop = highlightChip.offsetTop - 100;
	}
}

export default function Signals() {
	const { t } = useTranslationsContext();

	const { trades, tradePlans, saveTrades, saveTradePlans } =
		useContext(TradesContext);
	const [signals, saveSignals] = useState(null);
	const connected = useContext(PushContext);
	const { user } = useContext(UserContext);
	const [soundOn, setSoundOn] = useState(user?.isPremium);
	const { config } = useContext(ConfigContext);

	const [play] = useSound(config.Variables.NotificationSoundUrl);
	const tradeFromPush = useSignalPush(connected, user?.isPremium);
	const tradePlanFromPush = useTradePlanPush(connected);
	const query = useQuery();

	const [defaultShowOpenTab, setDefaultShowOpenTab] = useState(true);

	useEffect(() => {
		setSoundOn(user?.isPremium);
	}, [user]);

	useEffect(() => {
		if (!signals) return;
		const positionMaintenanceId = query.get('highlightedSignal');
		if (positionMaintenanceId) navigateToChip(positionMaintenanceId);
	}, [signals]);

	useEffect(() => {
		if (!trades) return;
		const positionMaintenanceId = query.get('highlightedSignal');
		const signalsResponse = trades && trades.map((x) => mapFromApi(x));
		if (positionMaintenanceId && signalsResponse) {
			const currentIndex = signalsResponse.findIndex(
				(s) => s.positionMaintenanceId === positionMaintenanceId
			);
			if (currentIndex === -1) {
				saveSignals(signalsResponse);
				return;
			}

			const isHighlighted = true;
			const current = signalsResponse[currentIndex];
			if (current.status !== StatusType.Running)
				setDefaultShowOpenTab(false);
			const merged = {
				...current,
				isHighlighted
			};
			const result = [...signalsResponse];
			result[currentIndex] = merged;

			saveSignals(result);
		} else {
			saveSignals(signalsResponse);
		}
	}, [trades, defaultShowOpenTab]);

	useEffect(() => {
		setSoundOn(user?.isPremium);
	}, [user]);

	useEffect(() => {
		if (soundOn) {
			play();
		}
		if (tradeFromPush) {
			const newTrades = addTradeMessageInList(trades, tradeFromPush);
			saveTrades([...newTrades]);
		}

		if (tradePlanFromPush) {
			const { tradePlan, method } = tradePlanFromPush;
			const mappedTradePlanFromPush = mapFromPush(tradePlan);
			const newTradePlan = addOrUpdateTradePlan(
				mappedTradePlanFromPush,
				method,
				tradePlans
			);

			saveTradePlans([...newTradePlan]);
		}
	}, [tradeFromPush, tradePlanFromPush]);

	const toggleSound = (activated: boolean) => {
		setSoundOn(activated);
	};

	return (
		<FullWidthMainLayout>
			<FXSPageTitle>
				<FXS_hd_med>{t('signalsPageTitle')}</FXS_hd_med>
			</FXSPageTitle>

			<EmbededMarketBias />

			{tradePlans && (
				<SignalsTradePlan
					data={tradePlans}
					isPremium={user?.isPremium}
					signalPreOrderInsightPage={`/${SIGNALS}`}
					t={t}
					tradingViewUrl={config.Variables.TradingViewUrl}
				/>
			)}
			<SignalsTable
				isPremium={user?.isPremium}
				data={signals}
				error={false}
				isFetching={!trades}
				t={t}
				nofiticationsEnabled={user?.isPremium}
				onNotificationSoundActivated={toggleSound}
				signalsFaqPage={`${config.Variables.FxsSite}/signals/faq`}
				becomePremium={`${config.Variables.FxsSite}/info/premium?utm_source=trading-studio&utm_medium=internal&utm_campaign=tradingstudio-no-premium`}
				defaultShowOpenTab={defaultShowOpenTab}
				tradingViewUrl={config.Variables.TradingViewUrl}
			/>
		</FullWidthMainLayout>
	);
}
