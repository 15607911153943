import React from 'react';
import { COLORS } from 'styles/colors';

const IconFaq = React.memo(function () {
	return (
		<svg
			width='24'
			height='24'
			viewBox='0 0 18 18'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				className='icon'
				d='M9 17.9688C13.7812 17.9688 17.7188 14.0664 17.7188 9.25C17.7188 4.46875 13.7812 0.53125 9 0.53125C4.18359 0.53125 0.28125 4.46875 0.28125 9.25C0.28125 14.0664 4.18359 17.9688 9 17.9688ZM5.34375 15.2969L7.24219 13.3984C8.33203 13.8906 9.63281 13.8906 10.7227 13.3984L12.6211 15.2969C10.4062 16.6328 7.55859 16.6328 5.34375 15.2969ZM11.8125 9.25C11.8125 10.832 10.5469 12.0625 9 12.0625C7.41797 12.0625 6.1875 10.832 6.1875 9.25C6.1875 7.70312 7.41797 6.4375 9 6.4375C10.5469 6.4375 11.8125 7.70312 11.8125 9.25ZM15.0117 12.9062L13.1133 11.0078C13.6055 9.91797 13.6055 8.61719 13.1133 7.52734L15.0117 5.62891C16.3477 7.84375 16.3477 10.6914 15.0117 12.9062ZM12.6211 3.23828L10.7227 5.13672C9.63281 4.64453 8.33203 4.64453 7.24219 5.13672L5.34375 3.23828C7.55859 1.90234 10.4062 1.90234 12.6211 3.23828ZM2.95312 5.62891L4.85156 7.52734C4.35938 8.61719 4.35938 9.91797 4.85156 11.0078L2.95312 12.9062C1.61719 10.6914 1.61719 7.84375 2.95312 5.62891Z'
				fill={COLORS.grey}
			/>
		</svg>
	);
});

export default IconFaq;
