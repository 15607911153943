import {
	DurationType,
	SignalStrategyStats
} from 'fxs-signal-strategy-stats-table';

import { StatCalculationResponse } from '../models/strategyStats';

export const mapStats = (
	source: StatCalculationResponse
): SignalStrategyStats => ({
	statCalculation: {
		averageDuration: new DurationType(source.averageDuration),
		numberOfTrades: source.numberOfTrades,
		netProfit: source.netProfit,
		winRate: source.winRate,
		lossRate: source.lossRate,
		numberOfWinningTrades: source.numberOfWinningTrades,
		numberOfLosingTrades: source.numberOfLosingTrades,
		grossProfit: source.grossProfit,
		grossLoss: source.grossLoss,
		averageProfit: source.averageProfit,
		averageLoss: source.averageLoss
	}
});
