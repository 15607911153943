import { useEffect, useState } from 'react';

import useSWR from 'swr';

import { SignalStrategyInfo } from '../models/strategy';
import { getUser } from '../utils/getUser';

const fetcher = (strategyId: string) => async () => {
	return getUser().then(async (user) => {
		const res = await fetch(
			`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.SignalStrategyStrategyId.replace('{strategyId}', strategyId)}`,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			}
		);

		return await res.json();
	});
};

export function useStrategyInfo(strategyId: string): SignalStrategyInfo {
	const [strategyInfo, setStrategyInfo] = useState(null);

	const { data, error } = useSWR(
		['getStrategyInfo', window.config.Variables.Culture, strategyId],
		fetcher(strategyId)
	);

	if (error) console.log(error);

	useEffect(() => {
		setStrategyInfo(data);
	}, [data]);

	return strategyInfo;
}
