import { configMapper } from '../mappers/configMapper';
import { Config } from '../models/config';

const fetcher = async () => {
	return await fetch(
		`${process.env.REACT_APP_GATEWAY_URL}/api/v1/config/tradingStudioApp`,
		{
			method: 'GET',
			headers: { Accept: 'application/json' }
		}
	).then(async (res) => {
		const data = await res.json();
		return configMapper(data);
	});
};

export async function getConfig(): Promise<Config> {
	return await fetcher();
}
