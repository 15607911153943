import {
	MESSAGES_PER_REQUEST,
	NUM_UNREAD_MESSAGES
} from '../constants/signalMessages';
import { FilterState } from '../contexts/filtersContext';
import { mapSignalsMessages } from '../mappers/signalMessages';
import { EditorialCategoryType } from '../models/enums';
import {
	Message,
	MessagePayload,
	MessageResponse
} from '../models/signalMessages';
import { getUser } from '../utils/getUser';
import { getWelcomeMessage } from '../utils/messages';

function getResourceUrl(date?: string, filters?: FilterState, limit?: number) {
	let url;
	if (limit) {
		url = `limit=${date ? limit + NUM_UNREAD_MESSAGES : limit}`;
	} else {
		url = `limit=${date ? MESSAGES_PER_REQUEST + NUM_UNREAD_MESSAGES : MESSAGES_PER_REQUEST}`;
	}

	if (date)
		url += `&offset=${-NUM_UNREAD_MESSAGES}&dateTime=${
			new Date(date) > new Date() ? new Date().toISOString() : date
		}`;
	if (filters?.categories?.length > 0)
		if (filters.categories.includes(EditorialCategoryType.Trade)) {
			url += '&categories=trade';
			return url;
		}
	filters?.categories.map((filter) => (url += `&categories=${filter}`));
	if (filters?.markets?.length > 0)
		filters.markets.map((filter) => (url += `&markets=${filter}`));
	if (filters?.tags?.length > 0)
		filters.tags.map((filter) => (url += `&tags=${filter}`));
	return url;
}

const fetcher = async (
	date?: string,
	filters?: FilterState,
	limit?: number
): Promise<MessagePayload> => {
	return getUser().then(async (user) => {
		const apiEndpointUrl = `${process.env.REACT_APP_GATEWAY_URL}${window.config.Endpoints.SignalMessages}`;
		try {
			const resourceUrl = getResourceUrl(date, filters, limit);
			const response = await fetch(`${apiEndpointUrl}?${resourceUrl}`, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			});
			if (response.ok) {
				const mappedMessages = (await response.json())?.map(
					(x: MessageResponse) =>
						mapSignalsMessages(
							x,
							date ? new Date(x.dateUtc) > new Date(date) : false
						)
				) as Message[];
				const showWelcome = date === undefined;
				if (showWelcome) {
					mappedMessages.unshift(getWelcomeMessage());
				}
				const noMoreNewer = date
					? mappedMessages.length <
						MESSAGES_PER_REQUEST + NUM_UNREAD_MESSAGES
					: true;
				return { data: mappedMessages, noMoreNewer } as MessagePayload;
			}
			if (response.status === 404) return { data: [] } as MessagePayload;
			return { data: undefined } as MessagePayload;
		} catch (error) {
			console.log(error);
			return null;
		}
	});
};

export function getSignalsMessages(
	date?: string,
	filters?: FilterState,
	limit?: number
): Promise<MessagePayload> {
	return fetcher(date, filters, limit);
}
