import { useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import {
	NEW_PREMIUM_USER,
	NEW_PREMIUM_USER_THREE_DAYS_PREMIUM,
	NEW_USER_SITE,
	NEW_USER_SITE_NEW_PREMIUM_USER,
	NEW_USER_SITE_PREMIUM_AND_THREE_DAYS_PREMIUM,
	NEW_USER_SITE_SEVEN_DAYS_USER_SITE,
	SEVEN_DAYS_USER_SITE,
	THREE_DAYS_COOKIE_NAME,
	THREE_DAYS_PREMIUM,
	THREE_DAYS_PREMIUM_CHANNEL,
	THREE_DAYS_USER,
	TYPEFORM_BUTTON_COLOR,
	TYPEFORM_EVENT_NAME,
	TYPEFORM_TOOLTIP,
	TYPEFORM_TYPE
} from 'constants/TypeformInfo';
import { HOURS, MILLISECONDS, MINUTES, SECONDS } from 'constants/time';
import { ConfigContext } from 'contexts/configContext';
import { getTypeformUserState } from 'data/getUserTypeformState';

import { getCookie } from './getUser';

export function useTypeformToDatalayer(): void {
	const { config } = useContext(ConfigContext);

	const typeformOptions = {
		dataLayer: {
			event: TYPEFORM_EVENT_NAME,
			tf_type: TYPEFORM_TYPE,
			tf_id: config.Variables.TypeformId,
			tf_options: {
				hidden: {
					channel: THREE_DAYS_PREMIUM_CHANNEL,
					email: window.userInfo.email,
					uid: window.userInfo.id,
					userstatus: undefined
				},
				buttonColor: TYPEFORM_BUTTON_COLOR,
				tooltip: TYPEFORM_TOOLTIP
			}
		}
	};

	useEffect(() => {
		const dc = getCookie(THREE_DAYS_COOKIE_NAME);
		const cookieDate = new Date(dc);
		const dateDiff = dateNowDiff(cookieDate);
		if (
			((dc !== undefined && dateDiff >= THREE_DAYS_USER) ||
				dc === undefined) &&
			window.userInfo.isPremium
		) {
			getTypeformUserState(window.userInfo.email).then((response) => {
				if (response !== null) {
					switch (response.Status.toUpperCase()) {
						case NEW_USER_SITE:
							typeformOptions.dataLayer.tf_options.hidden.userstatus =
								NEW_PREMIUM_USER_THREE_DAYS_PREMIUM;
							TagManager.dataLayer(typeformOptions);
							break;
						case NEW_PREMIUM_USER:
							typeformOptions.dataLayer.tf_options.hidden.userstatus =
								THREE_DAYS_PREMIUM;
							TagManager.dataLayer(typeformOptions);
							break;
						case NEW_USER_SITE_NEW_PREMIUM_USER:
							typeformOptions.dataLayer.tf_options.hidden.userstatus =
								THREE_DAYS_PREMIUM;
							TagManager.dataLayer(typeformOptions);
							break;
						case SEVEN_DAYS_USER_SITE:
							typeformOptions.dataLayer.tf_options.hidden.userstatus =
								NEW_PREMIUM_USER_THREE_DAYS_PREMIUM;
							TagManager.dataLayer(typeformOptions);
							break;
						case NEW_USER_SITE_SEVEN_DAYS_USER_SITE:
							typeformOptions.dataLayer.tf_options.hidden.userstatus =
								NEW_PREMIUM_USER_THREE_DAYS_PREMIUM;
							TagManager.dataLayer(typeformOptions);
							break;
						default:
							break;
					}
				} else {
					typeformOptions.dataLayer.tf_options.hidden.userstatus =
						NEW_USER_SITE_PREMIUM_AND_THREE_DAYS_PREMIUM;
					TagManager.dataLayer(typeformOptions);
				}
			});
		}
	}, []);
}

function dateNowDiff(cookieDate: Date) {
	const dateNow = new Date();
	const diffTime = Math.abs(dateNow.getTime() - cookieDate.getTime());
	const diffDays = Math.ceil(
		diffTime / (MILLISECONDS * SECONDS * MINUTES * HOURS)
	);
	return diffDays;
}

export default useTypeformToDatalayer;
