import { COLORS } from 'styles/colors';

export default function IconHamburguer() {
	return (
		<svg
			width='35px'
			height='30px'
			viewBox='0 0 22 19'
			fill={COLORS.white}
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M1.65625 3.80469C1.25195 3.80469 0.9375 3.49023 0.9375 3.08594V1.28906C0.9375 0.929688 1.25195 0.570312 1.65625 0.570312H20.3438C20.7031 0.570312 21.0625 0.929688 21.0625 1.28906V3.08594C21.0625 3.49023 20.7031 3.80469 20.3438 3.80469H1.65625ZM1.65625 10.9922C1.25195 10.9922 0.9375 10.6777 0.9375 10.2734V8.47656C0.9375 8.11719 1.25195 7.75781 1.65625 7.75781H20.3438C20.7031 7.75781 21.0625 8.11719 21.0625 8.47656V10.2734C21.0625 10.6777 20.7031 10.9922 20.3438 10.9922H1.65625ZM1.65625 18.1797C1.25195 18.1797 0.9375 17.8652 0.9375 17.4609V15.6641C0.9375 15.3047 1.25195 14.9453 1.65625 14.9453H20.3438C20.7031 14.9453 21.0625 15.3047 21.0625 15.6641V17.4609C21.0625 17.8652 20.7031 18.1797 20.3438 18.1797H1.65625Z' />
		</svg>
	);
}
