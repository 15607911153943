import { Loading } from 'fxs-loading';

import { Division } from './styles';

export default function Spinner() {
	return (
		<>
			<Division>
				<Loading size='3x' />
			</Division>
		</>
	);
}
