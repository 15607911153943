import { Trade } from '../models/trades';
import { getUser } from '../utils/getUser';

const fetcher = async () => {
	return getUser().then((user) => {
		return fetch(
			`${process.env.REACT_APP_GATEWAY_URL}/${window.config.Variables.Culture}${window.config.Endpoints.Signals}`,
			{
				method: 'GET',
				headers: {
					Accept: 'application/json',
					Authorization: `bearer ${user?.token}`
				}
			}
		).then((res) => {
			if (res.ok) return res.json();

			if (res.status === 404) return [];
		});
	});
};

export async function getTrades(): Promise<Trade[]> {
	return fetcher();
}
