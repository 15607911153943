import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconFaq from 'components/icons/iconFaq';
import { useTranslationsContext } from 'contexts/translationsContext';
import { TranslateProps } from 'models/props';
import { isLessThan } from 'utils/useWindowWidth';
import FaqBtnStyles from './styles';
import { HeaderTooltip } from 'components/Header/HeaderBtn/styles';

const FaqBtn: React.FC<TranslateProps> = () => {
	const { t } = useTranslationsContext();

	const isLessThan833 = isLessThan(833);
	const [showTooltip, setShowTooltip] = useState(null);

	return (
		<>
			<FaqBtnStyles />

			{isLessThan833 ? (
				<Link to={'/faq'} className={'faq-button'}>
					<IconFaq />
					<span>{t('FAQ')}</span>
				</Link>
			) : (
				<Link
					to={'/faq'}
					className={'faq-button'}
					onMouseEnter={() => {
						setShowTooltip(true);
					}}
					onMouseLeave={() => {
						setShowTooltip(false);
					}}
				>
					<IconFaq />
					{showTooltip && (
						<HeaderTooltip>
							<span>FAQ</span>
						</HeaderTooltip>
					)}
				</Link>
			)}
		</>
	);
};

export const MemoFaqBtn = React.memo(FaqBtn);
