import { useTranslationsContext } from 'contexts/translationsContext';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	PopUpContainer,
	PopUpLink,
	PopUpTitle,
	PopUpWrapper,
	PopUpWrapperIcon
} from './styles';

export default function UserNoPremium() {
	const { t } = useTranslationsContext();

	return (
		<PopUpContainer>
			<PopUpWrapper>
				<PopUpWrapperIcon>
					<FontAwesomeIcon icon={faLockAlt as IconProp} />
				</PopUpWrapperIcon>
				<div style={{ marginLeft: '1rem' }}>
					<PopUpTitle>{t('exclusivePremiumContent')}</PopUpTitle>
					<PopUpLink
						data-ga4event-clk-name='tradingstudio_tooltip_click'
						data-ga4event-element='learn_more_about_premium'
						href={`${window.config.Variables.FxsSite}/info/premium?utm_source=trading-studio&utm_medium=internal&utm_campaign=tradingstudio-no-premium`}
						rel='noopener noreferrer nofollow'
					>
						{t('learnMoreAboutPremium')}
					</PopUpLink>
				</div>
			</PopUpWrapper>
		</PopUpContainer>
	);
}
