import React from 'react';

import { CATEGORIES, MARKETS, TAGS } from '../constants/filter';
import { EditorialCategoryType, EditorialMarketType } from '../models/enums';

type ContextProps = {
	filters: FilterState;
	saveFilters(newState: FilterState): void;
	clearFilters(): void;
};

export type FilterState = {
	[CATEGORIES]: EditorialCategoryType[];
	[MARKETS]: EditorialMarketType[];
	[TAGS]: string[];
};

export const initialFilterState: FilterState = {
	categories: [] as EditorialCategoryType[],
	markets: [] as EditorialMarketType[],
	tags: []
};

export const FilterContext = React.createContext<ContextProps>(null);
