import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faAnalytics,
	faExclamationCircle,
	faFileSearch,
	faUserChart
} from '@fortawesome/pro-solid-svg-icons';

import { EditorialMarketType } from '../models/enums';

export type MarketProps = {
	id: number;
	icon: IconProp;
	label: string;
	value: EditorialMarketType;
};

export const Markets: MarketProps[] = [
	{
		id: 11,
		icon: faUserChart as IconProp,
		label: 'forexType',
		value: EditorialMarketType.Forex
	},
	{
		id: 12,
		icon: faFileSearch as IconProp,
		label: 'cryptosType',
		value: EditorialMarketType.Cryptos
	},
	{
		id: 13,
		icon: faExclamationCircle as IconProp,
		label: 'stocksType',
		value: EditorialMarketType.Stocks
	},
	{
		id: 14,
		icon: faAnalytics as IconProp,
		label: 'commoditiesType',
		value: EditorialMarketType.Commodities
	}
];
