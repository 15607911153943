import React from 'react';

import { MemoFaqBtn } from 'components/FaqBtn';
import NavMenu from 'components/NavMenu';
import NewsletterBtn from 'components/NewsletterBtn';
import NotificationsBtn from 'components/NotificationsBtn';
import { DashboardProps } from 'models/props';
import { isLessThan } from 'utils/useWindowWidth';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import GoToFXS from '../GoToFXS';
import {
	CloseIcon,
	FXSBackdrop,
	FXSPremiumLogoDesktop,
	FXSSidebarDesktop,
	FXSSidebarMobile,
	FXSSidebarTop
} from './styles';

export default function SidebarDesktop({
	isLateralMobileMenuOpened,
	setLateralMobileMenuStatus,
	setIsNotificationsModalOpened,
	setIsNewslettersModalOpened
}: DashboardProps) {
	const isLessThan833 = isLessThan(833);

	const handleClick = (
		e: React.MouseEvent<HTMLElement | SVGElement, MouseEvent>
	) => {
		if ((e.target as HTMLButtonElement)?.tagName.toLowerCase() === 'button')
			return;

		e.stopPropagation();
		setLateralMobileMenuStatus(false);
	};

	return isLessThan833 ? (
		<FXSBackdrop
			isLateralMobileMenuOpened={isLateralMobileMenuOpened}
			onClick={handleClick}
		>
			<FXSSidebarMobile>
				<FXSSidebarTop>
					<CloseIcon
						icon={faTimes as IconProp}
						onClick={handleClick}
					/>
					<NewsletterBtn
						setIsNewslettersModalOpened={
							setIsNewslettersModalOpened
						}
					/>
					{window.config.Variables.NotificationEnabled && (
						<NotificationsBtn
							setIsNotificationsModalOpened={
								setIsNotificationsModalOpened
							}
						/>
					)}
					<MemoFaqBtn />
				</FXSSidebarTop>
				<NavMenu />
				<GoToFXS />
			</FXSSidebarMobile>
		</FXSBackdrop>
	) : (
		<FXSSidebarDesktop>
			<FXSPremiumLogoDesktop
				src={`${window.config.Variables.StaticContentBaseUrl}logos/PremiumOfficialTransparentBackgroundLogo.svg`}
			/>
			<NavMenu />
			<GoToFXS />
		</FXSSidebarDesktop>
	);
}

export const MemSidebarDesktop = React.memo(SidebarDesktop);
