import { useContext, useEffect, useState } from 'react';

import { INSIGHTS } from 'constants/locations';
import { useTranslationsContext } from 'contexts/translationsContext';
import { UserContext } from 'contexts/userContext';
import { getPopularSignals } from 'data/getPopularSignals';
import { getEventFollow } from 'data/getUserEventFollow';
import { setBookmarkedSignals } from 'data/setBookmarkedSignals';
import { setLikedSignals } from 'data/setLikedSignals';
import { setTerms } from 'data/setTerms';
import { setEventFollow } from 'data/setUserEventFollow';
import * as fxsSignalsInsights from 'fxs-signals-insights';
import { SignalsMessageState } from 'models/signalMessages';

import { FXSInsightsWrapper } from './styles';

const initialState: SignalsMessageState = {
	messages: undefined,
	isFetching: true
};

const SignalsInsightsPopular = () => {
	const { user } = useContext(UserContext);
	const { t } = useTranslationsContext();

	const culture = window.config.Variables.Culture;
	const [mostLikedData, setMostLikedData] = useState(initialState);

	useEffect(() => {
		getPopularSignals().then((payload) => {
			setMostLikedData({ messages: payload.data, isFetching: false });
		});
	}, []);

	return (
		<FXSInsightsWrapper>
			<fxsSignalsInsights.SignalsInsights
				isPremium={user?.isPremium}
				data={mostLikedData.messages}
				isFetching={mostLikedData.isFetching}
				t={t}
				culture={culture}
				signalsPageUrl={window.config.Variables.SignalsLandingUrl}
				setLike={(id, status) => setLikedSignals(id, status)}
				setBookmark={(id, status) => setBookmarkedSignals(id, status)}
				getEventFollow={(eventId) => getEventFollow(eventId)}
				setEventFollow={(eventId, eventTitle) =>
					setEventFollow(eventId, eventTitle)
				}
				setTerms={(eventTitle) => setTerms(eventTitle)}
				messagePageUrl={`/${INSIGHTS}/`}
				isEmployee={user?.isEmployee}
			/>
		</FXSInsightsWrapper>
	);
};

export default SignalsInsightsPopular;
