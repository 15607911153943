import { TypeformSettingsFilterResponse } from 'models/userSettings';

export const mapTypeformUserState = (
	source: any
): TypeformSettingsFilterResponse => ({
	Channel: source.channel,
	DateUtc: source.dateUtc,
	Email: source.email,
	IsSurveyFilled: source.isSurveyFilled,
	Status: source.status,
	UserId: source.userId
});
