import Routes from 'Routes';

import { useContext, useEffect } from 'react';
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch
} from 'react-router-dom';

import S2P from 'components/S2P';
import { DEFAULT_STRATEGY_ID } from 'constants/strategies';
import { AuthorContextProvider } from 'context-providers/authorsContextProvider';
import Compose from 'context-providers/compose';
import { FilterContextProvider } from 'context-providers/filterContextProvider';
import { PushContextProvider } from 'context-providers/pushContextProvider';
import { SignalsInsightsContextProvider } from 'context-providers/signalsInsightsContextProvider';
import { StrategyFullStatsContextProvider } from 'context-providers/strategyFullStatsContextProvider';
import { TradesContextProvider } from 'context-providers/tradesContextProvider';
import { useSplashScreenContext } from 'contexts/SplashScreenContext';
import { UserContext } from 'contexts/userContext';

import Home from '.';
import Calendar from './Calendar';
import FaqPage from './faq';
import SignalInsight from './insight';
import Masterclass from './masterclass';
import PreSignalInfo from './pre-signal-info';
import RecordingsPage from './recordings';
import Signals from './signals';
import Strategies from './strategies';
import Webinars from './webinars';

function App() {
	const { user } = useContext(UserContext);
	const { isPremium } = user;
	const { setIsLoading } = useSplashScreenContext();

	useEffect(() => {
		/**
		 * Remove splash screen after app is loaded correctly
		 */
		setIsLoading(false);
	});

	return (
		<>
			<Router>
				<Switch>
					<Route
						exact
						path={[
							Routes.HOME,
							Routes.POPULAR,
							Routes.BOOKMARKS,
							Routes.LIVE_COVERAGE
						]}
					>
						<Compose
							components={[
								PushContextProvider,
								TradesContextProvider,
								FilterContextProvider,
								SignalsInsightsContextProvider,
								StrategyFullStatsContextProvider
							]}
						>
							<Home isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={`${Routes.STRATEGIES}:id`}>
						<Compose
							components={[
								PushContextProvider,
								StrategyFullStatsContextProvider
							]}
						>
							<Strategies isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={Routes.STRATEGIES}>
						<Compose
							components={[
								PushContextProvider,
								StrategyFullStatsContextProvider
							]}
						>
							<Redirect
								to={`${Routes.STRATEGIES}${DEFAULT_STRATEGY_ID}`}
							/>{' '}
							: <Strategies isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={Routes.SIGNALS}>
						<Compose
							components={[
								PushContextProvider,
								TradesContextProvider
							]}
						>
							<Signals />
						</Compose>
					</Route>
					<Route exact path={`${Routes.SIGNALS}/pre_order`}>
						<Compose
							components={[
								PushContextProvider,
								TradesContextProvider,
								StrategyFullStatsContextProvider
							]}
						>
							<PreSignalInfo isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={Routes.WEBINARS}>
						<Compose components={[AuthorContextProvider]}>
							<Webinars />
						</Compose>
					</Route>
					<Route exact path={Routes.CALENDAR}>
						<Compose components={[]}>
							<Calendar isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={Routes.RECORDINGS}>
						<Compose
							components={[
								PushContextProvider,
								AuthorContextProvider
							]}
						>
							<RecordingsPage />
						</Compose>
					</Route>
					{isPremium && (
						<Route path={Routes.MASTERCLASS}>
							<Masterclass />
						</Route>
					)}
					<Route path={`${Routes.INSIGHTS}:id`}>
						<Compose
							components={[
								PushContextProvider,
								SignalsInsightsContextProvider,
								TradesContextProvider,
								StrategyFullStatsContextProvider
							]}
						>
							<SignalInsight isPremium={isPremium} />
						</Compose>
					</Route>
					<Route exact path={Routes.FAQ}>
						<FaqPage />
					</Route>
				</Switch>
			</Router>
			<S2P />
		</>
	);
}

export default App;
