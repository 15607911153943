import { useLayoutEffect } from 'react';

import { DivJoinTab } from 'components/Header/styles';
import { isLessThan } from 'fxs-utils';
import { getCookie } from 'utils/getUser';

import { ContextProps, useDiscordContext } from '../Context/DiscordContext';
import { getElementOffset } from '../tools/getElementOffset';

export default function JoinTab() {
	const { isOpen, setIsOpen } = useDiscordContext() as ContextProps;
	const discordCookie = getCookie('discordPopup') ? true : false;

	const { isDiscord } = useDiscordContext() as ContextProps;
	const isLessThan833 = isLessThan(833);

	const alignTab = () => {
		const el = document?.getElementById('discord-btn-container');
		const tab = document?.getElementById('join-tab-container');
		if (el && tab && !isDiscord && !isLessThan833) {
			const elWidth = el.offsetWidth / 2;
			const elOffsetLeft = getElementOffset(el).left;
			const elTotal = elWidth + elOffsetLeft;
			const windowWidth = window.innerWidth;
			const tabWidth = tab.offsetWidth / 2;
			const coordFromWindow = windowWidth - elTotal;
			tab.style.right = `${Math.round(coordFromWindow - tabWidth)}px`;
		}
	};

	useLayoutEffect(() => {
		alignTab();
	}, [isOpen, isLessThan833]);

	return (
		<DivJoinTab
			id='join-tab-container'
			className={`${isOpen || discordCookie ? 'popup-open' : ''} join-tab`}
			onClick={() => setIsOpen(!isOpen)}
		>
			<p>
				<span></span> Join our live community
			</p>
		</DivJoinTab>
	);
}
