import { FXS_EMAIL_DOMAIN } from 'constants/email';

import { User } from '../models/user';

let userHash = null;
let currentUser = null;
let getUserPromise = null;

export function getCookie(name) {
	const dc = document.cookie;
	const prefix = `${name}=`;
	let begin = dc.indexOf(`; ${prefix}`);
	if (begin === -1) {
		begin = dc.indexOf(prefix);
		if (begin !== 0) return undefined;
	} else {
		begin += 2;
	}
	let end = document.cookie.indexOf(';', begin);
	if (end === -1) {
		end = dc.length;
	}
	return decodeURI(dc.substring(begin + prefix.length, end));
}

export async function getUser(): Promise<User> {
	if (!userHash) {
		userHash = getCookie(window.config.Variables.AuthenticationCookie);
	}

	if (currentUser) return currentUser;
	if (getUserPromise) return getUserPromise;

	getUserPromise = await fetch(
		`${window.config.Variables.FxsSite}/api/userapi/get`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', 'fxs-user-hash': userHash }
		}
	)
		.then((res) => {
			getUserPromise = null;
			if (res.status === 200) {
				return res.json().then((user) => {
					currentUser = {
						id: user.Id,
						isPremium: user.IsPremium,
						isLogged: user.IsLogged,
						email: user.Email,
						phone: user.Phone,
						country: user.CountryCode,
						name: user.FirstName,
						token: user.Token?.Access_token,
						isEmployee: user.Email.endsWith(FXS_EMAIL_DOMAIN),
						emailEncrypted: user.EmailEncrypted
					};
					window.userInfo = currentUser;
					return currentUser;
				});
			}
			return null;
		})
		.catch((err) => console.log(err));

	return getUserPromise;
}
