export const THREE_DAYS_USER = 3;
export const THREE_DAYS_COOKIE_NAME = 'premiumDate';
export const THREE_DAYS_PREMIUM_CHANNEL = 'tradingStudio';
export const ONBOARDING_CHANNEL = 'onboarding';
export const TYPEFORM_TYPE = 'popup';
export const TYPEFORM_TOOLTIP =
	'Tell us a bit about yourself to help us create the best content for you 👉';
export const TYPEFORM_BUTTON_COLOR = '#242D38';
export const TYPEFORM_EVENT_NAME = 'show_typeform';

export const NEW_USER_SITE = 'NUS';
export const NEW_PREMIUM_USER = 'NPU';
export const NEW_USER_SITE_NEW_PREMIUM_USER = 'NUS_NPU';
export const NEW_USER_SITE_PREMIUM_AND_THREE_DAYS_PREMIUM = 'NUS_NPU_3DPU';
export const NEW_PREMIUM_USER_THREE_DAYS_PREMIUM = 'NPU_3DPU';
export const THREE_DAYS_PREMIUM = '3DPU';
export const SEVEN_DAYS_USER_SITE = '7DUS';
export const NEW_USER_SITE_SEVEN_DAYS_USER_SITE = 'NUS_7DUS';
