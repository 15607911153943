import { useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { FilterMobileBtn } from 'components/FilterBtn';
import {
	SignalsInsights,
	SignalsInsightsBookmarks,
	SignalsInsightsPopular
} from 'components/Insights';
import InsightsTab from 'components/InsightsTab';
import {
	FXSLeftColLayout,
	FXSRightColLayout,
	TwoColsMainLayout
} from 'components/Layouts';
import UserNoPremium from 'components/UserNoPremium';
import SignalsMini from 'components/signalsMini';
import { ConfigContext } from 'contexts/configContext';
import { useTranslationsContext } from 'contexts/translationsContext';
import { EventList } from 'fxs-event-list';
import { InsightsType } from 'models/enums';
import { PremiumProps } from 'models/props';
import { FXS_Subtitle } from 'styles/typography';
import useTypeformToDatalayer from 'utils/useTypeformToDatalayer';
import { isLessThan } from 'utils/useWindowWidth';

export default function Home({ isPremium }: PremiumProps) {
	const isLessThan833 = isLessThan(833);
	const isLessThan1280 = isLessThan(1280);
	const { t } = useTranslationsContext();
	const { pathname } = useLocation();
	const splitLocation = pathname.split('/')[1].toLowerCase();
	const { config } = useContext(ConfigContext);
	useTypeformToDatalayer();

	return (
		<TwoColsMainLayout>
			{!isPremium && <UserNoPremium />}
			<FXSLeftColLayout
				id='fxs_signals_messaging_bar_inner'
				isLessThan1280={isLessThan1280}
				isLessThan833={isLessThan833}
				removePaddings={true}
				removeScroll={true}
				removeBg={true}
				isInsights={true}
			>
				<InsightsTab />
				{splitLocation === InsightsType.latest && <SignalsInsights />}
				{splitLocation === InsightsType.popular && (
					<SignalsInsightsPopular />
				)}
				{splitLocation === InsightsType.bookmarks && (
					<SignalsInsightsBookmarks />
				)}
				{isLessThan833 && splitLocation === InsightsType.latest && (
					<FilterMobileBtn />
				)}
			</FXSLeftColLayout>
			{!isLessThan1280 && (
				<FXSRightColLayout
					isLessThan1280={isLessThan1280}
					isLessThan833={isLessThan833}
					removePaddings={true}
					removeBg={true}
				>
					<div style={{ marginTop: '32px' }}>
						<FXS_Subtitle>{t('fxsSignal')}</FXS_Subtitle>
						<SignalsMini />
						<FXS_Subtitle style={{ marginTop: '4rem' }}>
							{t('nextWebinarsTitle')}
						</FXS_Subtitle>
						<EventList
							appId={config.Variables.AlgoliaAppId}
							apiKey={config.Variables.AlgoliaApiKey}
							indexName={config.Variables.AlgoliaIndex}
							take={config.Variables.AlgoliaTake}
							culture={config.Variables.Culture}
							t={t}
						/>
					</div>
				</FXSRightColLayout>
			)}
		</TwoColsMainLayout>
	);
}
