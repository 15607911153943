import React from 'react';
import ReactDOM from 'react-dom';

import { DiscordContext } from 'components/DiscordBtnOnboarding/Context/DiscordContext';
import { ConfigContextProvider } from 'context-providers/configContextProvider';
import { NewsletterContextProvider } from 'context-providers/newsletterContextProvider';
import { NotificationsContextProvider } from 'context-providers/notificationsContextProvider';
import { TimeZoneContextProvider } from 'context-providers/timeZonesProvider';
import { UserContextProvider } from 'context-providers/userContextProvider';
import { SplashScreenProvider } from 'contexts/SplashScreenContext';
import { TranslationsContext } from 'contexts/translationsContext';
import App from 'pages/App';

import './styles/styles.css';

ReactDOM.render(
	<React.StrictMode>
		<SplashScreenProvider>
			<ConfigContextProvider>
				<TranslationsContext>
					<TimeZoneContextProvider>
						<NotificationsContextProvider>
							<UserContextProvider>
								<NewsletterContextProvider>
									<DiscordContext>
										<App />
									</DiscordContext>
								</NewsletterContextProvider>
							</UserContextProvider>
						</NotificationsContextProvider>
					</TimeZoneContextProvider>
				</TranslationsContext>
			</ConfigContextProvider>
		</SplashScreenProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
