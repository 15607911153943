import { useEffect, useState } from 'react';

import { FXSButtons } from 'fxs-styles';
import { getCookie } from 'utils/getUser';

import '../styles/styles.css';

export default ({ Payment, isLastDay, cookieBeforeExpiration }) => {
	// Popup logic
	let popupElement = null;
	let monthlyLink: string | boolean = null;
	let unlimitedLink: string = null;
	const [isBannerOpen, setIsBannerOpen] = useState(null);
	const currentCookie = getCookie('s2pBanner');

	const toggleBanner = () => {
		if (popupElement !== null) {
			popupElement.classList.toggle('fxs_banner_box_closed');
		}
	};

	const setCookie = () => {
		if (!isLastDay && (!currentCookie || currentCookie === 'open')) {
			document.cookie = `s2pBanner=closed; expires=${new Date(cookieBeforeExpiration)}; path=/`;
			setIsBannerOpen(false);
		} else {
			document.cookie = `s2pBanner=open; expires=${new Date(cookieBeforeExpiration)}; path=/`;
			setIsBannerOpen(true);
		}
	};

	switch (Payment.subscription.id.toLowerCase()) {
		// Yearly high
		case '20be3702-f3cd-4b02-aec7-8d3e76bc8e9e':
			unlimitedLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=d4222666-9e7f-424e-87ba-57e36c632ba9&uritoredirect=%2FtradingStudio&step=payment-options`;
			monthlyLink = isLastDay
				? `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=18221a27-1c32-4ac7-81b9-d0089f0f60ce&uritoredirect=%2FtradingStudio&step=payment-options`
				: false;
			break;

		// Quartely & Monthly high
		case '78571db4-d721-4796-9c3e-21c18239943b':
		case '1b6219c4-89eb-4347-8c4f-5c60be92764d':
			unlimitedLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=d4222666-9e7f-424e-87ba-57e36c632ba9&uritoredirect=%2FtradingStudio&step=payment-options`;
			monthlyLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=18221a27-1c32-4ac7-81b9-d0089f0f60ce&uritoredirect=%2FtradingStudio&step=payment-options`;
			break;

		// Yearly low
		case 'd6bb17be-6383-433e-895b-df66d63ce14d':
			unlimitedLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=181663fd-0d59-4e82-b68c-3bd4c5d4b57c&uritoredirect=%2FtradingStudio&step=login-options`;
			monthlyLink = isLastDay
				? `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=3df15f36-f9b7-44ff-ace5-7cdd69b1805f&uritoredirect=%2FtradingStudio&step=login-options`
				: false;
			break;

		// Quartely & Monthly low
		case '56b50311-4fd3-455e-a317-6fdc880737a9':
		case '753177FC-2D8B-4417-A560-2297076130DB':
			unlimitedLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=181663fd-0d59-4e82-b68c-3bd4c5d4b57c&uritoredirect=%2FtradingStudio&step=login-options`;
			monthlyLink = `${window.config.Variables.FxsSite}/account/premium-payment?subscriptionid=3df15f36-f9b7-44ff-ace5-7cdd69b1805f&uritoredirect=%2FtradingStudio&step=login-options`;
	}

	useEffect(() => {
		popupElement = document.getElementById('bannerbox_content');
	}, [isBannerOpen]);

	return (
		<>
			<FXSButtons />
			<div
				id='bannerbox_content'
				className={`fxs_banner_box ${currentCookie === 'closed' && 'fxs_banner_box_closed'}`}
			>
				<div className='fxs_banner_box_tab_wrapper'>
					<div className='fxs_banner_box_tab_wrapper_inner'>
						<div
							className='fxs_banner_box_tab'
							id='fxs_banner_box_tab'
							onClick={() => {
								toggleBanner();
								setCookie();
							}}
						>
							<span>FXS Premium </span>
							<svg
								id='fxs_banner_box_collapse'
								aria-hidden='true'
								focusable='false'
								data-prefix='fas'
								data-icon='chevron-double-down'
								role='img'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 448 512'
								className='svg-inline--fa fa-chevron-double-down fa-w-14'
							>
								<path
									fill='currentColor'
									d='M207 477.5L12.7 283.1c-9.4-9.4-9.4-24.6 0-33.9l22.7-22.7c9.4-9.4 24.5-9.4 33.9 0l154.7 154 154.7-154c9.4-9.3 24.5-9.3 33.9 0l22.7 22.7c9.4 9.4 9.4 24.6 0 33.9L241 477.5c-9.4 9.3-24.6 9.3-34 0zm34-192L435.3 91.1c9.4-9.4 9.4-24.6 0-33.9l-22.7-22.7c-9.4-9.4-24.5-9.4-33.9 0L224 188.5 69.3 34.5c-9.4-9.3-24.5-9.3-33.9 0L12.7 57.2c-9.4 9.4-9.4 24.6 0 33.9L207 285.5c9.4 9.3 24.6 9.3 34 0z'
									className=''
								></path>
							</svg>
							<svg
								id='fxs_banner_box_expand'
								aria-hidden='true'
								focusable='false'
								data-prefix='fas'
								data-icon='chevron-double-up'
								role='img'
								xmlns='http://www.w3.org/2000/svg'
								viewBox='0 0 448 512'
								className='svg-inline--fa fa-chevron-double-up fa-w-14'
							>
								<path
									fill='currentColor'
									d='M241 34.5l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9l-22.7 22.7c-9.4 9.4-24.5 9.4-33.9 0L224 131.5l-154.7 154c-9.4 9.3-24.5 9.3-33.9 0l-22.7-22.7c-9.4-9.4-9.4-24.6 0-33.9L207 34.5c9.4-9.3 24.6-9.3 34 0zm-34 192L12.7 420.9c-9.4 9.4-9.4 24.6 0 33.9l22.7 22.7c9.4 9.4 24.5 9.4 33.9 0l154.7-154 154.7 154c9.4 9.3 24.5 9.3 33.9 0l22.7-22.7c9.4-9.4 9.4-24.6 0-33.9L241 226.5c-9.4-9.3-24.6-9.3-34 0z'
									className=''
								></path>
							</svg>
						</div>
					</div>
				</div>
				<div className='fxs_banner_box_content'>
					<div className='fxs_banner_box_content_inner'>
						<div className='fxs_banner_box_content_inner_inner'>
							<div className='fxs_banner_box_title_description'>
								<h3 className='fxs_banner_box_title fxs_headline_from_small_to_medium'>
									We're lowering the price only for you in
									Premium!
								</h3>
								<div
									className='fxs_banner_box_hidden_description'
									id='fxs_banner_box_hidden_description'
								>
									<h3 className='fxs_headline_from_small_to_medium'>
										Your Premium subscription is about to
										expire! We're lowering the price only
										for you!
									</h3>
									<p className='fxs_entryPlain_txt'>
										<strong>Limited time offer!</strong>{' '}
										Your new plan will replace the current
										one. It will only be billed once your
										previous plan has expired.
									</p>
								</div>
							</div>
							<div className='fxs_banner_box_button_cup'>
								<a
									className='fxs_banner_box_button dbox-banner-button fxs_btn fxs_btn_line inverse'
									href={
										unlimitedLink && monthlyLink
											? `${window.config.Variables.FxsSite}/info/premium?s2p=b`
											: `${window.config.Variables.FxsSite}/info/premium?s2p=l`
									}
								>
									Select your Plan now!
								</a>
								<svg
									className='fxs_banner_coffee_cup'
									width='104'
									height='77'
									viewBox='0 0 104 77'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M48.5501 102L51.0877 99.9955L48.5497 102C46.7966 99.7881 44.615 97.0357 44.615 91.8293C44.615 86.6229 46.7964 83.8703 48.5493 81.6586C50.0226 79.7997 51.0871 78.4567 51.0871 75.4973C51.0871 72.5381 50.0226 71.1954 48.5495 69.3365C46.7966 67.125 44.615 64.3726 44.615 59.1664C44.615 53.9598 46.7964 51.2078 48.5493 48.9961C50.0226 47.1371 51.0871 45.7942 51.0871 42.8348C51.0871 39.8754 50.0226 38.5327 48.5493 36.6738C46.7964 34.4623 44.615 31.7098 44.615 26.5035C44.615 21.2967 46.7964 18.5441 48.5493 16.3326C50.0226 14.4732 51.0871 13.1301 51.0871 10.1701C51.0871 7.20992 50.0228 5.86699 48.5499 4.00848L53.6255 0C55.378 2.21133 57.559 4.96334 57.559 10.1703C57.559 15.3771 55.3776 18.1297 53.6247 20.3412C52.1515 22.2005 51.0871 23.5437 51.0871 26.5037C51.0871 29.4631 52.1515 30.806 53.6249 32.6649C55.3778 34.8764 57.5592 37.6288 57.5592 42.835C57.5592 48.0416 55.3778 50.7938 53.6249 53.0053C52.1515 54.8642 51.0871 56.2072 51.0871 59.1666C51.0871 62.126 52.1515 63.4687 53.6249 65.3276C55.3778 67.5391 57.5592 70.2913 57.5592 75.4975C57.5592 80.7041 55.3778 83.4563 53.6249 85.6678C52.1515 87.5266 51.0871 88.8695 51.0871 91.8291C51.0871 94.7885 52.1517 96.1314 53.6251 97.9903L48.5501 102Z'
										fill='#2A77F6'
									/>
									<path
										d='M80.3626 102L82.9002 99.9955L80.3622 102C78.6091 99.7881 76.4277 97.0357 76.4277 91.8293C76.4277 86.6229 78.6093 83.8703 80.3622 81.6586C81.8353 79.7997 82.8998 78.4567 82.8998 75.4973C82.8998 72.5381 81.8353 71.1954 80.3622 69.3365C78.6093 67.125 76.4277 64.3726 76.4277 59.1664C76.4277 53.9598 78.6091 51.2078 80.362 48.9961C81.8353 47.1372 82.8998 45.7942 82.8998 42.8348C82.8998 39.8754 81.8357 38.5331 80.3632 36.6752L85.439 32.6661C87.1913 34.8768 89.3719 37.6284 89.3719 42.8346C89.3719 48.0412 87.1905 50.7932 85.4376 53.0049C83.9642 54.8638 82.8998 56.2068 82.8998 59.1662C82.8998 62.1256 83.9644 63.4683 85.4376 65.3272C87.1905 67.5387 89.3719 70.291 89.3719 75.4971C89.3719 80.7033 87.1905 83.4559 85.4376 85.6675C83.9642 87.5264 82.8998 88.8693 82.8998 91.8289C82.8998 94.7883 83.9644 96.1312 85.4378 97.9901L80.3626 102Z'
										fill='#FA3F50'
									/>
									<path
										d='M16.7376 102L19.2752 99.9955L16.7372 102C14.9841 99.7881 12.8027 97.0357 12.8027 91.8293C12.8027 86.6229 14.9843 83.8703 16.7372 81.6586C18.2103 79.7997 19.2748 78.4567 19.2748 75.4973C19.2748 72.5381 18.2103 71.1954 16.7372 69.3365C14.9843 67.125 12.8027 64.3726 12.8027 59.1664C12.8027 53.9598 14.9841 51.2078 16.737 48.9961C18.2103 47.1371 19.2748 45.7942 19.2748 42.8348C19.2748 39.8754 18.2107 38.5331 16.7382 36.6752L21.814 32.6661C23.5663 34.8768 25.7469 37.6284 25.7469 42.8346C25.7469 48.0412 23.5655 50.7932 21.8126 53.0049C20.3392 54.8638 19.2748 56.2068 19.2748 59.1662C19.2748 62.1256 20.3394 63.4683 21.8126 65.3272C23.5655 67.5387 25.7469 70.2909 25.7469 75.4971C25.7469 80.7033 23.5655 83.4559 21.8126 85.6674C20.3392 87.5264 19.2748 88.8693 19.2748 91.8289C19.2748 94.7883 20.3394 96.1312 21.8128 97.9901L16.7376 102Z'
										fill='#FA3F50'
									/>
									<path
										d='M95.57 55.8042C93.7097 55.8042 92.2016 57.3097 92.2016 59.1668C92.2016 61.0239 93.7097 62.5294 95.57 62.5294C97.4303 62.5294 98.9383 61.0239 98.9383 59.1668C98.9383 57.3097 97.4303 55.8042 95.57 55.8042Z'
										fill='#B38419'
									/>
									<path
										d='M98.8058 6.8083C96.9455 6.8083 95.4375 8.31379 95.4375 10.1709C95.4375 12.028 96.9455 13.5335 98.8058 13.5335C100.666 13.5335 102.174 12.028 102.174 10.1709C102.174 8.31379 100.666 6.8083 98.8058 6.8083Z'
										fill='#0CB5C6'
									/>
									<path
										d='M66.9925 23.1395C65.1322 23.1395 63.6242 24.645 63.6242 26.5021C63.6242 28.3592 65.1322 29.8647 66.9925 29.8647C68.8528 29.8647 70.3609 28.3592 70.3609 26.5021C70.3609 24.645 68.8528 23.1395 66.9925 23.1395Z'
										fill='#B38419'
									/>
									<path
										d='M70.2284 72.1353C68.3681 72.1353 66.86 73.6408 66.86 75.4979C66.86 77.355 68.3681 78.8605 70.2284 78.8605C72.0887 78.8605 73.5967 77.3551 73.5967 75.4979C73.5967 73.6408 72.0887 72.1353 70.2284 72.1353Z'
										fill='#0CB5C6'
									/>
									<path
										d='M63.7567 88.4665C61.8964 88.4665 60.3883 89.972 60.3883 91.8291C60.3883 93.6862 61.8964 95.1917 63.7567 95.1917C65.617 95.1917 67.125 93.6862 67.125 91.8291C67.125 89.972 65.617 88.4665 63.7567 88.4665Z'
										fill='#B38419'
									/>
									<path
										d='M38.4175 39.4214C36.5572 39.4214 35.0491 40.9269 35.0491 42.784C35.0491 44.6411 36.5572 46.1466 38.4175 46.1466C40.2778 46.1466 41.7858 44.6411 41.7858 42.784C41.7858 40.9269 40.2778 39.4214 38.4175 39.4214Z'
										fill='#0CB5C6'
									/>
									<path
										d='M38.4175 6.8083C36.5572 6.8083 35.0491 8.31379 35.0491 10.1709C35.0491 12.028 36.5572 13.5335 38.4175 13.5335C40.2778 13.5335 41.7858 12.028 41.7858 10.1709C41.7858 8.31379 40.2778 6.8083 38.4175 6.8083Z'
										fill='#B38419'
									/>
									<path
										d='M31.9458 55.8042C30.0855 55.8042 28.5774 57.3097 28.5774 59.1668C28.5774 61.0239 30.0855 62.5294 31.9458 62.5294C33.8061 62.5294 35.3141 61.0239 35.3141 59.1668C35.3141 57.3097 33.8061 55.8042 31.9458 55.8042Z'
										fill='#B38419'
									/>
									<path
										d='M9.84025 6.80829C7.97996 6.80829 6.47189 8.31378 6.47189 10.1709C6.47189 12.028 7.97996 13.5335 9.84025 13.5335C11.7005 13.5335 13.2086 12.028 13.2086 10.1709C13.2086 8.31378 11.7005 6.80829 9.84025 6.80829Z'
										fill='#0CB5C6'
									/>
									<path
										d='M3.36836 23.1395C1.50806 23.1395 1.62632e-07 24.6449 0 26.5021C-1.62632e-07 28.3592 1.50806 29.8647 3.36835 29.8647C5.22865 29.8647 6.73671 28.3592 6.73671 26.5021C6.73671 24.6449 5.22865 23.1395 3.36836 23.1395Z'
										fill='#B38419'
									/>
									<path
										d='M6.6042 72.1353C4.74391 72.1353 3.23585 73.6408 3.23585 75.4979C3.23585 77.355 4.74391 78.8605 6.6042 78.8605C8.46449 78.8605 9.97256 77.355 9.97256 75.4979C9.97256 73.6408 8.46449 72.1353 6.6042 72.1353Z'
										fill='#0CB5C6'
									/>
									<path
										d='M114.632 91.0486C112.771 91.0486 111.263 92.5541 111.263 94.4113C111.263 96.2684 112.771 97.7739 114.632 97.7739C116.492 97.7739 118 96.2684 118 94.4113C118 92.5541 116.492 91.0486 114.632 91.0486Z'
										fill='#B38419'
									/>
								</svg>
							</div>
						</div>
						<div
							className={`fxs_banner_box_plans ${!monthlyLink && 'is-single'}`}
						>
							{monthlyLink && (
								<div
									className='fxs_banner_box_plan monthly'
									onClick={() =>
										window.open(
											monthlyLink as string,
											'_self'
										)
									}
								>
									<p className='fxs_banner_box_plan_title'>
										MONTHLY PLAN
									</p>
									<div className='fxs_banner_box_plan_tag_price'>
										<div className='fxs_banner_box_plan_tag'>
											<strong>75% OFF</strong>
											<span>FOREVER</span>
										</div>
										<div className='fxs_banner_box_plan_price'>
											<span className='fxs_banner_box_dollar_amount'>
												<span className='fxs_banner_box_price_dollar'>
													$
												</span>
												<span className='fxs_banner_box_price_amount'>
													9.99
												</span>
											</span>
											<span className='fxs_banner_box_temporality'>
												/month
											</span>
											<span className='fxs_banner_box_previous_price'>
												<span className='fxs_banner_box_previous_price_dollar'>
													$
												</span>
												<span className='fxs_banner_box_previous_price_value'>
													39.99
												</span>
											</span>
										</div>
									</div>
								</div>
							)}
							<div
								className='fxs_banner_box_plan lifetime'
								onClick={() =>
									window.open(unlimitedLink, '_self')
								}
							>
								<p className='fxs_banner_box_plan_title'>
									UNLIMITED PLAN
								</p>
								<div className='fxs_banner_box_plan_tag_price'>
									<div className='fxs_banner_box_plan_tag'>
										<strong>ONE PAYMENT</strong>
										<span>NEVER PAY AGAIN</span>
									</div>
									<div className='fxs_banner_box_plan_price'>
										<span className='fxs_banner_box_dollar_amount'>
											<span className='fxs_banner_box_price_dollar'>
												$
											</span>
											<span className='fxs_banner_box_price_amount'>
												199
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
